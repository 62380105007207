<div class="float-right d-none d-sm-block "><b class= "Copyright" >Version</b> {{ appVersion }}</div>
<strong>
    <span class="Copyright">Copyright &copy; {{ currentYear }}</span>
    <a
        href="https://monitoringinnovation.com"
        target="_blank"
        rel="noopener noreferrer"
        style="margin: 0; color: #00FF32;"
    >
        monitoringinnovation.com</a
    >
    <span>.</span>
</strong>
<span> All rights reserved.</span>
