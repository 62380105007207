import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TableViewModel, Value} from '@components/models/table';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {PaginationService} from '@components/pagination/pagination.service';
import {ExcelService} from '@services/excel.service';
import {SwitchService} from '@services/switch.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {CovidForm} from '@/models/Covid';
import {Question} from '@/models/questions';
import {motion_logo} from 'assets/images/motion_logo';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-manage-covid-forms',
    templateUrl: './manage-covid-forms.component.html',
    styleUrls: ['./manage-covid-forms.component.scss']
})
export class ManageCovidFormsComponent implements OnInit {
    currentPage: number = 1;
    pageSize: number = 5;
    selected_form: any;
    covid_forms: any[] = null;
    covid_forms_count: number = null;
    covidModel: TableViewModel = {
        title: 'Control Covid V.2',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {title: 'Folio', key: 'folio', filter: true, data: []},
            {title: 'Fecha', key: 'date', filter: true, data: []},
            {
                title: 'Conductor',
                key: 'driver',
                filter: true,
                filterDriver: true,
                data: this.getDriverValues()
            }
        ],
        records: [],
        showFilters: true,
        enableActions: true,
        isModal: false
    };
    searchby: string = '';
    show_single_covid_form: boolean = false;
    loading: boolean = false;
    data: CovidForm = null;
    questions: Question[] = [];

    constructor(
        private apiService: ApiService,
        private appService: AppService,
        private cd: ChangeDetectorRef,
        private pS: PaginationService,
        private excelService: ExcelService,
        private modalService: SwitchService
    ) {}

    ngOnInit(): void {
        this.getCovidForms(this.currentPage, this.searchby);
        this.modalService.$modal.subscribe((value) => {
            this.show_single_covid_form = value;
        });
    }

    getCovidForms(currentPage, searchby) {
        this.apiService
            .getCovidFormsInfo(currentPage, this.pageSize, searchby)
            .then((covid_forms) => {
                if (covid_forms.count > 0) {
                    this.covid_forms = covid_forms.result;
                    this.covid_forms_count = covid_forms.total_messages_count;
                    this.covidModel.records = this.covid_forms;
                    this.covidModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: covid_forms.count,
                        rowCount: this.covid_forms_count
                    };
                    this.cd.detectChanges();
                }
            });
    }

    getDriverValues(): Value[] {
        let driverValues: Value[] = [];
        this.apiService.getAllDrivers().then((drivers:any) => {
            for (let driver of drivers) {
                let driverValue = new Value(driver.name, driver.name);
                driverValues.push(driverValue);
            }
        });
        return driverValues;
    }

    onRemove(index: number) {
        console.log('remove event ! ');
    }

    paginatorEvent(currentPage: number) {
        this.getCovidForms(currentPage, this.searchby);
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchP(searchby: string) {
        this.getCovidForms(
            this.currentPage,
            searchby ? searchby : this.searchby
        );
    }

    showSingleCovidForm(data) {
        this.modalService.$modal.emit(true);
        this.selected_form = data;
    }

    updateFilter(event) {
        console.log(event);
    }
    filterDriver(event) {
        console.log(event);
    }
    filterUnit(event) {
        console.log(event);
    }

    validateRevision(event) {
        console.log(event);
    }

    getQuestions(folio: string) {
        return this.apiService
            .getSingleCovidNovelties(folio)
            .then((novelties) => {
                this.questions = novelties.result;
            });
    }

    openPDF(data: any): void {
        this.loading = true;
        this.apiService.getSingleCovidInfo(data.folio).then((covid) => {
            this.data = covid.result;
            this.getQuestions(data.folio).then(() => {
                var contentTable = [];

                for (let quest of this.questions) {
                    contentTable.push([
                        quest.name,
                        quest.novetly ? 'X' : '-',
                        !quest.novetly ? 'X' : '-'
                    ]);
                }

                var docDefinition = {
                    pageMargins: [40, 90, 40, 60],
                    header: {
                        columns: [
                            {
                                image: motion_logo,
                                width: 200,
                                height: 60,
                                alignment: 'right',
                                opacity: 0.9,
                                margin: [0, 10]
                            }
                        ],
                        columnGap: 200
                    },
                    footer: {
                        columns: [
                            {
                                text: 'monitoringinnovation.com',
                                link: 'http://monitoringinnovation.com',
                                alignment: 'right',
                                margin: [0, 10, 10, 0]
                            }
                        ]
                    },
                    content: [
                        {
                            text: `Formulario Covid: ${this.data.folio}`,
                            fontSize: 16,
                            margin: [0, 5]
                        },
                        {
                            text: `Fecha: ${this.data.date}`,
                            fontSize: 16,
                            margin: [0, 5]
                        },
                        {
                            columns: [
                                [
                                    {
                                        text: `Conductor:`,
                                        fontSize: 12,
                                        alignment: 'center'
                                    },
                                    {
                                        text: `${this.data.driver}`,
                                        fontSize: 14,
                                        alignment: 'center',
                                        bold: true
                                    }
                                ],
                                [
                                    {
                                        text: `Temperatura registrada:`,
                                        fontSize: 12,
                                        alignment: 'center'
                                    },
                                    {
                                        text: `${this.data.temp}`,
                                        fontSize: 14,
                                        alignment: 'center',
                                        bold: true
                                    }
                                ]
                            ],
                            margin: [0, 10]
                        },
                        {
                            layout: 'lightHorizontalLines',
                            table: {
                                // headers are automatically repeated if the table spans over multiple pages
                                // you can declare how many rows should be treated as headers
                                headerRows: 1,
                                widths: ['*', 100, 100],

                                body: [
                                    [
                                        {text: 'ITEM', bold: true},
                                        {text: 'CON NOVEDAD', bold: true},
                                        {text: 'SIN NOVEDAD', bold: true}
                                    ],
                                    ...contentTable
                                ]
                            }
                        }
                    ]
                };

                pdfMake.tableLayouts = {
                    exampleLayout: {
                        hLineWidth: function (i, node) {
                            if (i === 0 || i === node.table.body.length) {
                                return 0;
                            }
                            return i === node.table.headerRows ? 2 : 1;
                        },
                        vLineWidth: function (i) {
                            return 0;
                        },
                        hLineColor: function (i) {
                            return i === 1 ? 'black' : '#8a8a8a';
                        },
                        paddingLeft: function (i) {
                            return i === 0 ? 0 : 8;
                        },
                        paddingRight: function (i, node) {
                            return i === node.table.widths.length - 1 ? 0 : 8;
                        }
                    }
                };
                pdfMake.createPdf(docDefinition).open();
                this.loading = false;
            });
        });
    }
}
