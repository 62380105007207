<div class="row pagination">
    <div class="column records">
        <div class="" *ngIf="pagination">
            <span *ngIf="pagination" class="pagination__desc">
                {{ pagination.rowCount }} Registros
            </span>
        </div>
    </div>
    <div class="column pageSize">
        <select
            name="pageSize"
            (change)="changePageSize($event.target.value)"
            class="col-4 form-control ml-4"
        >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
        </select>
    </div>
    <div class="column page">
        <div
            class="
                datatable
                datatable-bordered
                datatable-head-custom
                datatable-default
                datatable-primary
                datatable-loaded
            "
        >
            <div
                class="
                    datatable-pager datatable-paging-loaded
                    py-1
                    align-items-center
                "
            >
                <ul class="datatable-pager-nav mb-sm-0">
                    <li
                        style="cursor: pointer"
                        *ngIf="totalPages > 5 && count - 1 > 0"
                        (click)="initPage()"
                    >
                        <a
                            class="
                                datatable-pager-link datatable-pager-link-first
                            "
                        >
                            <span
                                [inlineSVG]="
                                    './assets/icons/Navigation/Arrow-to-left.svg'
                                "
                                class="svg-icon svg-icon-md svg-icon-danger"
                            ></span>
                        </a>
                    </li>
                    <li
                        style="cursor: pointer"
                        *ngIf="totalPages > 5 && count - 1 > 0"
                        (click)="previous()"
                    >
                        <a
                            class="
                                datatable-pager-link datatable-pager-link-prev
                            "
                        >
                            <span
                                [inlineSVG]="
                                    './assets/icons/Navigation/Arrow-left.svg'
                                "
                                class="svg-icon svg-icon-md svg-icon-danger"
                            ></span>
                        </a>
                    </li>

                    <li
                        style="cursor: pointer"
                        *ngIf="count - 1 > 0"
                        (click)="onPage(count - 1)"
                    >
                        <a
                            class="
                                datatable-pager-link datatable-pager-link-number
                            "
                            [ngClass]="{
                                'datatable-pager-link-active':
                                    currentPage === count - 1
                            }"
                            >{{ count - 1 }}</a
                        >
                    </li>

                    <li
                        style="cursor: pointer"
                        *ngIf="count > 0"
                        (click)="onPage(count)"
                    >
                        <a
                            class="
                                datatable-pager-link datatable-pager-link-number
                            "
                            [ngClass]="{
                                'datatable-pager-link-active':
                                    currentPage === count
                            }"
                            >{{ count }}</a
                        >
                    </li>

                    <li
                        style="cursor: pointer"
                        *ngIf="totalPages >= 1 || count + 1 <= totalPages"
                        (click)="onPage(count + 1)"
                    >
                        <a
                            class="
                                datatable-pager-link datatable-pager-link-number
                            "
                            [ngClass]="{
                                'datatable-pager-link-active':
                                    currentPage === count + 1
                            }"
                            >{{ count + 1 }}</a
                        >
                    </li>

                    <li
                        style="cursor: pointer"
                        *ngIf="totalPages >= 2 && count + 2 <= totalPages"
                        (click)="onPage(count + 2)"
                    >
                        <a
                            class="
                                datatable-pager-link datatable-pager-link-number
                            "
                            [ngClass]="{
                                'datatable-pager-link-active':
                                    currentPage === count + 2
                            }"
                            >{{ count + 2 }}</a
                        >
                    </li>
                    <li
                        style="cursor: pointer"
                        *ngIf="totalPages >= 3 && count + 3 <= totalPages"
                        (click)="onPage(count + 3)"
                    >
                        <a
                            class="
                                datatable-pager-link datatable-pager-link-number
                            "
                            [ngClass]="{
                                'datatable-pager-link-active':
                                    currentPage === count + 3
                            }"
                            >{{ count + 3 }}</a
                        >
                    </li>
                    <li
                        style="cursor: pointer"
                        *ngIf="totalPages >= 4 && count + 4 <= totalPages"
                        (click)="onPage(count + 4)"
                    >
                        <a
                            class="
                                datatable-pager-link datatable-pager-link-number
                            "
                            [ngClass]="{
                                'datatable-pager-link-active':
                                    currentPage === count + 4
                            }"
                            >{{ count + 4 }}</a
                        >
                    </li>
                    <li
                        style="cursor: pointer"
                        *ngIf="totalPages >= 5 && count + 5 <= totalPages"
                        (click)="onPage(count + 5)"
                    >
                        <a
                            class="
                                datatable-pager-link datatable-pager-link-number
                            "
                            [ngClass]="{
                                'datatable-pager-link-active':
                                    currentPage === count + 5
                            }"
                            >{{ count + 5 }}</a
                        >
                    </li>
                    <li
                        style="cursor: pointer"
                        *ngIf="totalPages > 5 && count + 1 !== totalPages"
                        (click)="next()"
                    >
                        <a
                            title="Next"
                            class="
                                datatable-pager-link datatable-pager-link-next
                            "
                        >
                            <span
                                [inlineSVG]="
                                    './assets/icons/Navigation/Arrow-right.svg'
                                "
                                class="svg-icon svg-icon-md svg-icon-danger"
                            ></span>
                        </a>
                    </li>
                    <li
                        style="cursor: pointer"
                        *ngIf="totalPages > 5"
                        (click)="endPage()"
                    >
                        <a
                            title="Last"
                            class="
                                datatable-pager-link datatable-pager-link-last
                            "
                        >
                            <span
                                [inlineSVG]="
                                    './assets/icons/Navigation/Arrow-to-right.svg'
                                "
                                class="svg-icon svg-icon-md svg-icon-danger"
                            ></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="column search">
        <form class="form-inline ml-3 motion-right" [formGroup]="searchForm">
            <div class="input-group input-group-sm">
                <input
                    class="form-control form-control-navbar"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    (change)="search($event.target.value)"
                />
            </div>
        </form>
    </div>
    <div class="column excel">
        <div class="input-group input-group-sm">
            <div class="input-group-append">
                <button class="exportButton" type="button" (click)="selectExport(content)">
                    Exportar
                </button>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-modal>
  <app-modal>
    <app-action-confirm
      iconPath="/assets/icon/ec-descargar.svg"
      title="Qué desea exportar?"
      confirmText="Todos los registros"
      cancelText="Esta página"
      (confirmEvent)="exportAllExcel()"
      (cancelEvent)="exportExcel()"
    >

    </app-action-confirm>
  </app-modal>
</ng-template>
