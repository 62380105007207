<div class="email-maker" *ngIf="_emails && _emails.length > 0">
    <div class="card-email" *ngFor="let email of _emails; let i = index">
        <div
            class="email row"
            [ngStyle]="{
                background:
                    _selected_email && _selected_email.id === id
                        ? '#ff0032'
                        : ''
            }"
        >
            <div (click)="onSelectEmail(i)">{{ email }}</div>
            <div (click)="onDeleteEmail(i)">X</div>
        </div>
    </div>
</div>
