<div class="enlist-container">
    <div class="row">
        <div class="col-12 page-title-heading pl-2">
            <div class="header-icon">
                <img src="/assets/icon/imprimir.svg" width="30" />
            </div>
            <div class="page-subheading">
                <div class="page-subheading-title">Generar Reporte</div>
                <div class="page-subheading-subtitle">
                    En esta sección podras generar un reporte , configurando los
                    parametros mostrados a continuacion..
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="enlist-card mb-3 card">
                <div class="card-body">
                    <form id="excelOptions" [formGroup]="report_excel">
                        <!-- token csrf -->
                        <div id="div_estado" class="column" style="gap: 10px">
                            <div class="row">
                                <div class="col-6">
                                    <div class="input-div"></div>
                                    <label for="estadoAlistamientos"
                                        >Estado</label
                                    >
                                    <select
                                        name="estado"
                                        id="estadoAlistamientos"
                                        class="form-control"
                                        title="Estado de alistamiento..."
                                        data-live-search="true"
                                        required=""
                                        formControlName="status"
                                    >
                                        <option
                                            class="font12px fuec_patch"
                                            value="all"
                                        >
                                            Todos
                                        </option>
                                        <option
                                            class="font12px fuec_patch"
                                            value="creado"
                                        >
                                            Sin novedad
                                        </option>
                                        <option
                                            class="font12px fuec_patch"
                                            value="incompleto"
                                        >
                                            Con novedad
                                        </option>
                                    </select>
                                </div>
                                <div class="col-3">
                                    <label for="date_ini">Desde</label>
                                    <input
                                        class="font12px form-control"
                                        type="date"
                                        id="date_ini"
                                        name="date_ini"
                                        required=""
                                        formControlName="date_init"
                                    />
                                </div>
                                <div class="col-3">
                                    <label for="date_end">Hasta</label>
                                    <input
                                        class="font12px form-control"
                                        type="date"
                                        id="date_end"
                                        name="date_end"
                                        required=""
                                        formControlName="date_end"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <!-- unidades -->
                                <ng-container *ngIf="loadingUnits">
                                    <div class="col-6">
                                        <div
                                            class="spinner-grow text-success"
                                            role="status"
                                        >
                                            <span
                                                class="visually-hidden"
                                            ></span>
                                        </div>
                                        <div
                                            class="spinner-grow text-success"
                                            role="status"
                                        >
                                            <span
                                                class="visually-hidden"
                                            ></span>
                                        </div>
                                        <div
                                            class="spinner-grow text-success"
                                            role="status"
                                        >
                                            <span
                                                class="visually-hidden"
                                            ></span>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!loadingUnits">
                                    <div
                                        id="div_unidades"
                                        class="col-6 column"
                                        style="gap: 5px"
                                    >
                                        <span>Unidades</span>
                                        <div class="row" style="align-items: center;">
                                            <a
                                                type=""
                                                class="ec-link"
                                                id="select_all_units"
                                                name="select_all_units"
                                                (click)="selectAllUnits()"
                                                >Todas las unidades</a
                                            >
                                            -
                                            <a
                                                type=""
                                                class="ec-link"
                                                id="select_all_units"
                                                name="select_all_units"
                                                (click)="deleteAllUnits()"
                                                >Borrar todo</a
                                            >
                                            <i 
                                                class="fas fa-filter"
                                                style="margin-left: .2em; cursor: pointer;"
                                                (click)="handleFilterUnits()"
                                                [ngClass]="filtersClass[unitsFilterStatus]"
                                            ></i>
                                            <label class="input-filter">
                                                <img src="../../../assets/icon/search.svg" alt="">
                                                <input 
                                                type="text"
                                                (input)="handleSearchUnity($event)"
                                                placeholder="Unidad"
                                                class="input-filter"
                                            >
                                            </label>
                                        </div>
<!--  -->
                                        <input
                                            type="hidden"
                                            class=""
                                            name="unidadesarray[]"
                                            id="unidadesinput"
                                            required=""
                                        />
                                        <select
                                            id="selectpickerUnidades"
                                            class="
                                                js-example-basic-multiple
                                                form-control
                                            "
                                            name="units[]"
                                            multiple="multiple"
                                            formControlName="units"
                                            (change)="handleUnitsSelectChange($event)"
                                        >
                                            <option
                                                *ngFor="let unit of unitsFiltered"
                                                [ngValue]="unit.id"
                                                [attr.unit-id]="unit.id"
                                            >
                                                {{ unit.name }}
                                            </option>
                                        </select>
                                    </div>
                                </ng-container>
                                <!-- conductores -->
                                <ng-container *ngIf="loadingDrivers">
                                    <div class="col-6">
                                        <div
                                            class="spinner-grow text-success"
                                            role="status"
                                        >
                                            <span
                                                class="visually-hidden"
                                            ></span>
                                        </div>
                                        <div
                                            class="spinner-grow text-success"
                                            role="status"
                                        >
                                            <span
                                                class="visually-hidden"
                                            ></span>
                                        </div>
                                        <div
                                            class="spinner-grow text-success"
                                            role="status"
                                        >
                                            <span
                                                class="visually-hidden"
                                            ></span>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!loadingDrivers">
                                    <div
                                        id="div_conductores"
                                        class="col-6 column"
                                        style="gap: 5px"
                                    >
                                        <span>Conductores</span>
                                        <div class="row" style="align-items: center;">
                                            <a
                                                type=""
                                                class="ec-link"
                                                id="select_all_drivers"
                                                name="select_all_drivers"
                                                (click)="selectAllDrivers()"
                                                >Todos los conductores</a
                                            >
                                            -
                                            <a
                                                type=""
                                                class="ec-link"
                                                id="deselect_all_drivers"
                                                name="deselect_all_drivers"
                                                (click)="deleteAllDrivers()"
                                                >Borrar todo</a
                                            >
                                            <i 
                                                class="fas fa-filter"
                                                style="margin-left: .2em; cursor: pointer;"
                                                (click)="handleFilterDrivers()"
                                                [ngClass]="filtersClass[driversFilterStatus]"
                                            ></i>
                                            <label class="input-filter">
                                                <img src="../../../assets/icon/search.svg" alt="">
                                                <input 
                                                type="text"
                                                (input)="handleSearchDriver($event)"
                                                placeholder="Conductor"
                                                class="input-filter"
                                            >
                                            </label>
                                        </div>
                                        <input
                                            type="hidden"
                                            class=""
                                            name="conductoresarray[]"
                                            id="conductoresinput"
                                            required=""
                                        />
                                        <select
                                            name="drivers[]"
                                            id="selectpickerConductores"
                                            class="
                                                js-example-basic-multiple
                                                form-control
                                            "
                                            multiple="multiple"
                                            required=""
                                            formControlName="drivers"
                                            (change)="handleDriversSelectChange($event)"
                                        >
                                            <option
                                                *ngFor="
                                                    let conductor of driversFiltered
                                                "
                                                [ngValue]="conductor.id"
                                                [attr.driver-id]="conductor.id"
                                            >
                                                {{ conductor.name }}
                                            </option>
                                        </select>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <!-- Estado -->
                    </form>
                </div>
                <div class="row button">
                    <div class="card-footer">
                        <button
                            type="submit"
                            id="createxcelbtn"
                            class="ec-btn7 mt-4 gi_enable"
                            (click)="openModalReportGenerate()"
                        >
                            Generar Informe
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template [ngIf]="modal_report_generate">
    <app-modal type="none">
        <div class="modal_report_generate" [ngClass]="{'modal_report_generate_loading': loading}">
            <ng-container *ngIf="loading">
                <div class="loader-container">
                    <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                    <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                    <div class="spinner-grow text-success" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!loading">
                <button class="ec-btn4" (click)="openPDF()">
                    <img
                        class="ico-report"
                        src="assets/icon/motion_icon-pdf.svg"
                        alt=""
                    />
                </button>
                <button class="ec-btn2" (click)="generateExcel()">
                    <img
                        class="ico-report"
                        src="assets/icon/motion_icon-xls.svg"
                        alt=""
                    />
                </button>
            </ng-container>
        </div>
    </app-modal>
</ng-template>
