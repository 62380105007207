import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-emails-component',
    templateUrl: './emails-component.component.html',
    styleUrls: ['./emails-component.component.scss']
})
export class EmailsComponentComponent implements OnInit {
    @Input()
    _emails: string[] = [];
    @Input()
    _selected_email: any;
    @Input()
    _index: number;

    @Output()
    createEmail = new EventEmitter<any>();
    @Output()
    deleteEmailApi = new EventEmitter<any>();
    @Output()
    deleteEmailIndex = new EventEmitter<any>();
    @Output()
    selectEmail = new EventEmitter<number>();

    id: number;
    index: number;
    email: string = '';

    constructor() {}

    ngOnInit(): void {}

    onDeleteEmail(index: number) {
        this.deleteEmailIndex.emit(index);
    }

    onSelectEmail(index: number) {
        console.log(index);
        this.selectEmail.emit(index);
    }
}
