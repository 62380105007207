import {Component, Input, OnInit} from '@angular/core';
import {OldEnlistment} from '@/models/OldEnlistment';
import {ApiService} from '@services/api.service';
import {OldCovidForm} from '@/models/OldCovid';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {motion_logo} from 'assets/images/motion_logo';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-single-old-covid-form',
    templateUrl: './single-old-covid-form.component.html',
    styleUrls: ['./single-old-covid-form.component.scss']
})
export class SingleOldCovidFormComponent implements OnInit {
    @Input()
    form: any = null;
    @Input()
    origin: 'covid' | 'enlist' = 'covid';

    user_type: string = 'gerencia';
    loading: boolean = true;
    data: OldCovidForm = null;
    slides: any = [[]];
    chunk(arr, chunkSize) {
        let R = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize) {
            R.push(arr.slice(i, i + chunkSize));
        }
        return R;
    }
    constructor(private apiService: ApiService) {}

    ngOnInit(): void {
        if (!this.form) {
            console.warn('No seteaste data o algo salio mal :C');
        } else {
            if (this.origin === 'covid') {
                this.apiService
                    .getOldSingleCovidFormInfo(this.form.folio)
                    .then((form) => {
                        this.data = form.result[0];
                        this.data.state = this.computeStatus(this.data);
                        this.loading = false;
                    });
            } else {
                this.data = this.form.result.result;
                this.data.state = 'creado';
                this.loading = false;
            }
        }
    }

    openPDF() {
        this.loading = true;

        var docDefinition = {
            pageMargins: [40, 90, 40, 60],
            header: {
                columns: [
                    {
                        image: motion_logo,
                        width: 200,
                        height: 60,
                        alignment: 'right',
                        opacity: 0.8,
                        margin: [0, 10]
                    }
                ],
                columnGap: 200
            },
            footer: {
                columns: [
                    {
                        text: 'monitoringinnovation.com',
                        link: 'http://monitoringinnovation.com',
                        alignment: 'right',
                        margin: [0, 10, 10, 0]
                    }
                ]
            },
            content: [
                {
                    text: `Formulario Covid: ${this.data.folio}`,
                    fontSize: 16,
                    margin: [0, 5]
                },
                {
                    text: `Fecha: ${this.data.fecha}`,
                    fontSize: 16,
                    margin: [0, 5]
                },
                {
                    columns: [
                        [
                            {
                                text: `Conductor:`,
                                fontSize: 12,
                                alignment: 'center'
                            },
                            {
                                text: `${this.data.conductor}`,
                                fontSize: 14,
                                alignment: 'center',
                                bold: true
                            }
                        ],
                        [
                            {
                                text: `Temperatura registrada:`,
                                fontSize: 12,
                                alignment: 'center'
                            },
                            {
                                text: `${this.data.temperatura}`,
                                fontSize: 14,
                                alignment: 'center',
                                bold: true
                            }
                        ]
                    ],
                    margin: [0, 10]
                },
                {
                    layout: 'lightHorizontalLines',
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: ['*', 100, 100],

                        body: [
                            [
                                {text: 'ITEM', bold: true},
                                {text: 'CON NOVEDAD', bold: true},
                                {text: 'SIN NOVEDAD', bold: true}
                            ],
                            [
                                'Dolor de garganta',
                                this.data.garganta ? 'X' : '-',
                                !this.data.garganta ? 'X' : '-'
                            ],
                            [
                                'Tos Seca',
                                this.data.tos ? 'X' : '-',
                                !this.data.tos ? 'X' : '-'
                            ],
                            [
                                'Dificulta para respirar',
                                this.data.respiracion ? 'X' : '-',
                                !this.data.respiracion ? 'X' : '-'
                            ],
                            [
                                'Congestion nasal',
                                this.data.congestion ? 'X' : '-',
                                !this.data.congestion ? 'X' : '-'
                            ],
                            [
                                'Fatiga',
                                this.data.fatiga ? 'X' : '-',
                                !this.data.fatiga ? 'X' : '-'
                            ],
                            [
                                'Escalofrios',
                                this.data.escalofrios ? 'X' : '-',
                                !this.data.escalofrios ? 'X' : '-'
                            ],
                            [
                                'Dolor muscular',
                                this.data.musculos ? 'X' : '-',
                                !this.data.musculos ? 'X' : '-'
                            ]
                        ]
                    }
                }
            ]
        };

        pdfMake.tableLayouts = {
            exampleLayout: {
                hLineWidth: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                        return 0;
                    }
                    return i === node.table.headerRows ? 2 : 1;
                },
                vLineWidth: function (i) {
                    return 0;
                },
                hLineColor: function (i) {
                    return i === 1 ? 'black' : '#8a8a8a';
                },
                paddingLeft: function (i) {
                    return i === 0 ? 0 : 8;
                },
                paddingRight: function (i, node) {
                    return i === node.table.widths.length - 1 ? 0 : 8;
                }
            }
        };
        pdfMake.createPdf(docDefinition).open();
        this.loading = false;
    }

    computeStatus(data: OldCovidForm) {
        let temp: number = +data.temperatura;
        if (
            temp > 37.2 ||
            temp < 34.9 ||
            data.tos ||
            data.musculos ||
            data.escalofrios ||
            data.fatiga ||
            data.congestion ||
            data.respiracion ||
            data.garganta
        ) {
            return 'incompleto';
        } else {
            return 'creado';
        }
    }
}
