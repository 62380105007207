<div class="modal-front">
    <div class="modal-body">
        <div class="card-header">Novedades De Mantenimiento</div>
        <div class="novetlys" *ngFor="let new of questions; index as i">
            <ng-container *ngIf="i === selected">
                <div class="body column">
                    <div class="title-notvetly">{{ new.name }}</div>
                    <div class="content-image row">
                        <div class="novelty-conta-img">
                            <img
                                *ngIf="new.img_mainten"
                                src="{{
                                    'data:image/jpg;base64,' + new.img_mainten
                                }}"
                                alt=""
                                class="novelty-img"
                                (error)="pictNotLoading($event, i)"
                            />
                            <div
                                *ngIf="!new.img_mainten"
                                alt=""
                                class="text-not-img"
                            >
                                Cargar imagen
                            </div>
                        </div>
                        <div class="upload_image">
                            <label class="custom-file-upload" img="img">
                                <input
                                    type="file"
                                    id="file"
                                    accept="Image/*"
                                    (change)="handleUpload($event, i)"
                                    *ngIf="!checked"
                                />
                                <img
                                    src="/assets/icon/ec-descargar.svg"
                                    class="ico-upload"
                                    [ngClass]="{'not-action': checked}"
                                />
                            </label>
                        </div>
                    </div>
                    <div class="input-div">
                        <textarea
                            class="InpuntNovedad"
                            placeholder="Descripcion de la reparacion"
                            [(ngModel)]="new.description_mainten"
                            (change)="handleDesc($event, i)"
                        ></textarea>
                    </div>
                    <div class="row content-buttons">
                        <input
                            type="button"
                            value="Atras"
                            (click)="backQuest(i)"
                            class="ec-btn4"
                        />
                        <input
                            type="button"
                            value="Siguiente"
                            (click)="nextQuest(i)"
                            class="ec-btn2"
                            *ngIf="i !== sizeNews"
                        />
                        <input
                            type="button"
                            value="Guardar"
                            (click)="saveNews()"
                            class="ec-btn7"
                            *ngIf="i === sizeNews && !checked"
                        />
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
