import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { setEmitFlags } from 'typescript';
import { SwitchService } from '@services/switch.service';

@Component({
  selector: 'app-questions-per-template',
  templateUrl: './questions-per-template.component.html',
  styleUrls: ['./questions-per-template.component.scss'],
  providers: [SwitchService]
})
export class QuestionsPerTemplateComponent implements OnInit {
 // when call the component required the template_id to
 // manage its questions
  @Input() selectedTemplate: number;

  //saves all questions to render that
  questions: any[] = [];

  // values to show modals to crud
  showEditModal: boolean = false;
  showCreateModal: boolean = false;
  showDeleteModal: boolean = false;

  showErrorModal: boolean = false;

  // any modal saves que question in a var to manage it
  questionToUpdate: any ;
  questionToCreate: any ;
  questionToDelete: any ;

  errorToShow: string;

  constructor(
    private editModalService: SwitchService,
    private deleteModalService: SwitchService,
    private createModalService: SwitchService,
    private showErrorService: SwitchService,
    private http: HttpClient
  ) {
    this.editModalService.$modal.subscribe((value: boolean) => {
      this.showEditModal = value;
    });

    this.deleteModalService.$modal2.subscribe((value: boolean) => {
      this.showDeleteModal = value;
    });

    this.createModalService.$modal3.subscribe((value: boolean) => {
      this.showCreateModal = value;
    });

    this.showErrorService.$modal4.subscribe((value: boolean) => {
      this.showErrorModal = value;
    });
  }

  // functions to opens and closes the modals

  openEditModal(question: any) {
    this.questionToUpdate = {}
    this.questionToUpdate = {...question}
    this.editModalService.$modal.emit(true);
  }

  openDeleteModal(question: any) {
    this.questionToDelete = {}
    this.questionToDelete = {...question}
    this.deleteModalService.$modal2.emit(true);
  }

  openCreateModal() {
    this.questionToCreate = {}
    this.createModalService.$modal3.emit(true);
  }

  closesCreateModal() {
    this.createModalService.$modal3.emit(false);
  }

  closesEditModal() {
    this.editModalService.$modal.emit(false);
  }

  closesDeleteModal() {
    this.deleteModalService.$modal2.emit(false);
  }

  openErrorModal(error: string) {
    this.errorToShow = error
    this.showErrorService.$modal4.emit(true);
  }

  closesErrorModal() {
    this.showErrorService.$modal4.emit(false);
  }

  // crud functions
  async updateQuestion() : Promise<void>{
    try {
      const response = await this.http.put<any>(`/api/enlistcontrol/custom_templates/update_question/${this.questionToUpdate.id}`,
        {"jsonrpc": "2.0",
          "method": "call",
          "params": {"context": {},
              "name": this.questionToUpdate.name,
              "description" : this.questionToUpdate.description,
              "active": this.questionToUpdate.active
          },
      "id": null}).toPromise()
      if (response['result']['error'] != null){
        this.openErrorModal(response['result']['error'])
        this.closesEditModal()
        return
      }
      if (response['result'][0]['id'] == this.questionToUpdate.id){
        for (let i in this.questions){
          if (this.questions[i]['id'] == this.questionToUpdate.id){
            this.questions[i] = {}
            this.questions[i] = {...this.questionToUpdate}
        }
        }
      }
      this.closesEditModal()
    } catch (error) {
      this.openErrorModal(`Ha ocurrido un error inesperado`)
      this.closesEditModal()
    }

  }

  async createQuestion() : Promise<void>{
    try {
      const response = await this.http.post<any>(`/api/enlistcontrol/custom_templates/create_question`,
        {"jsonrpc": "2.0",
          "method": "call",
          "params": {"context": {},
              "name": this.questionToCreate.name,
              "description" : this.questionToCreate.description,
              "template_id": this.selectedTemplate
          },
      "id": null}).toPromise()
      if (response['result']['error'] != null){
        this.openErrorModal(response['result']['error'])
        this.closesCreateModal()
        return
      }
      if (response['result'][0]['id'] != null){
        this.questions.push(response['result'][0])
      }
      this.closesCreateModal()
    } catch (error) {
      this.openErrorModal(`Ha ocurrido un error inesperado`)
      this.closesCreateModal()
    }

  }

  async deleteQuestion(): Promise<any> {
    try {
      const response = await this.http.delete<any>(`/api/enlistcontrol/custom_templates/delete_question/${this.questionToDelete.id}`).toPromise();
      if (response[0]['error'] != null){
        this.openErrorModal(response[0]['error'])
        this.closesDeleteModal()
        return
      }
      if (response[0]['id'] == this.questionToDelete.id ){
        this.questions = this.questions.filter(question => question.id !== this.questionToDelete.id);
      }
    this.closesDeleteModal();
    return response;
    } catch (error) {
      this.openErrorModal(`Ha ocurrido un error inesperado`)
      this.closesDeleteModal()
    }

  }

  async getQuestions () : Promise<any[]>{
    try {
      const response = await this.http.get<any[]>(`/api/enlistcontrol/custom_templates/get_questions/${this.selectedTemplate}`).toPromise()
      try {
        if (response[0]['error'] != null){
          this.openErrorModal(response[0]['error'])
          return []
        }
      } catch (error) {

      }
      return response
    } catch (error) {
      this.openErrorModal(`Ha ocurrido un error inesperado obteniendo las preguntas ${error} `)
      return []
    }

  }

  // when is the fst call the component get que questions
  // to render that
  async ngOnInit(): Promise<void> {
    this.questions = await this.getQuestions();
  }

}
