<div class="enlist-container">
    <ng-container *ngIf="loading">
        <div class="spinner-grow text-success" role="status">
            <span class="visually-hidden"></span>
        </div>
        <div class="spinner-grow text-success" role="status">
            <span class="visually-hidden"></span>
        </div>
        <div class="spinner-grow text-success" role="status">
            <span class="visually-hidden"></span>
        </div>
    </ng-container>
    <ng-container *ngIf="!loading">
        <div class="card old-covid-card">
            <div
                class="card-header"
                [ngClass]="{
                    'old-covid-card-header-complete': data.state === 'creado',
                    'old-covid-card-header-incomplete':
                        data.state === 'incompleto'
                }"
                *ngIf="data"
            >
                Formulario Covid {{ data.folio }}
            </div>
            <div class="card-body old-covid-card-body">
                <div class="row">
                    <div class="col-6">
                        <div class="page-subheading">
                            <div class="header-icon">
                                <img
                                    src="./assets/icon/covid19.svg"
                                    width="120"
                                />
                            </div>
                            <div class="page-subheading-subtitle">
                                El nuevo Coronavirus (COVID-19) ha sido
                                catalogado por la Organización Mundial de la
                                Salud como una emergencia en salud pública de
                                importancia internacional (ESPII). Se han
                                identificado casos en todos los continentes y,
                                el 6 de marzo se confirmó el primer caso en
                                Colombia. La infección se produce cuando una
                                persona enferma tose o estornuda y expulsa
                                partículas del virus que entran en contacto con
                                otras personas.
                            </div>
                        </div>
                        <div class="row-cols-1 pb-4">
                            <span class="data-label"
                                ><strong>Fecha y Hora </strong></span
                            ><br />
                            <span class="data-text">{{ data.fecha }}</span>
                        </div>
                        <div class="row-cols-1 pb-4">
                            <span class="data-label"
                                ><strong>Conductor </strong></span
                            ><br />
                            <span class="data-text">{{
                                data.conductor.name
                            }}</span>
                        </div>
                        <div class="row-cols-1 pb-4">
                            <span class="data-label">
                                <strong>Temperatura: </strong><br />
                            </span>
                            <span
                                class="creado"
                                *ngIf="data.state === 'creado'"
                            >
                                {{ data.temperatura }} °C
                            </span>
                            <span
                                class="incompleto"
                                *ngIf="data.state === 'incompleto'"
                            >
                                {{ data.temperatura }} °C
                            </span>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card covid-card mb-4">
                            <div class="row">
                                <div class="col-4 covid-item">
                                    <div class="covid-item-icon">
                                        <ng-container *ngIf="!data.garganta">
                                            <img
                                                src="./assets/icon/covid19-gray.svg"
                                                width="100"
                                            />
                                        </ng-container>
                                        <ng-container *ngIf="data.garganta">
                                            <img
                                                src="./assets/icon/covid19-red.svg"
                                                width="100"
                                            />
                                        </ng-container>
                                    </div>
                                    <span
                                        class="mb-3 covid-item-label"
                                        [ngClass]="{
                                            state_incompleto: data.garganta,
                                            state_creado: !data.garganta
                                        }"
                                        >Dolor de garganta</span
                                    >
                                </div>
                                <div class="col-4 covid-item">
                                    <div class="covid-item-icon">
                                        <ng-container *ngIf="!data.tos">
                                            <img
                                                src="./assets/icon/covid19-gray.svg"
                                                width="100"
                                            />
                                        </ng-container>
                                        <ng-container *ngIf="data.tos">
                                            <img
                                                src="./assets/icon/covid19-red.svg"
                                                width="100"
                                            />
                                        </ng-container>
                                    </div>
                                    <span
                                        class="mb-3 covid-item-label"
                                        [ngClass]="{
                                            state_incompleto: data.tos,
                                            state_creado: !data.tos
                                        }"
                                        >Tos</span
                                    >
                                </div>
                                <div class="col-4 covid-item">
                                    <div class="covid-item-icon">
                                        <ng-container *ngIf="!data.respiracion">
                                            <img
                                                src="./assets/icon/covid19-gray.svg"
                                                width="100"
                                            />
                                        </ng-container>
                                        <ng-container *ngIf="data.respiracion">
                                            <img
                                                src="./assets/icon/covid19-red.svg"
                                                width="100"
                                            />
                                        </ng-container>
                                    </div>
                                    <span
                                        class="mb-3 covid-item-label"
                                        [ngClass]="{
                                            state_incompleto: data.respiracion,
                                            state_creado: !data.respiracion
                                        }"
                                        >Respiracion agitada</span
                                    >
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-4 covid-item">
                                    <div class="covid-item-icon">
                                        <ng-container *ngIf="!data.congestion">
                                            <img
                                                src="./assets/icon/covid19-gray.svg"
                                                width="100"
                                            />
                                        </ng-container>
                                        <ng-container *ngIf="data.congestion">
                                            <img
                                                src="./assets/icon/covid19-red.svg"
                                                width="100"
                                            />
                                        </ng-container>
                                    </div>
                                    <span
                                        class="mb-3 covid-item-label"
                                        [ngClass]="{
                                            state_incompleto: data.congestion,
                                            state_creado: !data.congestion
                                        }"
                                        >Congestion</span
                                    >
                                </div>
                                <div class="col-4 covid-item">
                                    <div class="covid-item-icon">
                                        <ng-container *ngIf="!data.fatiga">
                                            <img
                                                src="./assets/icon/covid19-gray.svg"
                                                width="100"
                                            />
                                        </ng-container>
                                        <ng-container *ngIf="data.fatiga">
                                            <img
                                                src="./assets/icon/covid19-red.svg"
                                                width="100"
                                            />
                                        </ng-container>
                                    </div>
                                    <span
                                        class="mb-3 covid-item-label"
                                        [ngClass]="{
                                            state_incompleto: data.fatiga,
                                            state_creado: !data.fatiga
                                        }"
                                        >Fatiga</span
                                    >
                                </div>
                                <div class="col-4 covid-item">
                                    <div class="covid-item-icon">
                                        <ng-container *ngIf="!data.escalofrios">
                                            <img
                                                src="./assets/icon/covid19-gray.svg"
                                                width="100"
                                            />
                                        </ng-container>
                                        <ng-container *ngIf="data.escalofrios">
                                            <img
                                                src="./assets/icon/covid19-red.svg"
                                                width="100"
                                            />
                                        </ng-container>
                                    </div>
                                    <span
                                        class="mb-3 covid-item-label"
                                        [ngClass]="{
                                            state_incompleto: data.escalofrios,
                                            state_creado: !data.escalofrios
                                        }"
                                        >Escalofríos</span
                                    >
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-4 covid-item">
                                    <div class="covid-item-icon">
                                        <ng-container *ngIf="!data.musculos">
                                            <img
                                                src="./assets/icon/covid19-gray.svg"
                                                width="100"
                                            />
                                        </ng-container>
                                        <ng-container *ngIf="data.musculos">
                                            <img
                                                src="./assets/icon/covid19-red.svg"
                                                width="100"
                                            />
                                        </ng-container>
                                    </div>
                                    <span
                                        class="mb-3 covid-item-label"
                                        [ngClass]="{
                                            state_incompleto: data.musculos,
                                            state_creado: !data.musculos
                                        }"
                                        >Musculos con problemas</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <button
                        [ngClass]="{
                            'ec-btn8': data.state === 'incompleto',
                            'ec-btn7': data.state === 'creado'
                        }"
                        (click)="openPDF()"
                    >
                        Imprimir
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</div>
