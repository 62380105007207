import {Component, Input, OnInit} from '@angular/core';
import {SwitchService} from '@services/switch.service';
import {Questions, Question} from '@/models/questions';
import {ApiService} from '@services/api.service';
import {Enlistment} from '@/models/Enlistment';

@Component({
    selector: 'app-novelty-check',
    templateUrl: './novelty-check.component.html',
    styleUrls: ['./novelty-check.component.scss']
})
export class NoveltyCheckComponent implements OnInit {
    @Input()
    news_maintenance: Question[] = [];
    @Input()
    enlist_id: number;
    @Input()
    checked: boolean;

    questions: Question[] = [];
    type: string = 'white';
    image: string | ArrayBuffer;
    data: Enlistment = null;
    enlistment: any = null;
    selected: number = 0;
    sizeNews: number;

    constructor(
        private modalServicer: SwitchService,
        private apiService: ApiService
    ) {}

    ngOnInit(): void {
        this.questions = this.news_maintenance.filter((novet) => novet.novetly);
        this.sizeNews = this.questions.length - 1;
    }

    handleUpload(event, i) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.questions[i].img_mainten = reader.result.toString();
        };
    }

    handleDesc(event, i) {
        const value = event.target.value;
        this.questions[i].description_mainten = value;
    }

    saveNews() {
        var data = {
            novetlys: this.questions,
            enlist_id: this.enlist_id
        };
        this.apiService
            .checkMaintenance(data)
            .then((response) => console.log(response));
    }

    backQuest(i) {
        if (i - 1 >= 0) {
            this.selected = i - 1;
        }
    }

    nextQuest(i) {
        if (i + 1 < this.questions.length) {
            this.selected = i + 1;
        }
    }

    pictNotLoading(event, i) {
        event.target.src = this.questions[i].img_mainten;
    }

    close() {
        this.modalServicer.$modal2.emit(false);
        this.modalServicer.$card_selected.emit(false);
    }

    onDestroy() {}
}
