<nav class="main-header navbar navbar-expand motion-black">
    <ul class="navbar-nav">
        <li class="nav-item">
            <a
                class="nav-link motion-link2"
                (click)="toggleMenuSidebar.emit()"
                role="button"
                ><i class="fas fa-bars"></i
            ></a>
        </li>
    </ul>

    <ul class="navbar-nav ml-auto">
        <!--        <app-messages-dropdown-menu></app-messages-dropdown-menu>-->
        <app-notifications *ngIf="show_notifications"></app-notifications>
        <a
            class="nav-link motion-link"
            data-toggle="dropdown"
            (click)="toggleNotifications()"
        >
            <i class="far fa-bell"></i>
            <span class="badge motion-link1 navbar-badge">{{
                notifications_new
            }}</span>
        </a>

        <!--        <app-language-dropdown></app-language-dropdown>-->
        <app-user-dropdown-menu></app-user-dropdown-menu>
        <!-- <li class="nav-item">
    </li> -->
    </ul>
</nav>
