<div
    class="question row"
    [ngStyle]="{
        background:
            _selected_question && _selected_question.id === id ? '#ff0032' : ''
    }"
>
    <div (click)="onSelectQuestion()">{{ name }}</div>
    <div (click)="onDeleteQuestion()">X</div>
</div>
