import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';
import {DateRange} from '@angular/material/datepicker';
import {Question, Questions} from '@/models/questions';
import {ApiService} from '@services/api.service';
import Swal from 'sweetalert2';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Console} from 'console';

@Component({
    selector: 'app-manage-custom-fields',
    templateUrl: './manage-custom-fields.component.html',
    styleUrls: ['./manage-custom-fields.component.scss']
})
export class ManageCustomFieldsComponent implements OnInit {
    custom_fields_enabled: boolean = false;
    custom_enlist_enabled: boolean = false;
    form_enlistment_template: FormGroup;
    enlistments: Question[] = [];
    account: any;
    enlist_custom_size: number = 0;
    modalRef: any;




    enlist_form = new FormGroup({
        name: new FormControl(null, Validators.required),
        description: new FormControl(null, Validators.required)
    });
    selected_enlist: Question;
    selected_enlist_bool: boolean;

    constructor(
        private fb: FormBuilder,
        private detector: ChangeDetectorRef,
        private apiService: ApiService,
        private modalService: NgbModal
    ) {}

    ngOnInit(): void {


        this.form_enlistment_template = this.buildFormEnlistment();
        this.getInfoAccount();
    }

    getInfoAccount() {
        this.apiService.getMyAccountInfo().then((account) => {
            this.account = account;
            this.custom_enlist_enabled = account.custom_enlist_enabled;
            this.custom_fields_enabled = account.custom_question_enabled;

            this.getEnlistQuestion();
        });
    }


    buildFormEnlistment(): FormGroup {
        return this.fb.group({
            enlist_questions: this.fb.array([])
        });
    }

    toggleCustomFields(event: any) {
        // this.custom_fields_enabled = !this.custom_fields_enabled;
    }

    toggleCustomEnlistment(content) {
        let active: String;
        console.log();
        if (!this.account.custom_enlist_enabled) {
            active = 'true';
            this.apiService.activeCustomTemplateEnlist(active).then(() => {
                this.getInfoAccount();
            });
        } else {
            this.modalRef = this.modalService.open(content, {
                centered: true,
                size: 'xl'
            });
        }
    }

    toggleCustomQuestion() {
        var data = {
            custom_question_enabled: !this.custom_fields_enabled
        };
        this.apiService.pullResource(data).then(() => {
            this.getInfoAccount();
        });
    }

    confirmEnlistment() {
        let active: String;
        active = 'false';
        this.apiService.activeCustomTemplateEnlist(active).then(() => {
            this.getInfoAccount();
        });
        this.modalRef.close();
    }

    cancelEnlistment() {
        this.custom_enlist_enabled = true;
        this.modalRef.close();
    }

    cleanEnlistQuestion() {
        this.selected_enlist = {
            name: '',
            description: ''
        };
        this.selected_enlist_bool = false;
        this.enlist_form.controls['name'].setValue('');
        this.enlist_form.controls['description'].setValue('');
    }

    getEnlistQuestion() {
        this.apiService.getEnlistQuestions().then((response) => {
            this.enlistments = response;
            this.enlist_custom_size =
                this.account.enlist_custom_size - this.enlistments.length;
        });
    }

    createQuestionEnlist() {
        if (
            this.enlist_form.controls['name'].valid &&
            this.enlist_form.controls['description'].valid
        ) {
            var form = {
                name: this.enlist_form.controls['name'].value,
                description: this.enlist_form.controls['description'].value
            };
            this.apiService.postEnlistQuestion(form).then(() => {
                this.getEnlistQuestion();
                this.cleanEnlistQuestion();
            });
        }
    }

    deleteEnlistQuestion(id: number) {
        this.apiService.deleteEnlistQuestion(id).then(() => {
            this.getEnlistQuestion();
        });
    }

    updateEnlistQuestion() {
        var data = {
            id: this.selected_enlist.id,
            name: this.enlist_form.controls['name'].value,
            description: this.enlist_form.controls['description'].value
        };
        this.apiService.updateEnlistQuestion(data).then(() => {
            this.getEnlistQuestion();
            this.cleanEnlistQuestion();
        });
    }

    fillEnlistQuestions(): Question[] {
        let data: Question[];
        this.enlist_questions.controls.forEach((item) => {
            let question: Question = {
                name: item.get('name').value,
                description: item.get('description').value
            };
            data.push(question);
        });
        return data;
    }


    get enlist_questions() {
        return this.form_enlistment_template.get(
            'enlist_questions'
        ) as FormArray;
    }


}
