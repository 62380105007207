import {Component, OnInit, SimpleChanges} from '@angular/core';
import {Notification} from '@components/models/Notification';
import {ApiService} from '@services/api.service';
import {SwitchService} from '@services/switch.service';
import {NotificationsService} from './notifications.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
    active: boolean = false;
    desactivate: boolean = false;
    notifications: Notification[];
    constructor(
        private switchService: SwitchService,
        private apiService: ApiService,
        private notifyService: NotificationsService
    ) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.active = true;
        }, 100);
        this.notifications = this.apiService.notifications.filter(
            (notification) => notification.status === 'new'
        );
        this.notifyService.$notifications.subscribe((value) => {
            this.active = value;
        });
        this.notifyService.$desactivate_notifications.subscribe((value) => {
            this.desactivate = value;
            if (this.desactivate) {
                setTimeout(() => {
                    this.notifyService.$notifications.emit(false);
                }, 2000);
            }
        });
        const newNotifications = this.notifications.filter((value) => {
            return value.status === 'new';
        });
        for (var i = 0; newNotifications.length > i; i++) {
            this.apiService.postNotifications(newNotifications[i].id);
        }
    }

    closeNotifications() {
        this.active = false;
        setTimeout(() => {
            this.switchService.$notifications.emit(false);
        }, 500);
    }
}
