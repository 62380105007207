<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link motion-black">
    <img
        src="assets/images/enlist-control-favicon.png"
        alt="MI SIM Logo"
        class="brand-image"
    />
    <span class="brand-text">Enlist Control</span>
</a>

<!-- Sidebar -->
<div class="sidebar motion-gray">
    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow: hidden" *ngIf="account">
        <ul
            class="nav nav-collapse-hide-child nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
        >
            <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
            <li class="nav-item">
                <div class="title-nav nav-link">
                  <p>Resumen</p>
                </div>
                <hr class="title-nav-separator" />
            </li>
            <li class="nav-item">
                <a [routerLink]="['/enlistments']" class="nav-link">
                    <i
                        [inlineSVG]="'./assets/icon/alistamientos.svg'"
                        class="nav-icon fas"
                    ></i>
                    <p class="title">Alistamientos</p>
                </a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/covid-forms']" class="nav-link">
                    <i
                        [inlineSVG]="'./assets/icon/covid19.svg'"
                        class="nav-icon fas"
                    ></i>
                    <p class="title">Control Covid</p>
                </a>
            </li>
            <li class="nav-item">
                <div class="title-nav nav-link">
                  <p>Recursos</p>
                </div>
                <hr class="title-nav-separator" />
            </li>
            <li class="nav-item">
                <a [routerLink]="['/report']" class="nav-link">
                    <i
                        [inlineSVG]="'./assets/icon/imprimir.svg'"
                        class="nav-icon fas"
                    ></i>
                    <p class="title">Generar informe</p>
                </a>
            </li>
            <li
                class="nav-item has-treeview"
                [ngClass]="{'menu-open': show_admin === true}"
            >
                <a (click)="toggleShowAdmin()" class="nav-link">
                    <i
                        [inlineSVG]="'./assets/icon/soporte.svg'"
                        class="nav-icon fas"
                    ></i>
                    <p class="title">
                        Administrar
                        <i class="right fas fa-angle-left"></i>
                    </p>
                </a>
                <ul class="nav nav-treeview">
                    <li class="nav-item">
                        <a [routerLink]="['/drivers']" class="nav-link">
                            <!--                  <i-->
                            <!--                    [inlineSVG]="-->
                            <!--                                    './assets/icon/covid19.svg'-->
                            <!--                                "-->
                            <!--                    class="nav-icon fas"-->
                            <!--                  ></i>-->
                            <p class="pl-4">Conductores</p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/vehicles']" class="nav-link">
                            <!--                  <i-->
                            <!--                    [inlineSVG]="-->
                            <!--                                    './assets/icon/covid19.svg'-->
                            <!--                                "-->
                            <!--                    class="nav-icon fas"-->
                            <!--                  ></i>-->
                            <p class="pl-4">Vehiculos</p>
                        </a>
                    </li>
                </ul>
            </li>
            <li class="nav-item">
                <div class="title-nav nav-link">
                  <p>Configuracion</p>
                </div>
                <hr class="title-nav-separator" />
            </li>
            <li class="nav-item">
                <a [routerLink]="['/notification-config']" class="nav-link">
                    <i
                        [inlineSVG]="'./assets/icon/notificaciones.svg'"
                        class="nav-icon fas"
                    ></i>
                    <p class="title">Notificaciones</p>
                </a>
            </li>
            <li class="nav-item">
                <a [routerLink]="['/custom-fields']" class="nav-link">
                    <i
                        [inlineSVG]="'./assets/icon/personalizado.svg'"
                        class="nav-icon fas"
                    ></i>
                    <p class="title">Preguntas extras</p>
                </a>
            </li>
            <li class="nav-item">
                <a (click)="logout()" class="nav-link">
                    <i
                        [inlineSVG]="'./assets/icon/salir.svg'"
                        class="nav-icon fas"
                    ></i>
                    <p class="title">Salir</p>
                </a>
            </li>
        </ul>
    </nav>
</div>
