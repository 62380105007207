<!--INICIO MODAL-->
<ng-template #content let-modal>
    <div class="modal-header" *ngIf="modal.modalTitle">
        <h3 class="kt-subheader__title">
            {{ model ? model.modalTitle : 'Buscador' }}
        </h3>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="min-height: 40vh !important">
        <div class="row align-items-center">
            <div class="col-12">
                <ng-container [ngTemplateOutlet]="tableTemplate"></ng-container>
            </div>
        </div>
    </div>
</ng-template>
<!--FIN MODAL-->

<!--FORMAS DE VISUALIZAR-->
<!-- <button
    *ngIf="model.isModal"
    class="btn btn-sm btn-light-success font-weight-bolder text-uppercase"
    type="button"
    (click)="open(content)"
>
    {{ model ? model.buttonTitle : 'Buscar' }}
</button> -->
<ng-container
    *ngIf="!model.isModal"
    [ngTemplateOutlet]="tableTemplate"
></ng-container>

<ng-container
    *ngIf="model.isModal"
    [ngTemplateOutlet]="tableTemplateModal"
></ng-container>
<!--FIN FORMAS DE VISUALIZAR-->

<!--begin::Card-->
<ng-template #tableTemplate>
    <div *ngIf="!removeCard" class="card card-custom">
        <!--begin::Header-->
        <div
            *ngIf="model.title"
            class="card-header flex-wrap border-0 pt-6 pb-0"
        >
            <div class="card-title">
                <h3 *ngIf="model.title" class="card-label">
                    {{ model ? model.title : 'Cargando...' }}
                    <span
                        *ngIf="model.description"
                        class="d-block text-muted pt-2 font-size-sm"
                        >{{ model ? model.description : '' }}</span
                    >
                </h3>
            </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body">
            <ng-container [ngTemplateOutlet]="datatableTemplate"></ng-container>
        </div>
        <!--end::Body-->
    </div>

    <ng-container
        *ngIf="removeCard"
        [ngTemplateOutlet]="datatableTemplate"
    ></ng-container>
</ng-template>
<!--end::CARD-->

<!--Table Modal-->
<ng-template #tableTemplateModal>
    <div *ngIf="!removeCard" class="card card-custom">
        <!--begin::Body-->
        <div class="card-body">
            <ng-container
                [ngTemplateOutlet]="datatableTemplateModal"
            ></ng-container>
        </div>
        <!--end::Body-->
    </div>

    <ng-container
        *ngIf="removeCard"
        [ngTemplateOutlet]="datatableTemplateModal"
    ></ng-container>
</ng-template>
<!--end::CARD-->

<!--begin: Datatable-->
<ng-template #datatableTemplate>
    <section class="content">
        <div class="container-fluid">
            <div class="main-card mb-3 mt-3 card-table">
                <div class="card-header-filter">
                    <ng-container>
                        <app-pagination
                            [model]="model"
                            [pagination]="model.pagination"
                            [title]="model.title"
                            (clickedPage)="clickedPage($event)"
                            (clickedPageSize)="clickedPageSize($event)"
                            (changeSearch)="changeSearch($event)"
                            (getTotalRecords)="getTotalRecords($event)"
                        ></app-pagination>
                    </ng-container>
                </div>
                <ng-container>
                    <div class="mat-elevation-z8 m-4">
                        <div
                            style="
                                overflow-x: auto;
                                width: 100%;
                                height: max-content;
                            "
                            id="kt_datatable"
                        >
                            <table
                                style="
                                    height: -webkit-fill-available;
                                    width: -webkit-fill-available;
                                    border-collapse: separate;
                                    border-spacing: 2px;
                                "
                            >
                                <thead>
                                    <tr>
                                        <th
                                            *ngIf="!hideIndex"
                                            style="
                                                font-style: normal;
                                                font-weight: 500;
                                                font-size: 12px;
                                                line-height: 16px;
                                                height: 26px;
                                                text-align: start;
                                                padding: 0 5px;
                                            "
                                        >
                                            <span></span>
                                        </th>

                                        <th
                                            *ngIf="!hideIndex"
                                            data-field="RecordID"
                                            style="
                                                font-style: normal;
                                                font-weight: 500;
                                                font-size: 12px;
                                                line-height: 16px;
                                                height: 26px;
                                                text-align: start;
                                                padding: 0 5px;
                                            "
                                            data-sort="asc"
                                        >
                                            <span style="width: 40px">#</span>
                                        </th>

                                        <th
                                            *ngFor="let field of model.fields"
                                            data-field="Actions"
                                            data-autohide-disabled="false"
                                            style="
                                                font-style: normal;
                                                font-weight: 500;
                                                font-size: 12px;
                                                line-height: 16px;
                                                height: 26px;
                                                text-align: center;
                                                padding: 0 5px;
                                            "
                                        >
                                            <span
                                                [style.width]="
                                                    field.symbol ||
                                                    field.type === 'progressbar'
                                                        ? field.type ===
                                                          'progressbar'
                                                            ? '20vw'
                                                            : '15rem'
                                                        : ''
                                                "
                                            >
                                                <ng-container
                                                    *ngIf="!field.filter"
                                                >
                                                    <span>
                                                        {{ field.title }}
                                                    </span>
                                                </ng-container>
                                                <ng-container
                                                  *ngIf="field.filter"
                                                >
                                                  <ng-container *ngIf="!field.filterDriver && !field.filterUnit">
                                                    <a (click)="updateFilterStatus(field.key, field.filterOrder)">
                                                      <span>
                                                          {{ field.title }}
                                                      </span>
                                                      <ng-container *ngIf="field.filterOrder === 'desc'">
                                                        <i class="fas fa-sort-amount-down-alt"></i>
                                                      </ng-container>
                                                      <ng-container *ngIf="field.filterOrder === 'asc'">
                                                        <i class="fas fa-sort-amount-up-alt"></i>
                                                      </ng-container>
                                                      <ng-container *ngIf="field.filterOrder === 'none'">
                                                        <i class="fas fa-filter"></i>
                                                      </ng-container>
                                                      <ng-container *ngIf="!field.filterOrder">
                                                        <i class="fas fa-filter"></i>
                                                      </ng-container>
                                                    </a>
                                                  </ng-container>
                                                  <ng-container *ngIf="field.filterDriver && field.data">
                                                    <div class="input-div">
                                                      <input
                                                        list="conductores-list"
                                                        class="
                                                              selectpickerDrivers
                                                              label-title
                                                              gray1
                                                              i-select
                                                          "
                                                        placeholder="{{ field.title }}"
                                                        autocomplete="off"
                                                        (change)="onChangeDriver(field.key, $event)"
                                                        (keyup.enter)="handleFilterByDriver($event)"
                                                      />
                                                      <datalist
                                                        class="content-input"
                                                        id="conductores-list"
                                                        style="box-sizing: border-box"
                                                      >
                                                          <option
                                                            *ngFor="let driver of field.data"
                                                            [value]="driver.id"
                                                          >
                                                              {{ driver.text }}
                                                          </option>
                                                      </datalist>
                                                  </div>

                                                  </ng-container>
                                                  <ng-container *ngIf="field.filterDriver && !field.data">
                                                    <span>
                                                          {{ field.title }}
                                                      </span>
                                                  </ng-container>
                                                  <ng-container *ngIf="field.filterUnit && field.data">
                                                    <div class="input-div">
                                                      <input
                                                        list="vehiculos-list"
                                                        class="
                                                              selectpickerVehiculos
                                                              label-title
                                                              gray1
                                                              i-select
                                                          "
                                                        placeholder="{{ field.title }}"
                                                        autocomplete="off"
                                                        (change)="onChangeUnit(field.key, $event)"
                                                        (keyup.enter)="handleFilterByUnit($event)"
                                                      />
                                                      <datalist
                                                        class="content-input"
                                                        id="vehiculos-list"
                                                        style="box-sizing: border-box"
                                                      >
                                                          <option
                                                            *ngFor="let unit of field.data"
                                                            [value]="unit.id"
                                                          >
                                                              {{ unit.text }}
                                                          </option>
                                                      </datalist>
                                                  </div>

                                                  </ng-container>
                                                  <ng-container *ngIf="field.filterUnit && !field.data">
                                                    <span>
                                                          {{ field.title }}
                                                      </span>
                                                  </ng-container>
<!--                                                  <ng-container *ngIf="field.data">-->
<!--                                                    <a (click)="showFilterSearch(field.key, field.data)">-->
<!--                                                      <span>-->
<!--                                                          {{ field.title }}-->
<!--                                                      </span>-->
<!--                                                      <ng-container *ngIf="field.filterOrder === 'none'">-->
<!--                                                        <i class="fas fas fa-filter"></i>-->
<!--                                                      </ng-container>-->
<!--                                                      <ng-container *ngIf="field.filterOrder !== 'none'">-->
<!--                                                        <i class="fas fas fa-search"></i>-->
<!--                                                      </ng-container>-->
<!--                                                    </a>-->
<!--                                                  </ng-container>-->
                                                </ng-container>
                                            </span>
                                        </th>
                                        <th
                                            *ngIf="model.enableActions"
                                            data-field="Actions"
                                            data-autohide-disabled="false"
                                            style="
                                                font-style: normal;
                                                font-weight: 500;
                                                font-size: 12px;
                                                line-height: 16px;
                                                height: 26px;
                                                text-align: center;
                                                padding: 0 5px;
                                            "
                                        >
                                            <span>Acciones</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    [style.min-height.px]="minHeight"
                                    style="background-color: #fbfbfb"
                                    *ngIf="model?.records?.length > 0"
                                >
                                    <ng-container
                                        *ngFor="
                                            let record of model.records;
                                            let i = index
                                        "
                                    >
                                        <tr
                                            data-row="10"
                                            [style.cursor]="
                                                actionRow
                                                    ? 'pointer'
                                                    : 'default'
                                            "
                                        >
                                            <td
                                                *ngIf="!hideIndex"
                                                [style.cursor]="
                                                    actionRow
                                                        ? 'pointer'
                                                        : 'default'
                                                "
                                                (click)="clickedRow(i)"
                                                style="
                                                    font-style: normal;
                                                    font-weight: normal;
                                                    font-size: 12px;
                                                    line-height: 12px;
                                                "
                                            >
                                                <a>
                                                    <i
                                                        class="fa fa-caret-{{
                                                            selectedRow == i
                                                                ? 'down'
                                                                : 'right'
                                                        }}"
                                                    ></i>
                                                </a>
                                            </td>

                                            <td
                                                *ngIf="!hideIndex"
                                                [style.cursor]="
                                                    actionRow
                                                        ? 'pointer'
                                                        : 'default'
                                                "
                                                (click)="clickedRow(i)"
                                                style="
                                                    font-style: normal;
                                                    font-weight: normal;
                                                    font-size: 12px;
                                                    line-height: 12px;
                                                "
                                                data-field="RecordID"
                                                aria-label="11"
                                            >
                                                <span style="width: 40px"
                                                    ><span>{{
                                                        i +
                                                            1 +
                                                            (model.pagination
                                                                .currentPage -
                                                                1) *
                                                                model.pagination
                                                                    .pageSize
                                                    }}</span></span
                                                >
                                            </td>

                                            <td
                                                [style.cursor]="
                                                    actionRow
                                                        ? 'pointer'
                                                        : 'default'
                                                "
                                                (click)="clickedRow(i)"
                                                *ngFor="
                                                    let field of model.fields
                                                "
                                                aria-label="Littel and Sons"
                                                style="
                                                    font-style: normal;
                                                    font-weight: normal;
                                                    font-size: 12px;
                                                    line-height: 12px;
                                                    text-align: center;
                                                "
                                            >
                                                <ng-container
                                                    *ngIf="!field.type"
                                                >
                                                    <span
                                                        *ngIf="
                                                            field.subobject &&
                                                            !field.symbol
                                                        "
                                                    >
                                                        <div
                                                            *ngIf="
                                                                field.date &&
                                                                !field.label
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ][field.subkey]
                                                                    | date
                                                            }}
                                                        </div>
                                                        <div
                                                            *ngIf="
                                                                !field.date &&
                                                                !field.label
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ][field.subkey]
                                                            }}
                                                        </div>

                                                        <span
                                                            *ngIf="field.label"
                                                            class="label label-lg label-light-{{
                                                                field.getLabel(
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                )
                                                            }} label-inline font-weight-bold"
                                                            >{{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}</span
                                                        >
                                                    </span>

                                                    <span
                                                        *ngIf="
                                                            !field.subobject &&
                                                            !field.subkey &&
                                                            !field.symbol
                                                        "
                                                    >
                                                        <div
                                                            *ngIf="
                                                                field.date &&
                                                                !field.label
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ] | date
                                                            }}
                                                        </div>
                                                        <div
                                                            *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                !field.valueBool &&
                                                                !field.filterCovid
                                                            "
                                                            style="text-align: center"
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ] ? record[
                                                                  field.key
                                                                  ] : '---'
                                                            }}
                                                        </div>
                                                        <div
                                                          *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                !field.valueBool &&
                                                                field.filterCovid
                                                            "
                                                          style="text-align: center"
                                                        >
                                                          <ng-container *ngIf="record[
                                                            field.key
                                                            ]">
                                                            <a class="link-covid" (click)="filterCovid(record[
                                                            field.key
                                                            ])">
                                                                {{record[
                                                              field.key
                                                              ] }}
                                                                </a>
                                                          </ng-container>
                                                          <ng-container *ngIf="!record[field.key]">
                                                            ---
                                                          </ng-container>
                                                        </div>
                                                        <div
                                                            *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                field.valueBool
                                                            "
                                                        >
                                                            <div
                                                                *ngIf="
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ] === true
                                                                "
                                                            >
                                                                Si
                                                            </div>
                                                            <div
                                                                *ngIf="
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ] === false
                                                                "
                                                            >
                                                                No
                                                            </div>
                                                        </div>

                                                        <span
                                                            *ngIf="field.label"
                                                            class="label label-lg label-light-{{
                                                                field.getLabel(
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                )
                                                            }} label-inline font-weight-bold"
                                                            >{{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}</span
                                                        >
                                                    </span>

                                                    <span
                                                        *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            field.symbol
                                                        "
                                                    >
                                                        <div
                                                            class="
                                                                d-flex
                                                                align-items-center
                                                            "
                                                        >
                                                            <div
                                                                class="
                                                                    symbol
                                                                    symbol-40
                                                                    symbol-light-primary
                                                                    flex-shrink-0
                                                                "
                                                            >
                                                                <span
                                                                    class="
                                                                        symbol-label
                                                                        font-size-h4
                                                                        font-weight-bold
                                                                    "
                                                                    >{{
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ][0]
                                                                    }}</span
                                                                >
                                                            </div>
                                                            <div class="ml-4">
                                                                <div
                                                                    *ngIf="
                                                                        field.date
                                                                    "
                                                                >
                                                                    {{
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] | date
                                                                    }}
                                                                </div>
                                                                <div
                                                                    *ngIf="
                                                                        !field.date
                                                                    "
                                                                >
                                                                    {{
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ]
                                                                    }}
                                                                </div>

                                                                <a>{{
                                                                    record[
                                                                        field
                                                                            .subkey
                                                                    ]
                                                                }}</a>
                                                            </div>
                                                        </div>
                                                    </span>

                                                    <span
                                                        *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            !field.symbol &&
                                                            !field.highlighted
                                                        "
                                                    >
                                                        <div
                                                            class="
                                                                font-weight-bolder
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}
                                                        </div>
                                                        <div>
                                                            {{
                                                                record[
                                                                    field.subkey
                                                                ]
                                                            }}
                                                        </div>
                                                    </span>

                                                    <span
                                                        *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            !field.symbol &&
                                                            field.highlighted
                                                        "
                                                    >
                                                        <div
                                                            class="
                                                                font-weight-bolder
                                                                text-primary
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}
                                                        </div>
                                                        <div
                                                            class="
                                                                font-weight-bold
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.subkey
                                                                ]
                                                            }}
                                                        </div>
                                                    </span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="field.type"
                                                >
                                                    <span
                                                        class="mr-2"
                                                        *ngIf="
                                                            field.type ===
                                                            'progressbar'
                                                        "
                                                    >
                                                        <div class="progress">
                                                            <div
                                                                class="
                                                                    progress-bar
                                                                    progress-bar-striped
                                                                    progress-bar-animated
                                                                "
                                                                [ngClass]="{
                                                                    'bg-gray-400':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] === 0,
                                                                    'bg-danger':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 30,
                                                                    'bg-warning':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] >=
                                                                            30 &&
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 50,
                                                                    'bg-primary':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] >=
                                                                            50 &&
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 100,
                                                                    'bg-success':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] ===
                                                                        100,
                                                                    '':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] === 0
                                                                }"
                                                                role="progressbar"
                                                                [ngStyle]="{
                                                                    'width.%':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] === 0
                                                                            ? 100
                                                                            : record[
                                                                                  field
                                                                                      .key
                                                                              ]
                                                                }"
                                                                aria-valuenow="10"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            >
                                                                {{
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                        | number
                                                                            : '1.2-2'
                                                                }}%
                                                            </div>
                                                        </div>
                                                    </span>
                                                  <span
                                                    class="mr-2"
                                                    *ngIf="
                                                            field.type ===
                                                            'ec-status'
                                                        "
                                                  >
                                                        <div [ngClass]="{
                                                          'creado': record[field.key] === 'creado' || record[field.key] !== 'incompleto',
                                                          'incompleto': record[field.key] === 'incompleto'
                                                        }">
                                                            {{record[field.key] === 'creado' ? 'Sin Novedades' : record[field.key] === 'incompleto' ? 'Con novedades': record[field.key] }}
                                                        </div>
                                                    </span>
                                                  <span
                                                    class="mr-2 mb-2"
                                                    *ngIf="
                                                            field.type ===
                                                            'ec-rc'
                                                        "
                                                  >
                                                      <ng-container *ngIf="record[field.key] === 'no' || record[field.key]  === false">
                                                        <i [inlineSVG]="'./assets/icon/ec-coordinacion.svg'" class=""></i>
                                                      </ng-container>
                                                      <ng-container *ngIf="record[field.key] === 'readed'">
                                                        <i [inlineSVG]="'./assets/icon/ec-coordinacion-visto.svg'" class=""></i>
                                                      </ng-container>
                                                      <ng-container *ngIf="record[field.key] === 'checked' || record[field.key]  === true">
                                                        <i [inlineSVG]="'./assets/icon/ec-coordinacion-revisado.svg'" class="icon_creado"></i>
                                                      </ng-container>
                                                    </span>
                                                  <span
                                                    class="mr-2"
                                                    *ngIf="
                                                            field.type ===
                                                            'ec-rg'
                                                        "
                                                  >
                                                        <ng-container *ngIf="record[field.key] === 'no' || record[field.key]  === false">
                                                          <i [inlineSVG]="'./assets/icon/ec-gerencia.svg'" class=""></i>
                                                        </ng-container>
                                                        <ng-container *ngIf="record[field.key] === 'readed'">
                                                          <i [inlineSVG]="'./assets/icon/ec-gerencia-visto.svg'" class=""></i>
                                                        </ng-container>
                                                        <ng-container *ngIf="record[field.key] === 'checked' || record[field.key]  === true">
                                                          <i [inlineSVG]="'./assets/icon/ec-gerencia-revisado.svg'" class="icon_creado"></i>
                                                        </ng-container>
                                                    </span>
                                                  <span
                                                    class="mr-2"
                                                    *ngIf="
                                                            field.type ===
                                                            'ec-rm'
                                                        "
                                                  >
                                                        <ng-container *ngIf="record[field.key] === 'no'">
                                                          <i [inlineSVG]="'./assets/icon/ec-mantenimiento.svg'" class=""></i>
                                                        </ng-container>
                                                        <ng-container *ngIf="record[field.key] === 'readed'">
                                                          <i [inlineSVG]="'./assets/icon/ec-mantenimiento-visto.svg'" class=""></i>
                                                        </ng-container>
                                                        <ng-container *ngIf="record[field.key] === 'checked'">
                                                          <i [inlineSVG]="'./assets/icon/ec-mantenimiento-revisado.svg'" class="icon_creado"></i>
                                                        </ng-container>
                                                    </span>
                                                </ng-container>
                                            </td>

                                            <td
                                                *ngIf="model.enableActions"
                                                data-field="Actions"
                                                data-autohide-disabled="false"
                                                aria-label="null"
                                                style="
                                                    font-family: Ubuntu;
                                                    font-style: normal;
                                                    font-weight: normal;
                                                    font-size: 12px;
                                                    line-height: 12px;
                                                "
                                            >
                                                <span>
                                                    <ng-container
                                                        *ngTemplateOutlet="
                                                            actionsTemplate;
                                                            context: {
                                                                $implicit:
                                                                    record,
                                                                index: i
                                                            }
                                                        "
                                                    ></ng-container>
                                                </span>
                                            </td>
                                        </tr>
                                        <div
                                            class="row"
                                            *ngIf="
                                                selectedRow === i && actionRow
                                            "
                                        >
                                            <div class="col-12">
                                                <ng-container
                                                    *ngTemplateOutlet="
                                                        actionRow;
                                                        context: {
                                                            $implicit: record,
                                                            index: index
                                                        }
                                                    "
                                                ></ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <!--begin::Table Empty-->
                        <!--end::Table Empty-->
                    </div>
                </ng-container>
            </div>
        </div>
    </section>

    <!--end: Datatable-->
</ng-template>

<ng-template #datatableTemplateModal>
    <section class="content-modal">
        <div class="container-fluid ">
            <div class="main-card mb-3 mt-3 card-table">
                <div class="card-header">
                    <ng-container>
                        <app-pagination
                            [pagination]="model.pagination"
                            [title]="model.title"
                            (clickedPage)="clickedPage($event)"
                            (clickedPageSize)="clickedPageSize($event)"
                            (getTotalRecords)="getTotalRecords($event)"
                            (changeSearch)="changeSearch($event)"
                        ></app-pagination>
                    </ng-container>
                </div>
                <ng-container>
                    <div class="mat-elevation-z8 m-4">
                        <div
                            style="
                                overflow-x: auto;
                                width: 100%;
                                height: max-content;
                            "
                            id="kt_datatable"
                        >
                            <table
                                style="
                                    height: -webkit-fill-available;
                                    width: -webkit-fill-available;
                                    border-collapse: separate;
                                    border-spacing: 2px;
                                "
                            >
                                <thead>
                                    <tr>
                                        <th
                                            *ngIf="!hideIndex"
                                            style="
                                                font-style: normal;
                                                font-weight: 500;
                                                font-size: 12px;
                                                line-height: 16px;
                                                height: 26px;
                                                text-align: start;
                                                padding: 0 5px;
                                            "
                                        >
                                            <span></span>
                                        </th>

                                        <th
                                            *ngIf="!hideIndex"
                                            data-field="RecordID"
                                            style="
                                                font-style: normal;
                                                font-weight: 500;
                                                font-size: 12px;
                                                line-height: 16px;
                                                height: 26px;
                                                text-align: start;
                                                padding: 0 5px;
                                            "
                                            data-sort="asc"
                                        >
                                            <span style="width: 40px">#</span>
                                        </th>

                                        <th
                                            *ngFor="let field of model.fields"
                                            data-field="Actions"
                                            data-autohide-disabled="false"
                                            style="
                                                font-style: normal;
                                                font-weight: 500;
                                                font-size: 12px;
                                                line-height: 16px;
                                                height: 26px;
                                                text-align: start;
                                                padding: 0 5px;
                                            "
                                        >
                                            <span
                                                [style.width]="
                                                    field.symbol ||
                                                    field.type === 'progressbar'
                                                        ? field.type ===
                                                          'progressbar'
                                                            ? '20vw'
                                                            : '15rem'
                                                        : ''
                                                "
                                            >
                                                <ng-container
                                                    *ngIf="!field.filter"
                                                >
                                                    <span>
                                                        {{ field.title }}
                                                    </span>
                                                </ng-container>
                                                <ng-container
                                                  *ngIf="field.filter"
                                                >
                                                  <a (click)="updateFilterStatus(field.key, field.filterOrder)">
                                                    <span>
                                                        {{ field.title }}
                                                    </span>
                                                    <ng-container *ngIf="field.filterOrder === 'desc'">
                                                      <i class="fas fa-sort-down"></i>
                                                    </ng-container>
                                                    <ng-container *ngIf="field.filterOrder === 'asc'">
                                                      <i class="fas fa-sort-up"></i>
                                                    </ng-container>
                                                    <ng-container *ngIf="field.filterOrder === 'none'">
                                                      <i class="fas fa-angle-down"></i>
                                                    </ng-container>
                                                  </a>

                                                </ng-container>
                                            </span>
                                        </th>
                                        <th
                                            *ngIf="model.enableActions"
                                            data-field="Actions"
                                            data-autohide-disabled="false"
                                            style="
                                                font-style: normal;
                                                font-weight: 500;
                                                font-size: 12px;
                                                line-height: 16px;
                                                height: 26px;
                                                text-align: start;
                                                padding: 0 5px;
                                            "
                                        >
                                            <span>Acciones</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    [style.min-height.px]="minHeight"
                                    style="background-color: #fbfbfb"
                                    *ngIf="model?.records?.length > 0"
                                >
                                    <ng-container
                                        *ngFor="
                                            let record of model.records;
                                            let i = index
                                        "
                                    >
                                        <tr data-row="10">
                                            <td
                                                *ngIf="!hideIndex"
                                                [style.cursor]="
                                                    actionRow
                                                        ? 'pointer'
                                                        : 'default'
                                                "
                                                (click)="clickedRow(i)"
                                                style="
                                                    font-style: normal;
                                                    font-weight: normal;
                                                    font-size: 12px;
                                                    line-height: 12px;
                                                "
                                            >
                                                <a>
                                                    <i
                                                        class="fa fa-caret-{{
                                                            selectedRow == i
                                                                ? 'down'
                                                                : 'right'
                                                        }}"
                                                    ></i>
                                                </a>
                                            </td>

                                            <td
                                                *ngIf="!hideIndex"
                                                [style.cursor]="
                                                    actionRow
                                                        ? 'pointer'
                                                        : 'default'
                                                "
                                                (click)="clickedRow(i)"
                                                style="
                                                    font-style: normal;
                                                    font-weight: normal;
                                                    font-size: 12px;
                                                    line-height: 12px;
                                                "
                                                data-field="RecordID"
                                                aria-label="11"
                                            >
                                                <span style="width: 40px"
                                                    ><span>{{
                                                        i +
                                                            1 +
                                                            (model.pagination
                                                                .currentPage -
                                                                1) *
                                                                model.pagination
                                                                    .pageSize
                                                    }}</span></span
                                                >
                                            </td>

                                            <td
                                                [style.cursor]="
                                                    actionRow
                                                        ? 'pointer'
                                                        : 'default'
                                                "
                                                (click)="clickedRow(i)"
                                                *ngFor="
                                                    let field of model.fields
                                                "
                                                aria-label="Littel and Sons"
                                                style="
                                                    font-style: normal;
                                                    font-weight: normal;
                                                    font-size: 12px;
                                                    line-height: 12px;
                                                "
                                            >
                                                <ng-container
                                                    *ngIf="!field.type"
                                                >
                                                    <span
                                                        *ngIf="
                                                            field.subobject &&
                                                            !field.symbol
                                                        "
                                                    >
                                                        <div
                                                            *ngIf="
                                                                field.date &&
                                                                !field.label
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ][field.subkey]
                                                                    | date
                                                            }}
                                                        </div>
                                                        <div
                                                            *ngIf="
                                                                !field.date &&
                                                                !field.label
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ][field.subkey]
                                                            }}
                                                        </div>

                                                        <span
                                                            *ngIf="field.label"
                                                            class="label label-lg label-light-{{
                                                                field.getLabel(
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                )
                                                            }} label-inline font-weight-bold"
                                                            >{{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}</span
                                                        >
                                                    </span>

                                                    <span
                                                        *ngIf="
                                                            !field.subobject &&
                                                            !field.subkey &&
                                                            !field.symbol
                                                        "
                                                    >
                                                        <div
                                                            *ngIf="
                                                                field.date &&
                                                                !field.label
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ] | date
                                                            }}
                                                        </div>
                                                        <div
                                                            *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                !field.valueBool
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}
                                                        </div>
                                                        <div
                                                            *ngIf="
                                                                !field.date &&
                                                                !field.label &&
                                                                field.valueBool
                                                            "
                                                        >
                                                            <div
                                                                *ngIf="
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ] === true
                                                                "
                                                            >
                                                                Si
                                                            </div>
                                                            <div
                                                                *ngIf="
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ] === false
                                                                "
                                                            >
                                                                No
                                                            </div>
                                                        </div>

                                                        <span
                                                            *ngIf="field.label"
                                                            class="label label-lg label-light-{{
                                                                field.getLabel(
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                )
                                                            }} label-inline font-weight-bold"
                                                            >{{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}</span
                                                        >
                                                    </span>

                                                    <span
                                                        *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            field.symbol
                                                        "
                                                    >
                                                        <div
                                                            class="
                                                                d-flex
                                                                align-items-center
                                                            "
                                                        >
                                                            <div
                                                                class="
                                                                    symbol
                                                                    symbol-40
                                                                    symbol-light-primary
                                                                    flex-shrink-0
                                                                "
                                                            >
                                                                <span
                                                                    class="
                                                                        symbol-label
                                                                        font-size-h4
                                                                        font-weight-bold
                                                                    "
                                                                    >{{
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ][0]
                                                                    }}</span
                                                                >
                                                            </div>
                                                            <div class="ml-4">
                                                                <div
                                                                    *ngIf="
                                                                        field.date
                                                                    "
                                                                >
                                                                    {{
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] | date
                                                                    }}
                                                                </div>
                                                                <div
                                                                    *ngIf="
                                                                        !field.date
                                                                    "
                                                                >
                                                                    {{
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ]
                                                                    }}
                                                                </div>

                                                                <a>{{
                                                                    record[
                                                                        field
                                                                            .subkey
                                                                    ]
                                                                }}</a>
                                                            </div>
                                                        </div>
                                                    </span>

                                                    <span
                                                        *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            !field.symbol &&
                                                            !field.highlighted
                                                        "
                                                    >
                                                        <div
                                                            class="
                                                                font-weight-bolder
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}
                                                        </div>
                                                        <div>
                                                            {{
                                                                record[
                                                                    field.subkey
                                                                ]
                                                            }}
                                                        </div>
                                                    </span>

                                                    <span
                                                        *ngIf="
                                                            !field.subobject &&
                                                            field.subkey &&
                                                            !field.symbol &&
                                                            field.highlighted
                                                        "
                                                    >
                                                        <div
                                                            class="
                                                                font-weight-bolder
                                                                text-primary
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.key
                                                                ]
                                                            }}
                                                        </div>
                                                        <div
                                                            class="
                                                                font-weight-bold
                                                            "
                                                        >
                                                            {{
                                                                record[
                                                                    field.subkey
                                                                ]
                                                            }}
                                                        </div>
                                                    </span>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="field.type"
                                                >
                                                    <span
                                                        class="mr-2"
                                                        *ngIf="
                                                            field.type ===
                                                            'progressbar'
                                                        "
                                                    >
                                                        <div class="progress">
                                                            <div
                                                                class="
                                                                    progress-bar
                                                                    progress-bar-striped
                                                                    progress-bar-animated
                                                                "
                                                                [ngClass]="{
                                                                    'bg-gray-400':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] === 0,
                                                                    'bg-danger':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 30,
                                                                    'bg-warning':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] >=
                                                                            30 &&
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 50,
                                                                    'bg-primary':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] >=
                                                                            50 &&
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] < 100,
                                                                    'bg-success':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] ===
                                                                        100,
                                                                    '':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] === 0
                                                                }"
                                                                role="progressbar"
                                                                [ngStyle]="{
                                                                    'width.%':
                                                                        record[
                                                                            field
                                                                                .key
                                                                        ] === 0
                                                                            ? 100
                                                                            : record[
                                                                                  field
                                                                                      .key
                                                                              ]
                                                                }"
                                                                aria-valuenow="10"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            >
                                                                {{
                                                                    record[
                                                                        field
                                                                            .key
                                                                    ]
                                                                        | number
                                                                            : '1.2-2'
                                                                }}%
                                                            </div>
                                                        </div>
                                                    </span>
                                                </ng-container>
                                            </td>

                                            <td
                                                *ngIf="model.enableActions"
                                                data-field="Actions"
                                                data-autohide-disabled="false"
                                                aria-label="null"
                                                style="
                                                    font-family: Ubuntu;
                                                    font-style: normal;
                                                    font-weight: normal;
                                                    font-size: 12px;
                                                    line-height: 12px;
                                                "
                                            >
                                                <span>
                                                    <ng-container
                                                        *ngTemplateOutlet="
                                                            actionsTemplate;
                                                            context: {
                                                                $implicit:
                                                                    record,
                                                                index: i
                                                            }
                                                        "
                                                    ></ng-container>
                                                </span>
                                            </td>
                                        </tr>
                                        <div
                                            class="row"
                                            *ngIf="
                                                selectedRow === i && actionRow
                                            "
                                        >
                                            <div class="col-12">
                                                <ng-container
                                                    *ngTemplateOutlet="
                                                        actionRow;
                                                        context: {
                                                            $implicit: record,
                                                            index: index
                                                        }
                                                    "
                                                ></ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                        <!--begin::Table Empty-->
                        <!--end::Table Empty-->
                    </div>
                </ng-container>
            </div>
        </div>
    </section>

    <!--end: Datatable-->
</ng-template>
