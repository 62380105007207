import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesDropdownMenuComponent} from '@modules/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import {AppButtonComponent} from './components/app-button/app-button.component';
import {registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {UserDropdownMenuComponent} from '@modules/main/header/user-dropdown-menu/user-dropdown-menu.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageDropdownComponent} from '@modules/main/header/language-dropdown/language-dropdown.component';
import {PrivacyPolicyComponent} from './modules/privacy-policy/privacy-policy.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {TableComponent} from './components/table/table.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {InlineSVGModule} from 'ng-inline-svg';
import {PopupSelectComponent} from './components/popup-select/popup-select.component';
import {AnimatedCounterComponent} from './components/animated-counter/animated-counter.component';
import {MatDialogModule} from '@angular/material/dialog';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {ModalComponent} from './components/modal/modal.component';
import {NotificationsComponent} from './components/notifications/notifications.component';
import { ManageEnlistmentsComponent } from './pages/manage-enlistments/manage-enlistments.component';
import { ManageCovidFormsComponent } from './pages/manage-covid-forms/manage-covid-forms.component';
import { ManageReportComponent } from './pages/manage-report/manage-report.component';
import { ManageDriversComponent } from './pages/manage-drivers/manage-drivers.component';
import { ManageUnitsComponent } from './pages/manage-units/manage-units.component';
import { SingleEnlistmentComponent } from './pages/single-enlistment/single-enlistment.component';
import { SingleCovidFormComponent } from './pages/single-covid-form/single-covid-form.component';
import { ManageNotificationsComponent } from './pages/manage-notifications/manage-notifications.component';
import { ManageCustomFieldsComponent } from './pages/manage-custom-fields/manage-custom-fields.component';
import { CardCounterComponent } from './components/card-counter/card-counter.component';
import { ManageOldEnlistmentsComponent } from './pages/manage-old-enlistments/manage-old-enlistments.component';
import { ManageOldCovidFormsComponent } from './pages/manage-old-covid-forms/manage-old-covid-forms.component';
import { SingleOldEnlistmentComponent } from './pages/single-old-enlistment/single-old-enlistment.component';
import { SingleOldCovidFormComponent } from './pages/single-old-covid-form/single-old-covid-form.component';
import {CardsModule, CarouselModule} from "angular-bootstrap-md";
import {MatSelectModule} from "@angular/material/select";
import { QuestionsMakerComponent } from './components/questions-maker/questions-maker.component';
import { QuestionComponent } from './components/question/question.component';
import { ActionConfirmComponent } from './components/action-confirm/action-confirm.component';
import { MotionCheckboxComponent } from './components/motion-checkbox/motion-checkbox.component';
import { RptOldEnlistComponent } from './components/rpt-old-enlist/rpt-old-enlist.component';
import { EmailsComponentComponent } from './components/emails-component/emails-component.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NoveltyCheckComponent } from './components/novelty-check/novelty-check.component';
import { AccordionTemplatesComponent } from './components/accordion-templates/accordion-templates.component';
import { QuestionsPerTemplateComponent } from './components/questions-per-template/questions-per-template.component';

registerLocaleData(localeEs, 'es');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesDropdownMenuComponent,
        AppButtonComponent,
        UserDropdownMenuComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageDropdownComponent,
        PrivacyPolicyComponent,
        TableComponent,
        PaginationComponent,
        PopupSelectComponent,
        AnimatedCounterComponent,
        ModalComponent,
        NotificationsComponent,
        ManageEnlistmentsComponent,
        ManageCovidFormsComponent,
        ManageReportComponent,
        ManageDriversComponent,
        ManageUnitsComponent,
        SingleEnlistmentComponent,
        SingleCovidFormComponent,
        ManageNotificationsComponent,
        ManageCustomFieldsComponent,
        CardCounterComponent,
        ManageOldEnlistmentsComponent,
        ManageOldCovidFormsComponent,
        SingleOldEnlistmentComponent,
        SingleOldCovidFormComponent,
        QuestionsMakerComponent,
        QuestionComponent,
        ActionConfirmComponent,
        MotionCheckboxComponent,
        RptOldEnlistComponent,
        EmailsComponentComponent,
        NoveltyCheckComponent,
        AccordionTemplatesComponent,
        QuestionsPerTemplateComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
        }),
        MatFormFieldModule,
        MatDatepickerModule,
        FormsModule,
        MatTableModule,
        MatPaginatorModule,
        InlineSVGModule,
        MatDialogModule,
        SweetAlert2Module,
        CarouselModule,
        CardsModule,
        MatSelectModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
