import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {
    faTh,
    faCheck,
    faTrash,
    faAngleDown,
    faAngleUp
} from '@fortawesome/free-solid-svg-icons';
import {AppService} from '@services/app.service';
import {ApiService} from '@services/api.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
    heading = 'MI Cuenta';
    subheading = 'Bienvenido al panel de control MI SIM.';
    icon = 'pe-7s-id icon-gradient bg-happy-fisher';

    faTh = faTh;
    faCheck = faCheck;
    faTrash = faTrash;
    faAngleDown = faAngleDown;
    faAngleUp = faAngleUp;

    messages_count: number = null;
    cards_count: number = null;

    user: any = null;
    account: any = null;
    cardsCount: number = 0;

    constructor(
        private appService: AppService,
        private apiService: ApiService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.appService
            .getSessionInfo()
            .then((user) => (user ? (this.user = user) : this.logout()));
        // this.apiService.getCardsInfo(1, 1).then((messages) => {
        //     this.cardsCount = messages.total_cards_count;
        //     console.log(this.cardsCount);
        //     this.cd.detectChanges();
        // });
        // this.apiService.getMessageInfo(1, 1).then((messages) => {
        //     console.log(messages.total_messages_count);
        //     this.messages_count = messages.total_messages_count;
        // });
        this.appService
            .getAccountInfo()
            .then((account) =>
                account ? (this.account = account) : this.logout()
            );
    }
    ngOnDestroy() {
        console.log('Componente dashboard destruido');
    }
    logout() {
      this.appService.logout().then((res)=>{
        console.log("bye");
      });
    }
}
