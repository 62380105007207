import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;

    public account: any = null;

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private http: HttpClient
    ) {}

    async loginByAuth({email, password}) {
        try {
            let body = {
                jsonrpc: '2.0',
                params: {
                    login: email,
                    password: password,
                    //---------------------CAMBIAR------------------------
                    db: 'plataforma_motion'
                }
            };

            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':
                    'https://build.monitoringinnovation.com',
                'Access-Control-Allow-Credentials': 'true'
            });

            this.http
                .post<any>('/api/enlistcontrol/auth/', JSON.stringify(body), {
                    headers: headers
                })
                .subscribe((data) => {
                    console.log(data);
                    if (data.hasOwnProperty('error')) {
                        console.log('Credenciales invalidas!!!');
                        console.log(data.error.data.message);
                        this.toastr.error(data.error.data.message);
                    } else {
                        if (data.result.hasOwnProperty('error')) {
                            console.log('No tienes acceso!');
                            this.toastr.error(data.result.error.data.message);
                        } else if (data.result.uid > 0) {
                            console.log('Login exitoso!');
                            console.log(data.result);
                            // localStorage.setItem('sessionId', data.result);
                            this.saveUserInfo(data.result);
                            this.router.navigate(['/']);
                        } else {
                            this.toastr.error(
                                'Algo salio mal, intentalo de nuevo.'
                            );
                        }
                    }
                });
        } catch (error) {
            this.toastr.error(error.response.data.message);
        }
    }

    async getProfile() {
        try {
            this.user = await this.http
                .get<any>('/api/enlistcontrol/session')
                .toPromise();
            if (!this.user) {
                await this.logout();
            }
        } catch (error) {
            await this.logout();
            throw error;
        }
    }

    async logout() {
        await this.router.navigate(['/login']);
        let res = await this.http
            .get<any>('/api/enlistcontrol/logout')
            .toPromise();
        console.log(res);
        this.user = null;
    }

    async getSessionInfo() {
        let user = await this.http
            .get<any>('/api/enlistcontrol/session')
            .toPromise();
        if (user.uid != null) {
            return user;
        } else {
            // await this.logout();
            return null;
        }
    }

    async getAccountInfo() {
        let account = await this.http
            .get<any>('/api/enlistcontrol/myaccount')
            .toPromise();
        if (account) {
            this.account = account;
            return account;
        } else {
            return null;
        }
    }

    async putAccountInfo(data) {
        var body = {
            jsonrpc: '2.0',
            params: {
                name: data.name,
                email: data.email,
                phone: data.phone,
                street: data.street
            }
        };
        console.log(body);
        let account = await this.http
            .post<any>('/api/enlistcontrol/myaccount/update', body)
            .toPromise();
        if (account) {
            this.account = account;
            return account;
        } else {
            return null;
        }
    }

    private saveUserInfo(result) {
        this.user = result;
    }

    // Returns an observable
    async uploadLogo(fileb64: string) {
        let updatedLogo = await this.http
            .post<any>('/api/enlistcontrol/update/logo', {
                jsonrpc: '2.0',
                params: {
                    logo: fileb64
                }
            })
            .toPromise();
        if (!updatedLogo.error) {
            console.log(updatedLogo);
            return null;
        } else {
            return null;
        }
    }

    async uploadSignature(fileb64: string) {
        let updatedSignature = await this.http
            .post<any>('/api/enlistcontrol/update/signature', {
                jsonrpc: '2.0',
                params: {
                    signature: fileb64
                }
            })
            .toPromise();
        if (!updatedSignature.error) {
            console.log(updatedSignature);
            return null;
        } else {
            return null;
        }
    }


    // Returns an observable
    async uploadIso(fileb64: string) {
        let updatedIso = await this.http
            .post<any>('/api/enlistcontrol/update/iso', {
                jsonrpc: '2.0',
                params: {
                    logo: fileb64
                }
            })
            .toPromise();
        if (!updatedIso.error) {
            console.log(updatedIso);
            return null;
        } else {
            return null;
        }
    }
}
