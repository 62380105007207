<div class="PDF-OLD-ENLIST" id="pdfTable" #pdfTable>
    <div class="column header">
        <div class="row">Alistamiento: {{ data.folio }}</div>
        <div class="row">Fecha: {{ data.fecha }}</div>
    </div>
    <div class="row sub-header" style="display: flex; flex-direction: row">
        <div class="column sb-h-item">
            <div class="item-h">Vehiculo:</div>
            <div class="item-r">{{ data.vehiculo }}</div>
        </div>
        <div class="column sb-h-item">
            <div class="item-h">Conductor:</div>
            <div class="item-r">{{ data.conductor }}</div>
        </div>
        <div class="column sb-h-item">
            <div class="item-h">Revision Gerencia:</div>
            <div class="item-r">no</div>
        </div>
        <div class="column sb-h-item">
            <div class="item-h">Revision jefe de mantenimiento:</div>
            <div class="item-r">no</div>
        </div>
    </div>
    <div class="body">
        <div class="page-report">
            <table class="report-table">
                <tr class="thead">
                    <th class="ih">ITEM</th>
                    <th class="ih">CON NOVEDAD</th>
                    <th class="ih">SIN NOVEDAD</th>
                </tr>
                <tr class="tbody">
                    <td class="ib">Documentos del vehiculo</td>
                    <td class="ib">
                        {{ !data.documentos_vehiculo ? 'X' : '-' }}
                    </td>
                    <td class="ib">
                        {{ data.documentos_vehiculo ? 'X' : '-' }}
                    </td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Documentos del conductor</td>
                    <td class="ib">
                        {{ !data.documentos_conductor ? 'X' : '-' }}
                    </td>
                    <td class="ib">
                        {{ data.documentos_conductor ? 'X' : '-' }}
                    </td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Calcomania como conduzco</td>
                    <td class="ib">{{ !data.calcomania ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.calcomania ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Aire</td>
                    <td class="ib">{{ !data.aire ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.aire ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Aseo</td>
                    <td class="ib">{{ !data.aseo ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.aseo ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Bateria</td>
                    <td class="ib">{{ !data.baterias ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.baterias ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Botiquin</td>
                    <td class="ib">{{ !data.botiquin ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.botiquin ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Celular con minutos</td>
                    <td class="ib">{{ !data.celular ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.celular ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Cinturones</td>
                    <td class="ib">{{ !data.cinturones ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.cinturones ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Dispositivo de velocidad</td>
                    <td class="ib">
                        {{ !data.disp_velocidad ? 'X' : '-' }}
                    </td>
                    <td class="ib">
                        {{ data.disp_velocidad ? 'X' : '-' }}
                    </td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Equipo de carretera</td>
                    <td class="ib">
                        {{ !data.equipo_carretera ? 'X' : '-' }}
                    </td>
                    <td class="ib">
                        {{ data.equipo_carretera ? 'X' : '-' }}
                    </td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Estado escalera puerta conductor</td>
                    <td class="ib">
                        {{ !data.estado_esc_p_conductor ? 'X' : '-' }}
                    </td>
                    <td class="ib">
                        {{ data.estado_esc_p_conductor ? 'X' : '-' }}
                    </td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Estado escalera puerta pasajero</td>
                    <td class="ib">
                        {{ !data.estado_esc_p_pasajero ? 'X' : '-' }}
                    </td>
                    <td class="ib">
                        {{ data.estado_esc_p_pasajero ? 'X' : '-' }}
                    </td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Extintor</td>
                    <td class="ib">{{ !data.extintor ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.extintor ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Filtros</td>
                    <td class="ib">{{ !data.filtros ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.filtros ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Frenos</td>
                    <td class="ib">{{ !data.frenos ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.frenos ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Frenos de emergencia</td>
                    <td class="ib">
                        {{ !data.frenos_emergencia ? 'X' : '-' }}
                    </td>
                    <td class="ib">
                        {{ data.frenos_emergencia ? 'X' : '-' }}
                    </td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Herramientas</td>
                    <td class="ib">{{ !data.herramientas ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.herramientas ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Linterna</td>
                    <td class="ib">{{ !data.linterna ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.linterna ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Llantas</td>
                    <td class="ib">{{ !data.llantas ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.llantas ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Luces</td>
                    <td class="ib">{{ !data.luces ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.luces ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Motor</td>
                    <td class="ib">{{ !data.motor ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.motor ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Niveles</td>
                    <td class="ib">{{ !data.niveles ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.niveles ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Parabrisas</td>
                    <td class="ib">{{ !data.parabrisas ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.parabrisas ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Pito</td>
                    <td class="ib">{{ !data.pito ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.pito ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Repuesto</td>
                    <td class="ib">{{ !data.repuesto ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.repuesto ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Espejos retrovisores</td>
                    <td class="ib">{{ !data.retrovisores ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.retrovisores ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Ruteros</td>
                    <td class="ib">{{ !data.ruteros ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.ruteros ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Silla conductor</td>
                    <td class="ib">
                        {{ !data.silla_conductor ? 'X' : '-' }}
                    </td>
                    <td class="ib">
                        {{ data.silla_conductor ? 'X' : '-' }}
                    </td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Silleteria general</td>
                    <td class="ib">{{ !data.silleteria ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.silleteria ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Tapas</td>
                    <td class="ib">{{ !data.tapas ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.tapas ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Tension de correas</td>
                    <td class="ib">{{ !data.tension ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.tension ? 'X' : '-' }}</td>
                </tr>
                <tr class="tbody">
                    <td class="ib">Transmision</td>
                    <td class="ib">{{ !data.transmision ? 'X' : '-' }}</td>
                    <td class="ib">{{ data.transmision ? 'X' : '-' }}</td>
                </tr>
            </table>
        </div>
        <div class="page-novetly">
            <div class="title">Novedades</div>
            <div class="rp-novetly" *ngIf="!data.documentos_vehiculo">
                <div class="novetly-title">Documentos del vehiculo</div>
                <img
                    src="data:image/png;base64,{{
                        data.img_documentos_vehiculo
                    }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_documentos_vehiculo }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.documentos_conductor">
                <div class="novetly-title">Documentos del conductor</div>
                <img
                    src="data:image/png;base64,{{
                        data.img_documentos_conductor
                    }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_documentos_conductor }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.calcomania">
                <div class="novetly-title">Calcomania como conduzco</div>
                <img
                    src="data:image/png;base64,{{ data.img_calcomania }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_calcomania }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.aire">
                <div class="novetly-title">Aseo</div>
                <img
                    src="data:image/png;base64,{{ data.img_aire }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_aire }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.aseo">
                <div class="novetly-title">Aire</div>
                <img
                    src="data:image/png;base64,{{ data.img_aseo }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_aseo }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.baterias">
                <div class="novetly-title">Bateria</div>
                <img
                    src="data:image/png;base64,{{ data.img_baterias }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_baterias }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.botiquin">
                <div class="novetly-title">Botiquin</div>
                <img
                    src="data:image/png;base64,{{ data.img_botiquin }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_botiquin }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.celular">
                <div class="novetly-title">Celular con minutos</div>
                <img
                    src="data:image/png;base64,{{ data.img_celular }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_celular }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.cinturones">
                <div class="novetly-title">Cinturones</div>
                <img
                    src="data:image/png;base64,{{ data.img_cinturones }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_cinturones }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.disp_velocidad">
                <div class="novetly-title">Dispositivo de velocidad</div>
                <img
                    src="data:image/png;base64,{{ data.img_disp_velocidad }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_disp_velocidad }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.equipo_carretera">
                <div class="novetly-title">Equipo de carretera</div>
                <img
                    src="data:image/png;base64,{{ data.img_equipo_carretera }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_equipo_carretera }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.estado_esc_p_conductor">
                <div class="novetly-title">
                    Estado escalera puerta conductor
                </div>
                <img
                    src="data:image/png;base64,{{
                        data.img_estado_esc_p_conductor
                    }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_estado_esc_p_conductor }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.estado_esc_p_pasajero">
                <div class="novetly-title">Estado escalera puerta pasajero</div>
                <img
                    src="data:image/png;base64,{{
                        data.img_estado_esc_p_pasajero
                    }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_estado_esc_p_pasajero }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.extintor">
                <div class="novetly-title">Extintor</div>
                <img
                    src="data:image/png;base64,{{ data.img_extintor }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_extintor }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.filtros">
                <div class="novetly-title">Filtros</div>
                <img
                    src="data:image/png;base64,{{ data.img_filtros }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_filtros }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.frenos">
                <div class="novetly-title">Frenos</div>
                <img
                    src="data:image/png;base64,{{ data.img_frenos }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_frenos }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.frenos_emergencia">
                <div class="novetly-title">Frenos de emergencia</div>
                <img
                    src="data:image/png;base64,{{ data.img_frenos_emergencia }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_frenos_emergencia }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.herramientas">
                <div class="novetly-title">Herramientas</div>
                <img
                    src="data:image/png;base64,{{ data.img_herramientas }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_herramientas }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.linterna">
                <div class="novetly-title">Linterna</div>
                <img
                    src="data:image/png;base64,{{ data.img_linterna }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_linterna }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.llantas">
                <div class="novetly-title">Llantas</div>
                <img
                    src="data:image/png;base64,{{ data.img_llantas }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_llantas }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.luces">
                <div class="novetly-title">Luces</div>
                <img
                    src="data:image/png;base64,{{ data.img_luces }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_luces }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.motor">
                <div class="novetly-title">Motor</div>
                <img
                    src="data:image/png;base64,{{ data.img_motor }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_motor }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.niveles">
                <div class="novetly-title">Niveles</div>
                <img
                    src="data:image/png;base64,{{ data.img_niveles }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_niveles }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.parabrisas">
                <div class="novetly-title">Parabrisas</div>
                <img
                    src="data:image/png;base64,{{ data.img_parabrisas }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_parabrisas }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.pito">
                <div class="novetly-title">Pito</div>
                <img
                    src="data:image/png;base64,{{ data.img_pito }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_pito }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.repuesto">
                <div class="novetly-title">Repuesto</div>
                <img
                    src="data:image/png;base64,{{ data.img_repuesto }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_repuesto }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.retrovisores">
                <div class="novetly-title">Espejos retrovisores</div>
                <img
                    src="data:image/png;base64,{{ data.img_retrovisores }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_retrovisores }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.ruteros">
                <div class="novetly-title">Ruteros</div>
                <img
                    src="data:image/png;base64,{{ data.img_ruteros }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_ruteros }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.silla_conductor">
                <div class="novetly-title">Silla conductor</div>
                <img
                    src="data:image/png;base64,{{ data.img_silla_conductor }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_silla_conductor }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.silleteria">
                <div class="novetly-title">Silleteria general</div>
                <img
                    src="data:image/png;base64,{{ data.img_silleteria }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_silleteria }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.tapas">
                <div class="novetly-title">Tapas</div>
                <img
                    src="data:image/png;base64,{{ data.img_tapas }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_tapas }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.tension">
                <div class="novetly-title">Tension de correas</div>
                <img
                    src="data:image/png;base64,{{ data.img_tension }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_tension }}
                </div>
            </div>
            <div class="rp-novetly" *ngIf="!data.transmision">
                <div class="novetly-title">Transmision</div>
                <img
                    src="data:image/png;base64,{{ data.img_tension }}"
                    class="novetly-image"
                    alt="Evidencia"
                />
                <div class="novetly-description">
                    Descripcion: {{ data.desc_transmision }}
                </div>
            </div>
        </div>
    </div>
</div>
