import { Pagination } from '../models/Pagination'
import { Observable } from 'rxjs';
import { Select2OptionData } from 'ng-select2';
import { CommandResult } from '../models/CommandResult';

export class TableViewModel {
  title?: string;
  description?: string;
  fields: Field[];
  records: any[];
  enableActions: boolean;
  isModal?: boolean;
  buttonTitle?: string;
  modalTitle?: string;
  pagination?: Pagination;
  filter?: Filter;
  showFilters: boolean;

  removeCard?: boolean;
  checkable?: boolean;

}

export class Field {

  constructor(
    title:string,
    key: string,
    values: (val : string, page?: number, pageSize?: number) => Observable<CommandResult>,
    highlighted=false,
    symbol=false,
    uniqueSelection=false, theme="default"){
    this.subobject = false;
    this.model = [];
    this.title = title;
    this.key = key;
    this.highlighted = highlighted;
    this.symbol = symbol;
    this.uniqueSelection = uniqueSelection;
    this.filter = false;
    this.filterOrder = 'none';
    this.filterDriver = false;
    this.filterUnit = false;
    this.filterCovid = false;
    this.data = [];
    this.theme = theme;
    this.icon = "fas fa-filter"
    this.label_width = "90"
    this.addCheckbox = false;
    this.selectedField = false;
    this.values = values;
    this.onFilter = (val) => {
      values(val).subscribe(x => {
        if (!x.data) {
          console.error("data is returning undefined for " + key);
        }
        this.data= x.data;
      });
    }
  }

  title: string;
  key?: string;
  dKey?: ((item :any) => string);
  subkey?: string;
  subobject?: boolean = false;
  displayWith?: (item) => string;
  idWith?: (item) => string | number;
  highlighted?: boolean;
  date?: boolean;
  type? : 'text' | 'date' | 'progressbar' | 'ec-rc' | 'ec-rg' | 'ec-status' | 'ec-rm';
  dynamic?: boolean;
  symbol?: boolean;
  valueBool?: boolean;
  data?: Array<Select2OptionData> | Array<Value>;
  values?: (val : string, page?: number, pageSize?: number) => Observable<CommandResult>;
  model? : any[] | DateRange = [];
  filter?: boolean;
  filterOrder?: string  = 'none';
  filterDriver?: boolean;
  filterUnit?: boolean;
  filterCovid?: boolean;
  uniqueSelection? : boolean;
  onFilter?: (val: string, page?: number, pageSize?: number) => any;
  theme?: string;
  label? : boolean;
  getLabel?: (item:any)=> string;
  icon?: string;
  label_width?: string;
  enableGroup?: boolean;
  addCheckbox?: boolean;
  selectedField?: boolean;
  isSelected?: boolean = true;

}


export class Value {
  constructor(id: string | number, text: string){
    this.id = id;
    this.text = text;
  }
  id: string | number;
  text?: string;
  selected?: boolean;
  disabled?: boolean;
  metadata?: Metadata;
}

export interface Filter {
  [filter: string]: any | DateRange;

  page: number;
  pageSize: number;
}

export interface Metadata {
  [data: string]: any;
}

export class DateRange {
  start: Date = new Date();
  end: Date = new Date();

  isValid(){
    if( this.start || this.end){
      return true;
    }
    return false;
  }
}

export class ECFilter {
  key: string;
  order: string;
}
