import {Injectable} from '@angular/core';
import {Workbook} from 'exceljs';
import * as fs from 'file-saver';
import { SwitchService } from './switch.service';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {
    constructor(
      private modalService: SwitchService
    ) {}

    /**
     * Needs the excel template to insert the data and generate the excel file
     *
     * template => { title, header, keys }
     *
     * data => Array
     */
    constructModel(template: any, data: any[]) {
        const fields = [];
        for(const row of data) {
            const field = template.keys.map((key: string) => row[key]);
            fields.push(field);
        }
        template.data = fields;
        return template;
    }

    async generateExcel(model: any) {
        console.log('generar el excel ');
        // Excel Title, Header, Data
        const title = model.title;
        const header = model.header;
        const data = model.data;

        // Create workbook and worksheet
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(model.title);

        // Add Row and formatting
        const titleRow = worksheet.addRow([title]);
        titleRow.font = {
            name: 'Montserrat',
            family: 4,
            size: 16,
            bold: true
        };
        worksheet.addRow([]);
        worksheet.mergeCells('A1:D2');

        // Add Header Row
        const headerRow = worksheet.addRow(header);

        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFFFFF00'},
                bgColor: {argb: 'FF0000FF'}
            };
            cell.border = {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'}
            };
        });

        // Add Data and Conditional Formatting
        data.forEach((d) => {
            const row = worksheet.addRow(d);
            const qty = row.getCell(5);
            let color = 'FF99FF99';
            if (+qty.value < 500) {
                color = 'FF9999';
            }

            qty.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: color}
            };
        });

        worksheet.getColumn(3).width = 30;
        worksheet.getColumn(4).width = 30;
        worksheet.addRow([]);

        // Footer Row
        const footerRow = worksheet.addRow([
            'This is system generated excel sheet.'
        ]);
        footerRow.getCell(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'FFCCFFE5'}
        };
        footerRow.getCell(1).border = {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'}
        };

        // Merge Cells
        worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            fs.saveAs(blob, `${model.title}.xlsx`);
        });
        this.modalService.$modal.emit(false)
    }

    async reportExcel(model: any) {
        // Excel Title, Header, Data
        const title = model.title;
        const header = model.header;
        const data = model.data;

        // Create workbook and worksheet
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(model.title);

        // Add Row and formatting
        const titleRow = worksheet.addRow([title]);
        titleRow.font = {
            name: 'Montserrat',
            family: 4,
            size: 16,
            bold: true
        };
        worksheet.addRow([]);
        worksheet.mergeCells('A1:D2');

        // Add Row and formatting
        const drivers = worksheet.addRow([`Conductores: ${model.drivers}`]);
        drivers.font = {
            name: 'Calibri',
            family: 4,
            size: 12
        };
        worksheet.addRow([]);
        worksheet.mergeCells('A3:D3');

        // Add Row and formatting
        const units = worksheet.addRow([`Unidades: ${model.units}`]);
        units.font = {
            name: 'Calibri',
            family: 4,
            size: 12
        };
        worksheet.addRow([]);
        worksheet.mergeCells('A4:D4');

        // Add Row and formatting
        const date = worksheet.addRow([
            `Fechas: ${model.date_init} - ${model.date_end}`
        ]);
        date.font = {
            name: 'Calibri',
            family: 4,
            size: 14
        };
        worksheet.addRow([]);
        worksheet.mergeCells('A5:D5');

        // Blank Row
        worksheet.addRow([]);

        // Add Header Row
        const headerRow = worksheet.addRow(header);

        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFFFFF00'},
                bgColor: {argb: 'FF0000FF'}
            };
            cell.border = {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'}
            };
        });

        // Add Data and Conditional Formatting.. comentarios?
        data.forEach((d) => {
            const row = worksheet.addRow(d);
            let noveltyColor = 'FF0000';
            const documentos_conductor = row.getCell(6);
            if (documentos_conductor.value) {
                documentos_conductor.value = 'si';
            } else {
                documentos_conductor.value = 'no';
                documentos_conductor.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const documentos_vehiculo = row.getCell(7);
            if (documentos_vehiculo.value) {
                documentos_vehiculo.value = 'si';
            } else {
                documentos_vehiculo.value = 'no';
                documentos_vehiculo.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const calcomania = row.getCell(8);
            if (calcomania.value) {
                calcomania.value = 'si';
            } else {
                calcomania.value = 'no';
                calcomania.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const pito = row.getCell(9);
            if (pito.value) {
                pito.value = 'si';
            } else {
                pito.value = 'no';
                pito.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const disp_velocidad = row.getCell(10);
            if (disp_velocidad.value) {
                disp_velocidad.value = 'si';
            } else {
                disp_velocidad.value = 'no';
                disp_velocidad.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const estado_esc_p_conductor = row.getCell(11);
            if (estado_esc_p_conductor.value) {
                estado_esc_p_conductor.value = 'si';
            } else {
                estado_esc_p_conductor.value = 'no';
                estado_esc_p_conductor.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const estado_esc_p_pasajero = row.getCell(12);
            if (estado_esc_p_pasajero.value) {
                estado_esc_p_pasajero.value = 'si';
            } else {
                estado_esc_p_pasajero.value = 'no';
                estado_esc_p_pasajero.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const equipo_carretera = row.getCell(13);
            if (equipo_carretera.value) {
                equipo_carretera.value = 'si';
            } else {
                equipo_carretera.value = 'no';
                equipo_carretera.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const herramientas = row.getCell(14);
            if (herramientas.value) {
                herramientas.value = 'si';
            } else {
                herramientas.value = 'no';
                herramientas.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const linterna = row.getCell(15);
            if (linterna.value) {
                linterna.value = 'si';
            } else {
                linterna.value = 'no';
                linterna.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const extintor = row.getCell(16);
            if (extintor.value) {
                extintor.value = 'si';
            } else {
                extintor.value = 'no';
                extintor.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const botiquin = row.getCell(17);
            if (botiquin.value) {
                botiquin.value = 'si';
            } else {
                botiquin.value = 'no';
                botiquin.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const repuesto = row.getCell(18);
            if (repuesto.value) {
                repuesto.value = 'si';
            } else {
                repuesto.value = 'no';
                repuesto.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const retrovisores = row.getCell(19);
            if (retrovisores.value) {
                retrovisores.value = 'si';
            } else {
                retrovisores.value = 'no';
                retrovisores.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const cinturones = row.getCell(20);
            if (cinturones.value) {
                cinturones.value = 'si';
            } else {
                cinturones.value = 'no';
                cinturones.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const motor = row.getCell(21);
            if (motor.value) {
                motor.value = 'si';
            } else {
                motor.value = 'no';
                motor.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const llantas = row.getCell(22);
            if (llantas.value) {
                llantas.value = 'si';
            } else {
                llantas.value = 'no';
                llantas.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const baterias = row.getCell(23);
            if (baterias.value) {
                baterias.value = 'si';
            } else {
                baterias.value = 'no';
                baterias.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const transmision = row.getCell(24);
            if (transmision.value) {
                transmision.value = 'si';
            } else {
                transmision.value = 'no';
                transmision.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const tension = row.getCell(25);
            if (tension.value) {
                tension.value = 'si';
            } else {
                tension.value = 'no';
                tension.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const tapas = row.getCell(26);
            if (tapas.value) {
                tapas.value = 'si';
            } else {
                tapas.value = 'no';
                tapas.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const niveles = row.getCell(27);
            if (niveles.value) {
                niveles.value = 'si';
            } else {
                niveles.value = 'no';
                niveles.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const filtros = row.getCell(28);
            if (filtros.value) {
                filtros.value = 'si';
            } else {
                filtros.value = 'no';
                filtros.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const parabrisas = row.getCell(29);
            if (parabrisas.value) {
                parabrisas.value = 'si';
            } else {
                parabrisas.value = 'no';
                parabrisas.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const frenos = row.getCell(30);
            if (frenos.value) {
                frenos.value = 'si';
            } else {
                frenos.value = 'no';
                frenos.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const frenos_emergencia = row.getCell(31);
            if (frenos_emergencia.value) {
                frenos_emergencia.value = 'si';
            } else {
                frenos_emergencia.value = 'no';
                frenos_emergencia.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const aire = row.getCell(32);
            if (aire.value) {
                aire.value = 'si';
            } else {
                aire.value = 'no';
                aire.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const luces = row.getCell(33);
            if (luces.value) {
                luces.value = 'si';
            } else {
                luces.value = 'no';
                luces.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const silleteria = row.getCell(34);
            if (silleteria.value) {
                silleteria.value = 'si';
            } else {
                silleteria.value = 'no';
                silleteria.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const silla_conductor = row.getCell(35);
            if (silla_conductor.value) {
                silla_conductor.value = 'si';
            } else {
                silla_conductor.value = 'no';
                silla_conductor.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const aseo = row.getCell(36);
            if (aseo.value) {
                aseo.value = 'si';
            } else {
                aseo.value = 'no';
                aseo.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const celular = row.getCell(37);
            if (celular.value) {
                celular.value = 'si';
            } else {
                celular.value = 'no';
                celular.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            const ruteros = row.getCell(38);
            if (ruteros.value) {
                ruteros.value = 'si';
            } else {
                ruteros.value = 'no';
                ruteros.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: noveltyColor}
                };
            }
            // aqui pintamos manualmente la celda obteniendo sumando dinamicamente el index
        });
        worksheet.getColumn(1).width = 20;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 30;
        worksheet.getColumn(4).width = 20;
        worksheet.getColumn(5).width = 30;
        worksheet.addRow([]);

        // Footer Row
        const footerRow = worksheet.addRow([
            'This is system generated excel sheet.'
        ]);
        footerRow.getCell(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'FFCCFFE5'}
        };
        footerRow.getCell(1).border = {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'}
        };

        // Merge Cells
        worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            fs.saveAs(blob, `${model.title}.xlsx`);
        });
    }

    async printExcel(model: any) {
        // Excel Title, Header, Data
        const title = model.title;
        const header = model.header;
        const data = model.data;

        // Create workbook and worksheet
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(model.title);

        // Add Row and formatting
        const titleRow = worksheet.addRow([title]);
        titleRow.font = {
            name: 'Montserrat',
            family: 4,
            size: 16,
            bold: true
        };
        worksheet.addRow([]);
        worksheet.mergeCells('A1:D2');

        // Add Row and formatting
        const drivers = worksheet.addRow([`Conductores: ${model.drivers}`]);
        drivers.font = {
            name: 'Calibri',
            family: 4,
            size: 12
        };
        worksheet.addRow([]);
        worksheet.mergeCells('A3:D3');

        // Add Row and formatting
        const units = worksheet.addRow([`Unidades: ${model.units}`]);
        units.font = {
            name: 'Calibri',
            family: 4,
            size: 12
        };
        worksheet.addRow([]);
        worksheet.mergeCells('A4:D4');

        // Add Row and formatting
        const date = worksheet.addRow([
            `Fechas: ${model.date_init} - ${model.date_end}`
        ]);
        date.font = {
            name: 'Calibri',
            family: 4,
            size: 14
        };
        worksheet.addRow([]);
        worksheet.mergeCells('A5:D5');

        // Blank Row
        worksheet.addRow([]);

        // Add Header Row
        const headerRow = worksheet.addRow(header);

        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'FFFFFF00'},
                bgColor: {argb: 'FF0000FF'}
            };
            cell.border = {
                top: {style: 'thin'},
                left: {style: 'thin'},
                bottom: {style: 'thin'},
                right: {style: 'thin'}
            };
        });

        // Add Data and Conditional Formatting.. comentarios?
        data.forEach((d) => {
            const row = worksheet.addRow(d);
            let noveltyColor = 'FF0000';
            const row_folio = row.getCell(1);
            row_folio.value = d.folio;
            const row_date = row.getCell(2);
            row_date.value = d.date;
            const row_driver = row.getCell(3);
            row_driver.value = d.driver ? d.driver : '';
            const row_unit = row.getCell(4);
            row_unit.value = d.unit;
            const row_rc = row.getCell(5);
            row_rc.value = d.rc;
            const row_rg = row.getCell(6);
            row_rg.value = d.rg;
            const row_rm = row.getCell(7);
            row_rm.value = d.rm;
            const row_covid = row.getCell(8);
            row_covid.value = d.covid;
            for (var i = 0; i < d.novetlys.length; i++) {
                const row_novetly = row.getCell(9 + i);
                if (!d.novetlys[i].novetly) {
                    row_novetly.value = 'si';
                } else {
                    row_novetly.value = 'no';
                    row_novetly.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {argb: noveltyColor}
                    };
                }
            }

            // aqui pintamos manualmente la celda obteniendo sumando dinamicamente el index
        });
        worksheet.getColumn(1).width = 20;
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(3).width = 30;
        worksheet.getColumn(4).width = 20;
        worksheet.getColumn(5).width = 30;
        worksheet.addRow([]);

        // Footer Row
        const footerRow = worksheet.addRow([
            'This is system generated excel sheet.'
        ]);
        footerRow.getCell(1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'FFCCFFE5'}
        };
        footerRow.getCell(1).border = {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'}
        };

        // Merge Cells
        worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data: any) => {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            fs.saveAs(blob, `${model.title}.xlsx`);
        });
    }
}
