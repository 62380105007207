import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
    account: any;
    signatureFile: File;
    signatureName: string;
    logoUploading: boolean = false;
    signatureUploading: boolean = false;

    form_account = new FormGroup({
        name: new FormControl(null, Validators.required),
        email: new FormControl(null, Validators.required),
        phone: new FormControl(null, Validators.required),
        street: new FormControl(null, Validators.required)
    });

    constructor(
        private appService: AppService,
        private apiService: ApiService
    ) {}

    ngOnInit(): void {
        this.appService
            .getAccountInfo()
            .then((account) =>
                account
                    ? ((this.account = account),
                      this.form_account.controls['name'].setValue(account.name),
                      this.form_account.controls['email'].setValue(
                          account.email
                      ),
                      this.form_account.controls['phone'].setValue(
                          account.telefono
                      ),
                      this.form_account.controls['street'].setValue(
                          account.direccion
                      ))
                    : this.logout()
            );
    }

    logout() {
        this.appService.logout();
    }

    updateAccount() {
        var data = {
            name: this.form_account.controls['name'].value,
            email: this.form_account.controls['email'].value,
            phone: this.form_account.controls['phone'].value,
            street: this.form_account.controls['street'].value
        };
        this.appService.putAccountInfo(data).then(() => {});
    }

    handleUploadLogo(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.appService.uploadLogo(reader.result.toString()).then((res) => {
                this.appService
                    .getAccountInfo()
                    .then((account) =>
                        account ? (this.account = account) : this.logout()
                    );
            });
        };
    }

    handleUploadSignature(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.appService.uploadSignature(reader.result.toString()).then((res) => {
                this.appService
                    .getAccountInfo()
                    .then((account) =>
                        account ? (this.account = account) : this.logout()
                    );
            });
        };
    }

    handleUploadIso(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.appService.uploadIso(reader.result.toString()).then((res) => {
                this.appService
                    .getAccountInfo()
                    .then((account) =>
                        account ? (this.account = account) : this.logout()
                    );
            });
        };
    }

    ab2str(buf) {
        return String.fromCharCode.apply(null, new Uint16Array(buf));
    }
}
