<div class="enlist-container">
    <ng-container *ngIf="loading">
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden"></span>
            </div>
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden"></span>
            </div>
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden"></span>
            </div>

    </ng-container>
    <ng-container *ngIf="!loading">
        <div
            class="card"
            [ngClass]="{
                'old-enlistment-card': data.state === 'creado',
                'old-enlistment-card-incomplete': data.state === 'incompleto'
            }"
        >
            <div
                class="card-header"
                [ngClass]="{
                    'old-enlistment-card-header-complete':
                        data.state === 'creado',
                    'old-enlistment-card-header-incomplete':
                        data.state === 'incompleto'
                }"
                *ngIf="data"
            >
                Alistamiento {{ data.folio }}
            </div>
            <div class="card-body old-enlistment-card-body">
                <div class="row">
                    <div
                        [ngClass]="{
                            'col-12': data.state === 'creado',
                            'col-6': data.state === 'incompleto'
                        }"
                    >
                        <div class="row pb-4">
                            <div class="header-icon">
                                <img
                                    src="./assets/icon/ec-icon.svg"
                                    width="80"
                                />
                            </div>
                            <div class="page-subheading ml-4">
                                <div class="page-subheading-subtitle">
                                    En el Código Nacional de Tránsito Terrestre
                                    o Ley 769 del 6 de agosto de 2002 se
                                    encuentran las disposiciones legales que
                                    rigen la actividad. Es su deber conocer,
                                    aplicar y tener siempre presente este
                                    documento.
                                </div>
                            </div>
                        </div>
                        <div class="row-cols-1 pb-4">
                            <span class="data-label"
                                ><strong>Fecha y Hora </strong></span
                            ><br />
                            <span class="data-text">{{ data.fecha }}</span>
                        </div>
                        <div class="row-cols-1 pb-4">
                            <span class="data-label"
                                ><strong>Conductor </strong></span
                            ><br />
                            <span class="data-text">{{ data.conductor }}</span>
                        </div>
                        <div class="row-cols-1 pb-4">
                            <span class="data-label"
                                ><strong>Vehiculo </strong></span
                            ><br />
                            <span class="data-text">{{ data.fecha }}</span>
                        </div>
                        <div class="row-cols-1 pb-4">
                            <span class="data-label"
                                ><strong>Estado </strong></span
                            ><br />
                            <span
                                class="creado"
                                *ngIf="data.state === 'creado'"
                            >
                                Sin Novedades
                            </span>
                            <span
                                class="incompleto"
                                *ngIf="data.state === 'incompleto'"
                            >
                                Con Novedades
                            </span>
                        </div>
                    </div>
                    <ng-container *ngIf="data.state === 'incompleto'">
                        <div class="col-6">
                            <ng-container *ngIf="data.state === 'incompleto'">
                                <div class="card enlist-card mb-4">
                                    <mdb-carousel
                                        [isControls]="true"
                                        class="
                                            carousel-multi-item
                                            multi-animation
                                            novelty-carrousel
                                        "
                                        [animation]="'fade'"
                                    >
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.documentos_vehiculo"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_documentos_vehiculo
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                        >
                                                            Documentos del
                                                            vehiculo </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_documentos_vehiculo
                                                            }}.</span
                                                        >>
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.documentos_conductor"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_documentos_conductor
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                        >
                                                            Documentos del
                                                            conductor </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_documentos_conductor
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body
                                                        class="pt-4 pl-4"
                                                    >
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.calcomania"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_calcomania
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Calcomania como
                                                            conduzco </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_calcomania
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.aire"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_aire
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Aire </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_aire
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.aseo"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_aseo
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Aseo </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_aseo
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.baterias"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_baterias
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Bateria </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_baterias
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.botiquin"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_botiquin
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Botiquin </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_botiquin
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.celular"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_celular
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Celular con minutos </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_celular
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body
                                                        class="novelty-body"
                                                    >
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.cinturones"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_cinturones
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Cinturones </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_cinturones
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.disp_velocidad"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_disp_velocidad
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Dispositivo de
                                                            velocidad </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_disp_velocidad
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.equipo_carretera"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_equipo_carretera
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Equipo de carretera </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_equipo_carretera
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.estado_esc_p_conductor"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_estado_esc_p_conductor
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Estado escalera
                                                            puerta conductor </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_estado_esc_p_conductor
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.estado_esc_p_pasajero"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_estado_esc_p_pasajero
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Estado escalera
                                                            puerta pasajero </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_estado_esc_p_pasajero
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.extintor"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_extintor
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Extintor </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_extintor
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.filtros"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_filtros
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Filtros </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_filtros
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.frenos"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_frenos
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Frenos </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_frenos
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.frenos_emergencia"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_frenos_emergencia
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Frenos de
                                                            emergencia </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_frenos_emergencia
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.herramientas"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_herramientas
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Herramientas </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_herramientas
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.linterna"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_linterna
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Linterna </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_linterna
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.llantas"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_llantas
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Llantas </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_llantas
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.luces"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_luces
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Luces </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_luces
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.motor"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_motor
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Motor </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_motor
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.niveles"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_niveles
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Niveles </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_niveles
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.parabrisas"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_parabrisas
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Parabrisas </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_parabrisas
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.pito"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_pito
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Pito </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_pito
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.repuesto"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_repuesto
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Repuesto </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_repuesto
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.retrovisores"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_retrovisores
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Espejos
                                                            retrovisores </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_retrovisores
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.ruteros"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_ruteros
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Ruteros </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_ruteros
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.silla_conductor"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_silla_conductor
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Silla conductor </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_silla_conductor
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.silleteria"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_silleteria
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Silleteria general </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_silleteria
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.tapas"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_tapas
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Tapas </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_tapas
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.tension"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_tension
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Tension de correas </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_tension
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="!data.transmision"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <img
                                                        src="data:image/png;base64,{{
                                                            data.img_tension
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                    />
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                            >Transmision </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                data.desc_transmision
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                    </mdb-carousel>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <div class="row">
                    <button
                        [ngClass]="{
                            'ec-btn8': data.state === 'incompleto',
                            'ec-btn7': data.state === 'creado'
                        }"
                        (click)="openPDF()"
                    >
                        Imprimir
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</div>

