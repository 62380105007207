<div
    class="Notification column"
    [ngClass]="active ? 'active' : ''"
    [ngClass]="desactivate ? 'desactivate' : ''"
>
    <div *ngFor="let notify of notifications; index as i; first as isFirst">
        <div class="row notification-item">
            <div class="column notification-item-description">
                <div class="Notification-title">
                    {{ notify.title }}
                </div>
                <div class="Notification-text date">
                    {{ notify.create_date }}
                </div>
                <div class="Notification-text desc">
                    {{ notify.description }}
                </div>
            </div>
        </div>
    </div>
</div>
