import {
    Component,
    OnInit,
    ViewChild,
    HostListener,
    ElementRef,
    Renderer2
} from '@angular/core';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {DateTime} from 'luxon';

@Component({
    selector: 'app-user-dropdown-menu',
    templateUrl: './user-dropdown-menu.component.html',
    styleUrls: ['./user-dropdown-menu.component.scss']
})
export class UserDropdownMenuComponent implements OnInit {
    public user;

    @ViewChild('dropdownMenu', {static: false}) dropdownMenu;
    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.hideDropdownMenu();
        }
    }
    myAccount: any;
    fileToUpload: string;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private appService: AppService,
        private apiService: ApiService
    ) {}

    ngOnInit(): void {
        this.appService
            .getSessionInfo()
            .then((user) => (user ? (this.user = user) : this.logout()));
        this.apiService.getMyAccountInfo().then((account) => {
            this.fileToUpload = account.image;
            account ? (this.myAccount = account) : this.logout();
        });
    }

    toggleDropdownMenu() {
        if (this.dropdownMenu.nativeElement.classList.contains('show')) {
            this.hideDropdownMenu();
        } else {
            this.showDropdownMenu();
        }
    }

    pictNotLoading(event) {
        event.target.src = "./assets/images/avatars/0.png";
    }

    showDropdownMenu() {
        this.renderer.addClass(this.dropdownMenu.nativeElement, 'show');
    }

    hideDropdownMenu() {
        this.renderer.removeClass(this.dropdownMenu.nativeElement, 'show');
    }

    logout() {
      this.appService.logout().then((res)=>{
        console.log("bye");
      });
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
}
