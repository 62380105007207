import {Component, Input, OnInit} from '@angular/core';
import {SwitchService} from '@services/switch.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

    @Input()
    type: string = 'white';

    constructor(private modalServicer: SwitchService) {}

    ngOnInit(): void {}

    close() {
        // this.callbackFunction();
        this.modalServicer.$modal.emit(false);
        this.modalServicer.$card_selected.emit(false);
    }

    onDestroy() {}
}
