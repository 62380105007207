<div class="all_questions_container">
  <div class="quesitons_container" *ngFor="let question of questions">
    <div class="questions_text">
      <p class="questions_name"> {{question.name}} </p>
      <p class="questions_description">{{question.description}}</p>
    </div>
    <div class="questions_images">
      <img (click)="openEditModal(question)" class="questions_edit" src="../../../assets/icon/edit_icon.png" alt="edit question">
      <div (click)="openDeleteModal(question)" class="questions_delete"></div>
    </div>
  </div>
  <button
      type="button"
      class="ec-btn7 create_question"
      href="#"
      (click)="openCreateModal()">
      Nueva pregunta
  </button>
</div>
<!-- This modal is showed when select edit question -->
<app-modal *ngIf="showEditModal" type="black">
  <div class="questions_modal_container">
    <div class="questions_modal_close_container">
      <div (click)="closesEditModal()" class="questions_modal_close"></div>
    </div>
    <h2>Editor de preguntas</h2>
    <form action="">
      <label for="questionName">Nombre: </label>
      <input id="questionName" type="text" [(ngModel)]="questionToUpdate.name" name="name">
      <label for="questionName" >Descripción: </label>
      <input id="questionName" type="text" [(ngModel)]="questionToUpdate.description" name="description">
      <button
          type="button"
          class="ec-btn7"
          href="#"
          (click)="updateQuestion()">
          Actualizar
      </button>
    </form>
  </div>
</app-modal>
<!-- This modal is showed when select delete question -->
<app-modal *ngIf="showDeleteModal" type="black">
  <div class="questions_modal_container delete_question_container">
    <div class="questions_modal_close_container">
      <div (click)="closesDeleteModal()" class="questions_modal_close"></div>
    </div>
    <h2>Precaución!</h2>
    <p>Al confirmar se eliminará la pregunta:</p>
    <p class="red_text">{{questionToDelete.name}}</p>
    <button
          type="button"
          class="ec-btn8"
          href="#"
          (click)="deleteQuestion()">
          Eliminar
      </button>
  </div>
</app-modal>
<!-- This modal is showed when select create a question -->
<app-modal *ngIf="showCreateModal" type="black">
  <div class="questions_modal_container">
    <div class="questions_modal_close_container">
      <div (click)="closesCreateModal()" class="questions_modal_close"></div>
    </div>
    <h2>Nueva pregunta:</h2>
    <form action="">
      <label for="questionName">Nombre: </label>
      <input id="questionName" type="text" [(ngModel)]="questionToCreate.name" name="name">
      <label for="questionDescription" >Descripción: </label>
      <input id="questionDescription" type="text" [(ngModel)]="questionToCreate.description" name="description">
      <button
          type="button"
          class="ec-btn7"
          href="#"
          (click)="createQuestion()">
          Crear
      </button>
    </form>
  </div>
</app-modal>

<!-- This modal is showed when select delete question -->
<app-modal *ngIf="showErrorModal" type="black">
  <div class="questions_modal_container delete_question_container">
    <div class="questions_modal_close_container">
      <div (click)="closesErrorModal()" class="questions_modal_close"></div>
    </div>
    <h2>Error!</h2>
    <div class="img_container">
      <img src="../../../assets/icon/ec-alerta.svg" alt="Error alert">
    </div>
    <p class="red_text">{{errorToShow}}</p>
  </div>
</app-modal>

