import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-action-confirm',
  templateUrl: './action-confirm.component.html',
  styleUrls: ['./action-confirm.component.scss']
})
export class ActionConfirmComponent implements OnInit {

  @Input()
  iconPath: string = "";

  @Input()
  title: string = "";

  @Input()
  description: string = "";

  @Input()
  confirmText: string = "";

  @Input()
  cancelText: string = "";

  @Input()
  confirmClass: string = "ec-btn5";

  @Input()
  cancelClass: string = "ec-btn1";

  @Output()
  confirmEvent = new EventEmitter();

  @Output()
  cancelEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  confirm(){
    this.confirmEvent.emit();
  }
  cancel(){
    this.cancelEvent.emit();
  }
}
