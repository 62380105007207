<div class="card-action-confirm">
  <div class="row justify-content-center p-4">
    <img class="icon-confirm" src="{{iconPath}}" width="80">
  </div>
  <div class="row justify-content-center">
    <span class="action-confirm-title pb-4">{{title}}</span>
  </div>
  <ng-container *ngIf="description !== ''">
    <div class="row justify-content-center pb-4 description">
      {{description}}
    </div>
  </ng-container>
  <div class="row justify-content-center">
    <button class="confirm-btn {{confirmClass}}" (click)="confirm()">
      {{confirmText}}
    </button>
    <button class="ml-2 cancel-btn {{cancelClass}}" (click)="cancel()">
      {{cancelText}}
    </button>
  </div>
</div>
