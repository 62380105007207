import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {PaginationService} from '@components/pagination/pagination.service';
import {ExcelService} from '@services/excel.service';
import {TableViewModel, Value} from '@components/models/table';
import {ActivatedRoute, Router} from '@angular/router';
import {SwitchService} from '@services/switch.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {Question} from '@/models/questions';
import {OldEnlistment} from '@/models/OldEnlistment';
import {motion_logo} from 'assets/images/motion_logo';
import {Enlistment} from '@/models/Enlistment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-manage-enlistments',
    templateUrl: './manage-enlistments.component.html',
    styleUrls: ['./manage-enlistments.component.scss']
})
export class ManageEnlistmentsComponent implements OnInit {
    currentPage: number = 1;
    pageSize: number = 5;
    enlistments: any[] = null;
    enlistments_count: number = null;
    vehicles_count: number = null;
    ok_enlistments_count: number = null;
    enlistmentModel: TableViewModel = {
        title: 'Alistamientos',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {title: 'Folio', key: 'folio', filter: true, data: []},
            {title: 'Fecha', key: 'date', filter: true, data: []},
            {
                title: 'Conductor',
                key: 'driver',
                filter: true,
                filterDriver: true,
                data: this.getDriverValues()
            },
            {
                title: 'Vehiculo',
                key: 'unit',
                filter: true,
                filterUnit: true,
                data: this.getUnitValues()
            },
            {
                title: 'Formulario Covid',
                key: 'covid',
                filter: false,
                filterCovid: true,
                data: []
            },
            {
                title: 'Estado',
                key: 'state',
                filter: true,
                data: [],
                type: 'ec-status'
            },
            {title: 'RC', key: 'rc', filter: false, data: [], type: 'ec-rc'},
            {title: 'RG', key: 'rg', filter: false, data: [], type: 'ec-rg'},
            {title: 'RM', key: 'rm', filter: false, data: [], type: 'ec-rm'}
        ],
        records: [],
        showFilters: true,
        enableActions: true,
        isModal: false
    };
    searchby: string = '';
    selected_enlistment: any;
    selected_form: any;
    show_single_enlistment: boolean = false;
    show_single_covid_form: boolean = false;
    account: any;
    loading: boolean = false;
    questions: Question[] = [];
    data: Enlistment = null;
    filter = {
        driver: null,
        unit: null
    }

    constructor(
        private apiService: ApiService,
        private appService: AppService,
        private cd: ChangeDetectorRef,
        private pS: PaginationService,
        private excelService: ExcelService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: SwitchService
    ) {}

    ngOnInit(): void {
        this.getEnlistments(this.currentPage, this.searchby);
        this.modalService.$modal.subscribe((value) => {
          if (!value)
            this.show_single_enlistment = false;
        });
        this.modalService.$card_selected.subscribe((value) => {
            this.show_single_covid_form = value;
        });
        this.appService.getAccountInfo().then((account) => {
            this.account = account;
        });
    }

    getQuestions(folio: string) {
        return this.apiService.getQuestionsTemplate(folio).then((response) => {
            this.questions = response.result;
            this.loading = false;
        });
    }

    openPDF(data: any) {
        this.loading = true;
        this.apiService
            .getSingleEnlistmentInfo(data.folio)
            .then((enlistment) => {
                this.data = enlistment.result[0];
                let string_rc, string_rg = null;
                if(data.rc == 'checked'){
                  string_rc = `${this.data.rc_name} \n ${this.data.rc_date}`;
                }
                if(data.rg == 'checked'){
                  string_rg = `${this.data.rg_name} \n ${this.data.rg_date}`;
                }
                this.getQuestions(data.folio).then(async () => {
                    var contentTable = [];
                    var contentNovetlys = [];

                    for (let quest of this.questions) {
                        contentTable.push([
                            quest.name,
                            quest.novetly ? 'X' : '-',
                            !quest.novetly ? 'X' : '-'
                        ]);
                    }

                    for (let quest of this.questions) {
                        if (quest.novetly) {
                            contentNovetlys.push([
                                {
                                    text: quest.name,
                                    fontSize: 15,
                                    alignment: 'center',
                                    margin: [0, 5],
                                    bold: true
                                },
                                {
                                  image: quest.image != "" ? `data:image/jpeg;base64,${quest.image}`: await this.getBase64ImageFromURL('/assets/images/image-not-found-scaled-1150x647.png'),
                                  width: 250,
                                    margin: [0, 10],
                                    alignment: 'center'
                                },
                                {
                                    text: ` Descripcion: ${quest.description}`,
                                    fontSize: 12,
                                    alignment: 'center'
                                }
                            ]);
                        }
                    }

                    var docDefinition = {
                        pageMargins: [40, 90, 40, 60],
                        header: {
                            columns: [
                                this.account && this.account.iso_img
                                    ? {
                                          image: `data:image/jpeg;base64,${this.account.logo}`,
                                          width: 120,
                                          height: 60,
                                          alignment: 'left',
                                          opacity: 0.9,
                                          margin: [50, 10]
                                      }
                                    : {},
                                this.account && this.account.iso_img
                                    ? {
                                          image: `data:image/jpeg;base64,${this.account.iso_img}`,
                                          width: 200,
                                          height: 60,
                                          alignment: 'right',
                                          opacity: 0.9,
                                          margin: [0, 10]
                                      }
                                    : {
                                          image: motion_logo,
                                          width: 200,
                                          height: 60,
                                          alignment: 'right',
                                          opacity: 0.9,
                                          margin: [0, 10]
                                      }
                            ],
                            columnGap: 200
                        },
                        footer: {
                            columns: [
                                {
                                    text: 'monitoringinnovation.com',
                                    link: 'http://monitoringinnovation.com',
                                    alignment: 'right',
                                    margin: [0, 10, 10, 0]
                                }
                            ]
                        },
                        content: [
                            {
                                text: `Alistamiento: ${this.data.folio}`,
                                fontSize: 16,
                                margin: [0, 5]
                            },
                            {
                                text: `Fecha: ${this.data.date}`,
                                fontSize: 16,
                                margin: [0, 5]
                            },
                            {
                                columns: [
                                    [
                                        {
                                            text: `Vehiculo:`,
                                            fontSize: 12,
                                            alignment: 'center'
                                        },
                                        {
                                            text: `${this.data.unit}`,
                                            fontSize: 14,
                                            alignment: 'center',
                                            bold: true
                                        }
                                    ],
                                    [
                                        {
                                            text: `Conductor:`,
                                            fontSize: 12,
                                            alignment: 'center'
                                        },
                                        {
                                            text: `${this.data.driver}`,
                                            fontSize: 14,
                                            alignment: 'center',
                                            bold: true
                                        }
                                    ],
                                    [
                                        {
                                            text: `Revision Gerencia:`,
                                            fontSize: 12,
                                            alignment: 'center'
                                        },
                                        {
                                            text: string_rg ? string_rg : 'Sí',
                                            fontSize: 14,
                                            alignment: 'center',
                                            bold: true
                                        }
                                    ],
                                    [
                                        {
                                            text: `Revision jefe de mantenimiento:`,
                                            fontSize: 12,
                                            alignment: 'center'
                                        },
                                        {
                                            text: string_rc ? string_rc : 'Sí',
                                            fontSize: 14,
                                            alignment: 'center',
                                            bold: true
                                        }
                                    ]
                                ],
                                margin: [0, 10]
                            },
                            {
                                layout: 'lightHorizontalLines',
                                table: {
                                    // headers are automatically repeated if the table spans over multiple pages
                                    // you can declare how many rows should be treated as headers
                                    headerRows: 1,
                                    widths: ['*', 100, 100],

                                    body: [
                                        [
                                            {text: 'ITEM', bold: true},
                                            {text: 'CON NOVEDAD', bold: true},
                                            {text: 'SIN NOVEDAD', bold: true}
                                        ],
                                        ...contentTable
                                    ]
                                }
                            },
                            {
                                text: `Novedades`,
                                fontSize: 16,
                                bold: true,
                                margin: [0, 5]
                            },
                            ...contentNovetlys
                        ]
                    };

                    pdfMake.tableLayouts = {
                        exampleLayout: {
                            hLineWidth: function (i, node) {
                                if (i === 0 || i === node.table.body.length) {
                                    return 0;
                                }
                                return i === node.table.headerRows ? 2 : 1;
                            },
                            vLineWidth: function (i) {
                                return 0;
                            },
                            hLineColor: function (i) {
                                return i === 1 ? 'black' : '#8a8a8a';
                            },
                            paddingLeft: function (i) {
                                return i === 0 ? 0 : 8;
                            },
                            paddingRight: function (i, node) {
                                return i === node.table.widths.length - 1
                                    ? 0
                                    : 8;
                            }
                        }
                    };
                    pdfMake.createPdf(docDefinition).open();
                    this.loading = false;
                });
            });
    }

    getEnlistments(currentPage, searchby, update=true) {
        let id = this.route.snapshot.paramMap.get('id');
        let type = this.route.snapshot.paramMap.get('type');
        return this.apiService
            .getEnlistmentsInfo(
                update ? currentPage : 0,
                update ? this.pageSize : 0,
                searchby,
                null,
                null,
                type,
                id,
                null,
                null,
                null,
                null,
                null,
                this.filter.driver,
                this.filter.unit
            )
            .then((enlistments) => {
                if (update) {
                    this.enlistments = typeof enlistments.result !== 'string' ? enlistments.result : [];
                    this.enlistments_count = enlistments.total_messages_count;
                    this.enlistmentModel.records = this.enlistments;
                    this.ok_enlistments_count =
                        enlistments.count_satisfactorios;
                    this.enlistmentModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: enlistments.count,
                        rowCount: this.enlistments_count
                    };
                    this.cd.detectChanges();
                }
                return enlistments.result
            });
    }

    getEnlistmentSorted(sortby, order) {
        if (order === 'none') {
            this.getEnlistments(this.currentPage, this.searchby);
        } else {
            this.apiService
                .getEnlistmentsInfo(
                    this.currentPage,
                    this.pageSize,
                    null,
                    sortby,
                    order,
                    null,
                    null
                )
                .then((enlistments) => {
                    if (enlistments.count > 0) {
                        this.enlistments = enlistments.result;
                        this.enlistments_count =
                            enlistments.total_messages_count;
                        this.enlistmentModel.records = this.enlistments;
                        this.ok_enlistments_count =
                            enlistments.count_satisfactorios;
                        this.enlistmentModel.pagination = {
                            currentPage: this.currentPage,
                            pageSize: this.pageSize,
                            pageCount: enlistments.count,
                            rowCount: this.enlistments_count
                        };
                        this.cd.detectChanges();
                    }
                });
        }
    }

    onRemove(index: number) {
        console.log('remove event ! ');
    }

    paginatorEvent(currentPage: number) {
        this.currentPage = currentPage;
        this.getEnlistments(currentPage, this.searchby);
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchP(searchby: string) {
        this.getEnlistments(
            this.currentPage,
            searchby ? searchby : this.searchby
        );
    }

    updateFilter(event) {
        this.getEnlistmentSorted(event.key, event.order);
    }

    filterUnit(event) {
        this.getEnlistmentByItem(event.key, event.value);
    }

    filterDriver(event) {
        this.getEnlistmentByItem(event.key, event.value);
    }

    getEnlistmentByItem(key, value) {
        if(key === 'driver' && value){
          this.apiService
            .getEnlistmentsInfo(this.currentPage, this.pageSize, '', '', '', key, value)
            .then((enlistments) => {
              this.enlistments = enlistments.result;
              this.enlistments_count = enlistments.total_count;
              this.enlistmentModel.records = this.enlistments;
              this.enlistmentModel.pagination = {
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                pageCount: enlistments.count,
                rowCount: this.enlistments_count
              };
            });
        }else if (key === 'unit' && value){
          this.apiService
            .getEnlistmentsInfo(this.currentPage, this.pageSize, '', '', '', key, value)
            .then((enlistments) => {
              this.enlistments = enlistments.result;
              this.enlistments_count = enlistments.total_count;
              this.enlistmentModel.records = this.enlistments;
              this.enlistmentModel.pagination = {
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                pageCount: enlistments.count,
                rowCount: this.enlistments_count
              };
              this.cd.detectChanges();
            });
        }else{
          this.getEnlistments(this.currentPage, this.searchby);
        }
    }

    getUnitValues(): Value[] {
        let unitValues: Value[] = [];
        this.apiService.getAllUnits().then((units) => {
            this.vehicles_count = units.length;
            for (let unit of units) {
                let unitValue = new Value(unit.name, unit.name);
                unitValues.push(unitValue);
            }
        });
        return unitValues;
    }

    getDriverValues(): Value[] {
        let driverValues: Value[] = [];
        this.apiService.getAllDrivers().then((drivers: any) => {
            for (let driver of drivers) {
                let driverValue = new Value(driver.name, driver.name);
                driverValues.push(driverValue);
            }
        });
        return driverValues;
    }

    showSingleCovidForm(folio) {
        console.log(folio);
    }
    validateRevision(event) {
        console.log(event);
    }

    filterDriverByName(event: string) {
        this.filter.driver = event
        this.getEnlistments(this.currentPage, this.searchby)
    }
    filterUnitByName(event: string) {
        this.filter.unit = event
        this.getEnlistments(this.currentPage, this.searchby)
    }

    async generateGeneralExcel(template: any) {
        const enlistments = await this.getEnlistments(false, false, false)
        this.excelService.constructModel(template, enlistments)
        await this.excelService.generateExcel(template);
    }

    showSingleEnlistment(data) {
        this.show_single_enlistment = !this.show_single_enlistment;
        this.selected_enlistment = data;
    }
    getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;

          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          var dataURL = canvas.toDataURL("image/png");

          resolve(dataURL);
        };

        img.onerror = error => {
          reject(error);
        };

        img.src = url;
      });
    }
}
