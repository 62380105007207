<div class="question-maker" *ngIf="questions && questions.length > 0">
    <div
        class="card-questions"
        *ngFor="let question of questions; let i = index"
    >
        <app-question
            [_question]="question"
            [_selected_question]="selected_question"
            (createQuestion)="postQuestion($event)"
            (deleteQuestionApi)="deleteQuest($event)"
            (deleteQuestionIndex)="onDeleteQuestion($event)"
            (selectQuestion)="onSelectQuestion($event)"
            [_index]="i"
        ></app-question>
    </div>
</div>
