<div class="enlist-container">
    <ng-container *ngIf="loading">
        <div class="loader-container">
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden"></span>
            </div>
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden"></span>
            </div>
            <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden"></span>
            </div>
        </div>
    </ng-container>
    <div class="row">
        <div class="col-12 page-title-heading pl-2">
            <div class="header-icon">
                <img src="/assets/icon/notificaciones.svg" width="30" />
            </div>
            <div class="page-subheading">
                <div class="page-subheading-title">
                    Configuracion de Notificaciones
                </div>
                <div class="page-subheading-subtitle">
                    Siempre antes de salir, realice una inspección
                    pre-operacional a su vehículo. Es por su propio bienestar y
                    por el de sus pasajeros. Recuerde: Ellos confían ciegamente
                    en usted..
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="enlist-card mb-3 card">
                <div class="card-header">
                    <div class="row toggle-title">
                        <label
                            *ngIf="!notifications_enabled"
                            class="e-control-label"
                            >Habilitar configuracion personalizada</label
                        >
                        <label
                            *ngIf="notifications_enabled"
                            class="e-control-label"
                            >Deshabilitar configuracion</label
                        >
                        <app-motion-checkbox
                            [value]="notifications_enabled"
                            (checkout)="toggleCustomQuestion()"
                        ></app-motion-checkbox>
                    </div>
                </div>
                <div class="card-body">
                    <ng-container *ngIf="notifications_enabled">
                        <form id="configOptions" [formGroup]="form_notify">
                            <!-- token csrf -->
                            <div
                                id="div_estado"
                                class="column"
                                style="gap: 30px"
                            >
                                <div class="col-12 column" style="gap: 10px">
                                    <label for="estadoAlistamientos"
                                        >Estado</label
                                    >
                                    <select
                                        id="estadoAlistamientos"
                                        class="form-control"
                                        title="Estado de alistamiento..."
                                        data-live-search="true"
                                        required=""
                                        formControlName="type"
                                    >
                                        <option
                                            class="font12px fuec_patch"
                                            value="all"
                                        >
                                            Todos
                                        </option>
                                        <option
                                            class="font12px fuec_patch"
                                            value="nonovetly"
                                        >
                                            Sin novedad
                                        </option>
                                        <option
                                            class="font12px fuec_patch"
                                            value="novetly"
                                        >
                                            Con novedad
                                        </option>
                                    </select>
                                </div>
                                <!-- unidades -->
                                <ng-container *ngIf="loadingUnits">
                                    <div class="col-12">
                                        <div
                                            class="spinner-grow text-success"
                                            role="status"
                                        >
                                            <span
                                                class="visually-hidden"
                                            ></span>
                                        </div>
                                        <div
                                            class="spinner-grow text-success"
                                            role="status"
                                        >
                                            <span
                                                class="visually-hidden"
                                            ></span>
                                        </div>
                                        <div
                                            class="spinner-grow text-success"
                                            role="status"
                                        >
                                            <span
                                                class="visually-hidden"
                                            ></span>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!loadingUnits">
                                    <div
                                        id="div_unidades"
                                        class="col-12 ct-form column"
                                    >
                                        <div class="column" style="gap: 10px">
                                            <span>Unidades</span>
                                            <div class="row">
                                                <a
                                                    type=""
                                                    class="ec-link"
                                                    id="select_all_units"
                                                    name="select_all_units"
                                                    (click)="selectAllUnits()"
                                                    >Todas las unidades</a
                                                >
                                                -
                                                <a
                                                    type=""
                                                    class="ec-link"
                                                    id="select_all_units"
                                                    name="select_all_units"
                                                    (click)="deleteAllUnits()"
                                                    >Borrar todo</a
                                                >
                                            </div>
                                            <input
                                                type="hidden"
                                                class=""
                                                name="unidadesarray[]"
                                                id="unidadesinput"
                                                required=""
                                            />
                                            <select
                                                id="selectpickerUnidades"
                                                class="
                                                    js-example-basic-multiple
                                                    form-control
                                                "
                                                multiple="multiple"
                                                formControlName="wialon_units"
                                            >
                                                <option
                                                    *ngFor="let unit of units"
                                                    [ngValue]="unit.id"
                                                >
                                                    {{ unit.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div
                                            class="ct-emails column"
                                            style="gap: 10px"
                                        >
                                            <div class="content-email-actions">
                                                <input
                                                    class="
                                                        form-control
                                                        form-control-email
                                                    "
                                                    type="email"
                                                    placeholder="Email"
                                                    [formControl]="email"
                                                    [email]="true"
                                                />
                                                <div class="input-div button">
                                                    <button
                                                        type="button"
                                                        class="ec-btn7"
                                                        href="#"
                                                        (click)="createEmail()"
                                                        *ngIf="!email_selected"
                                                    >
                                                        Agregar email
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="ec-btn2"
                                                        href="#"
                                                        (click)="updateEmail()"
                                                        *ngIf="email_selected"
                                                    >
                                                        Editar email
                                                    </button>
                                                </div>
                                            </div>
                                            <app-emails-component
                                                [_emails]="emails"
                                                (selectEmail)="
                                                    onSelectEmail($event)
                                                "
                                                (deleteEmailIndex)="
                                                    onDeleteEmail($event)
                                                "
                                            ></app-emails-component>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <!-- Estado -->
                        </form>
                        <div class="card-footer">
                            <button
                                type="submit"
                                id="save_config_btn"
                                class="ec-btn7 mt-4 gi_enable"
                                (click)="saveConfNotify()"
                            >
                                Guardar Cambios
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
