<ng-container *ngIf="!showPlain">

  <div >

    <a style="min-width: 100px; width: 100%;" *ngIf="value" (click)="openModal()"
      class="btn {{value.length > 0? 'btn-primary':'btn-light'}}  font-weight-bolder text-uppercase mr-2 filter-element">
      <i class="{{field.icon}} icon-nm"></i> {{field.title}}
      <span *ngIf="value.length > 0" class="label label-sm label-white ml-2">{{value.length}}</span>
    </a>

    <a *ngIf="!value" href="javascript:void(0)" (click)="openModal()"
      class="btn font-weight-bolder text-uppercase mr-2 filter-element">
      <i class="{{field.icon}}"></i> Cargando...
    </a>

  </div>
</ng-container>
