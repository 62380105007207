import {OldEnlistment} from '@/models/OldEnlistment';
import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-rpt-old-enlist',
    templateUrl: './rpt-old-enlist.component.html',
    styleUrls: ['./rpt-old-enlist.component.scss']
})
export class RptOldEnlistComponent implements OnInit {
    @Input()
    data: OldEnlistment = null;

    constructor() {}

    ngOnInit(): void {
        console.log(this.data)
    }
}
