<ng-template #actionsTemplate let-index="index" let-$implicit="$implicit">
    <div class="row m-0 p-0 w-100">
        <div class="acionts-content">
            <div
                class="btn btn-icon"
                (click)="viewEnlistments($implicit)"
                matTooltip="Ver alistamientos"
            >
                <img
                    src="./assets/icon/alistamientos.svg"
                    alt=""
                    class="icon-action"
                />
            </div>
            <div
                class="btn btn-icon"
                (click)="viewCovid($implicit)"
                matTooltip="Formulario covid"
            >
                <img
                    src="./assets/icon/covid19.svg"
                    alt=""
                    class="icon-action"
                />
            </div>
        </div>
    </div>
</ng-template>

<div class="enlist-container">
    <div class="row">
        <div class="col-12 page-title-heading pl-2">
            <div class="header-icon">
                <img src="/assets/icon/documentos-vehiculo.svg" width="30" />
            </div>
            <div class="page-subheading">
                <div class="page-subheading-title">Vehiculos</div>
                <div class="page-subheading-subtitle">
                    Siempre antes de salir, realice una inspección
                    pre-operacional a su vehículo. Es por su propio bienestar y
                    por el de sus pasajeros. Recuerde: Ellos confían ciegamente
                    en usted..
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-table
                [model]="unitsModel"
                [removeCard]="true"
                [actionsTemplate]="actionsTemplate"
                (paginatorEvent)="paginatorEvent($event)"
                (pageSizeEvent)="pageSizeEvent($event)"
                [hideIndex]="true"
                (searchEvent)="searchP($event)"
            ></app-table>
        </div>
    </div>
</div>
