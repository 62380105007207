import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';

import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import {ManageEnlistmentsComponent} from './pages/manage-enlistments/manage-enlistments.component';
import {ManageCovidFormsComponent} from './pages/manage-covid-forms/manage-covid-forms.component';
import {ManageReportComponent} from './pages/manage-report/manage-report.component';
import {ManageDriversComponent} from './pages/manage-drivers/manage-drivers.component';
import {ManageUnitsComponent} from './pages/manage-units/manage-units.component';
import {SingleEnlistmentComponent} from './pages/single-enlistment/single-enlistment.component';
import {SingleCovidFormComponent} from './pages/single-covid-form/single-covid-form.component';
import {ManageNotificationsComponent} from './pages/manage-notifications/manage-notifications.component';
import {ManageCustomFieldsComponent} from './pages/manage-custom-fields/manage-custom-fields.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ManageOldEnlistmentsComponent} from '@pages/manage-old-enlistments/manage-old-enlistments.component';
import {ManageOldCovidFormsComponent} from '@pages/manage-old-covid-forms/manage-old-covid-forms.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'enlistments',
                component: ManageEnlistmentsComponent
            },
            {
                path: 'enlistments/:type/:id',
                component: ManageEnlistmentsComponent
            },
            {
                path: 'covid-forms',
                component: ManageCovidFormsComponent
            },
            {
                path: 'covid-forms/:type/:id',
                component: ManageCovidFormsComponent
            },
            {
                path: 'report',
                component: ManageReportComponent
            },
            {
                path: 'drivers',
                component: ManageDriversComponent
            },
            {
                path: 'vehicles',
                component: ManageUnitsComponent
            },
            {
                path: 'enlistment',
                component: SingleEnlistmentComponent
            },
            {
                path: 'covid-form',
                component: SingleCovidFormComponent
            },
            {
                path: 'notification-config',
                component: ManageNotificationsComponent
            },
            {
                path: 'custom-fields',
                component: ManageCustomFieldsComponent
            },
            {
                path: '',
                component: ManageEnlistmentsComponent
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
