import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PaginationService {
    constructor() {}

    $searchby = new EventEmitter<string>();
    $exportModal = new EventEmitter<boolean>();
}
