<ng-template #actionsTemplate let-index="index" let-$implicit="$implicit">
    <div class="row m-0 p-0 w-100">
        <div
            class="d-flex align-items-center justify-content-center h-100 w-100"
        >
            <div
                matTooltip="Ver"
                (click)="showSingleCovidForm($implicit)"
                class="btn btn-icon"
            >
                <img
                    src="./assets/icon/enlist_icon-visible.svg"
                    alt=""
                    class="icon-action"
                />
            </div>
            <div
                matTooltip="Imprimir"
                class="btn btn-icon"
                (click)="openPDF($implicit)"
            >
                <img
                    src="./assets/icon/imprimir.svg"
                    alt=""
                    class="icon-action"
                />
            </div>
        </div>
    </div>
</ng-template>
<div class="enlist-container">
    <div class="row">
        <div class="col-12 page-title-heading pl-2">
            <div class="header-icon">
                <img src="/assets/icon/covid19.svg" width="30" />
            </div>
            <div class="page-subheading">
                <div class="page-subheading-title">
                    Fomularios Control Covid
                </div>
                <div class="page-subheading-subtitle">
                    En esta sección encontrarás una compilación de formularios
                    de prevención y protección contra el covid-19 para el
                    cuidado de la vida de las personas y la competitividad
                    empresarial.
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-xl-4">
            <app-card-counter
                [digit]="covid_forms_count"
                title="Formularios"
                subtitle="Total formularios control covid"
            ></app-card-counter>
        </div>
        <div class="col-md-6 col-xl-4">
            <app-card-counter
                [digit]="0"
                title="Conductores"
                subtitle="Total conductores disponibles"
            ></app-card-counter>
        </div>
        <div class="col-md-6 col-xl-4">
            <app-card-counter
                [digit]="0"
                title="Satisfactorios"
                subtitle="Formularios sin novedades"
            ></app-card-counter>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-table
                [model]="covidModel"
                [removeCard]="true"
                [actionsTemplate]="actionsTemplate"
                (paginatorEvent)="paginatorEvent($event)"
                (pageSizeEvent)="pageSizeEvent($event)"
                [hideIndex]="true"
                (searchEvent)="searchP($event)"
                (filterEvent)="updateFilter($event)"
                (driverEvent)="filterDriver($event)"
                (revisionEvent)="validateRevision($event)"
            ></app-table>
        </div>
    </div>
</div>

<ng-template [ngIf]="show_single_covid_form === true">
  <app-modal type="none">
    <div>
      <app-single-covid-form
        [form]="selected_form"
      ></app-single-covid-form>
    </div>
  </app-modal>
</ng-template>
