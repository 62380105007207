<ng-container *ngIf="loading">
    <div class="loader-container">
        <div class="spinner-grow text-success" role="status">
            <span class="visually-hidden"></span>
        </div>
        <div class="spinner-grow text-success" role="status">
            <span class="visually-hidden"></span>
        </div>
        <div class="spinner-grow text-success" role="status">
            <span class="visually-hidden"></span>
        </div>
    </div>
</ng-container>

<ng-template #actionsTemplate let-index="index" let-$implicit="$implicit">
    <div class="row m-0 p-0 w-100">
        <div
            class="d-flex align-items-center justify-content-center h-100 w-100"
        >
            <div
                matTooltip="Ver"
                (click)="showSingleEnlistment($implicit)"
                class="btn btn-icon"
            >
                <img
                    src="./assets/icon/enlist_icon-visible.svg"
                    alt=""
                    class="icon-action"
                />
            </div>
            <div
                matTooltip="Imprimir"
                class="btn btn-icon"
                (click)="openPDF($implicit)"
            >
                <img
                    src="./assets/icon/imprimir.svg"
                    alt=""
                    class="icon-action"
                />
            </div>
        </div>
    </div>
</ng-template>

<div class="enlist-container container-fluid">
    <div class="row">
        <div class="col-12 page-title-heading pl-2">
            <div class="header-icon">
                <img src="/assets/icon/historial.svg" width="30" />
            </div>
            <div class="page-subheading">
                <div class="page-subheading-title">
                    Resumen de alistamientos Version anterior
                </div>
                <div class="page-subheading-subtitle">
                    Siempre antes de salir, realice una inspección
                    pre-operacional a su vehículo. Es por su propio bienestar y
                    por el de sus pasajeros. Recuerde: Ellos confían ciegamente
                    en usted..
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 col-xl-4">
            <app-card-counter
                [digit]="old_enlistments_count"
                title="Alistamientos"
                subtitle="Total alistamientos V.1.0"
            ></app-card-counter>
        </div>
        <div class="col-md-6 col-xl-4">
            <app-card-counter
                [digit]="old_vehicles_count"
                title="Vehiculos"
                subtitle="Vehiculos Operacionales"
            ></app-card-counter>
        </div>
        <div class="col-md-6 col-xl-4">
            <app-card-counter
                [digit]="old_ok_enlistments_count"
                title="Satisfactorios"
                subtitle="Alistamientos sin novedades"
            ></app-card-counter>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-table
                [model]="old_enlistmentModel"
                [removeCard]="true"
                [actionsTemplate]="actionsTemplate"
                (paginatorEvent)="old_paginatorEvent($event)"
                (pageSizeEvent)="old_pageSizeEvent($event)"
                [hideIndex]="true"
                (searchEvent)="old_searchP($event)"
                (filterEvent)="updateFilter($event)"
                (driverEvent)="filterDriver($event)"
                (unitEvent)="filterUnit($event)"
                (covidEvent)="showSingleCovidForm($event)"
                (revisionEvent)="validateRevision($event)"
            ></app-table>
        </div>
    </div>
    <ng-template [ngIf]="show_single_enlistment === true">
        <app-modal type="none">
            <app-single-old-enlistment
                [enlistment]="selected_enlistment"
            ></app-single-old-enlistment>
        </app-modal>
    </ng-template>
    <ng-template #covidContent [ngIf]="show_single_covid_form === true">
        <app-modal type="none">
            <div>
                <app-single-old-covid-form
                    [form]="selected_form"
                    origin="enlist"
                ></app-single-old-covid-form>
            </div>
        </app-modal>
    </ng-template>
</div>
