<div class="enlist-container">
    <div class="row">
        <div class="col-12 page-title-heading pl-2">
            <div class="header-icon">
                <img src="/assets/icon/notificaciones.svg" width="30" />
            </div>
            <div class="page-subheading">
                <div class="page-subheading-title">
                    Configuracion de preguntas Extra
                </div>
                <div class="page-subheading-subtitle">
                    Siempre antes de salir, realice una inspección
                    pre-operacional a su vehículo. Es por su propio bienestar y
                    por el de sus pasajeros. Recuerde: Ellos confían ciegamente
                    en usted..
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="enlist-card mb-3">
                <div class="card-header">
                    <div class="row content-header">
                        <h3 class="xs-title-dark">Preguntas perzonalizadas</h3>
                        <div class="row toggle-title">
                            <app-motion-checkbox
                                [value]="custom_fields_enabled"
                                (checkout)="toggleCustomQuestion()"
                            ></app-motion-checkbox>
                            <label
                                *ngIf="!custom_fields_enabled"
                                class="e-control-label"
                                >Deshabilitadas</label
                            >
                            <label
                                *ngIf="custom_fields_enabled"
                                class="e-control-label"
                                >Habilitadas</label
                            >
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <ng-container *ngIf="custom_fields_enabled">
                            <form
                                class="col-12 column custom-body"
                                [formGroup]="enlist_form"
                            >
                                <div class="row toggle-check">
                                    <div class="col-5 gray4">
                                        Formulario Alistamiento
                                    </div>
                                    <app-motion-checkbox
                                        [value]="custom_enlist_enabled"
                                        (checkout)="
                                            toggleCustomEnlistment(
                                                contentEnlisment
                                            )
                                        "
                                    ></app-motion-checkbox>
                                </div>
                                <div class="column form-custom">
                                    <div class="grid-col2">
                                        <input
                                            class="form-control form-control-sm"
                                            type="text"
                                            placeholder="Nombre de la pregunta"
                                            formControlName="name"
                                        />
                                        <div class="input-div button">
                                            <button
                                                type="button"
                                                class="ec-btn7"
                                                href="#"
                                                (click)="createQuestionEnlist()"
                                                *ngIf="!selected_enlist_bool"
                                            >
                                                Añadir pregunta
                                            </button>
                                            <button
                                                type="button"
                                                class="ec-btn8"
                                                href="#"
                                                (click)="updateEnlistQuestion()"
                                                *ngIf="selected_enlist_bool"
                                            >
                                                Editar pregunta
                                            </button>
                                        </div>
                                    </div>
                                    <div class="input-div">
                                        <textarea
                                            class="form-control tarea"
                                            formControlName="description"
                                            placeholder="Descripción pregunta.."
                                        ></textarea>
                                    </div>
                                </div>
                                <span class="gray2"
                                    >{{ enlist_custom_size }} preguntas
                                    disponibles</span
                                >
                                <ng-container *ngIf="custom_enlist_enabled">
                                    <app-questions-maker
                                        [_questions]="enlistments"
                                        (deleteQuestion)="
                                            deleteEnlistQuestion($event)
                                        "
                                        [_selected_question]="selected_enlist"
                                    ></app-questions-maker>
                                </ng-container>
                            </form>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="col-md-12">
          <div class="enlist-card mb-3">
            <app-accordion-templates>

            </app-accordion-templates>
          </div>
        </div>
    </div>
</div>
<ng-template #contentEnlisment let-modal>
    <app-modal>
        <app-action-confirm
            iconPath="/assets/icon/ec-alerta.svg"
            title="Está Seguro?"
            description="Las preguntas creadas se borraran y no se podrán recuperar."
            confirmText="Cancelar"
            cancelText="Deshabilitar"
            confirmClass="ec-btn6"
            cancelClass="ec-btn3"
            (confirmEvent)="cancelEnlistment()"
            (cancelEvent)="confirmEnlistment()"
        >
        </app-action-confirm>
    </app-modal>
</ng-template>
