import {Question} from '@/models/questions';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
    @Input()
    _question: Question;
    @Input()
    _index: number;
    @Input()
    _selected_question: Question;

    @Input()
    available_count: number;

    @Output()
    createQuestion = new EventEmitter<any>();
    @Output()
    deleteQuestionApi = new EventEmitter<any>();
    @Output()
    deleteQuestionIndex = new EventEmitter<any>();
    @Output()
    selectQuestion = new EventEmitter<any>();

    id: number;
    index: number;
    name: string = '';
    description: string = '';

    constructor() {}

    ngOnInit(): void {
        console.log(this._question);
        this.id = this._question.id;
        this.index = this._index;
        this.name = this._question.name;
        this.description = this._question.description;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}

    writeValue(obj: any): void {}

    propagateChange = (_: any) => {};

    postQuestion() {
        var form = {
            name: this.name,
            description: this.description
        };
        this.createQuestion.emit(form);
    }

    onSelectQuestion() {
        this.selectQuestion.emit(this._question);
    }

    onDeleteQuestion() {
        if (this.id) {
            this.deleteQuestionApi.emit(this.id);
        } else {
            this.deleteQuestionIndex.emit(this.index);
        }
    }
}
