<div class="modal-back">
    <div [ngClass]="{
      'modal-front_white': type === 'white',
      'modal-front_black': type === 'black',
      'modal-front_none': type === 'none'
    }">
        <ng-content></ng-content>
    </div>
    <div class="modal-close" (click)="close()"></div>
</div>
