import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TableViewModel} from '@components/models/table';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {PaginationService} from '@components/pagination/pagination.service';
import {ExcelService} from '@services/excel.service';
import {Router} from '@angular/router';


@Component({
    selector: 'app-manage-drivers',
    templateUrl: './manage-drivers.component.html',
    styleUrls: ['./manage-drivers.component.scss']
})
export class ManageDriversComponent implements OnInit {
    currentPage: number = 1;
    pageSize: number = 5;
    drivers: any[] = null;
    drivers_count: number = null;
    driversModel: TableViewModel = {
        title: 'Conductores',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {title: 'Nombre', key: 'name', filter: false, data: []},
            {title: 'Identificacion', key: 'cc', filter: false, data: []},
            {title: 'Licencia', key: 'licencia', filter: false, data: []},
            {title: 'Vencimiento', key: 'vencimiento', filter: false, data: []}
        ],
        records: [],
        showFilters: true,
        enableActions: true,
        isModal: false
    };
    searchby: string = '';

    constructor(
        private apiService: ApiService,
        private appService: AppService,
        private cd: ChangeDetectorRef,
        private pS: PaginationService,
        private excelService: ExcelService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.getDrivers(this.currentPage, this.searchby);
    }

    getDrivers(currentPage, searchby) {
        this.apiService
            .getDrivers(currentPage, this.pageSize, searchby)
            .then((drivers) => {
                console.log(drivers);
                if (drivers.count > 0) {
                    this.drivers = drivers.result;
                    this.drivers_count = drivers.total_count;
                    this.driversModel.records = this.drivers;
                    this.driversModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: drivers.count,
                        rowCount: this.drivers_count
                    };
                    this.cd.detectChanges();
                }
            });
    }
    onRemove(index: number) {
        console.log('remove event ! ');
    }

    paginatorEvent(currentPage: number) {
        this.getDrivers(currentPage, this.searchby);
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchP(searchby: string) {
        this.getDrivers(this.currentPage, searchby ? searchby : this.searchby);
    }

    viewEnlistments(driver: any) {
        this.router.navigate([`/enlistments/driver/${driver.id}`]);
    }

    viewCovid(driver: any) {
        this.router.navigate([`/covid-forms/driver/${driver.id}`]);
    }
}
