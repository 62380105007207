<div class="card-header">
    <div class="row content-header">
        <h3 class="xs-title-dark">Plantillas perzonalizadas</h3>
        <div class="row toggle-title">
          <button
          type="button"
          class="ec-btn7"
          href="#"
          (click)="openCreateModal()"
          >
          Crear plantilla
      </button>
        </div>
    </div>
</div>
<div class="card-body">
    <ng-container>
      <div class="accordion-container">
        <div *ngFor="let template of templates" class="template-item">
          <div class="template-header" [ngClass]="{ 'active': template === selectedTemplate }">
              <p (click)="selectTemplate(template)">{{ template.name }}</p>
              <app-motion-checkbox
                [value]="template.state === 'active'"
                (click)="changeStatus(template.id, template.name, template.state)"
              >
              </app-motion-checkbox>
              <img (click)="openEditModal(template)" class="questions_edit" src="../../../assets/icon/edit_icon.png" alt="edit template">
              <img *ngIf="template !== selectedTemplate" (click)="selectTemplate(template)" src="../../../assets/icon/desplegar_icon.svg" alt="Show questions">
              <img class="rotated" *ngIf="template === selectedTemplate" (click)="selectTemplate(template)" src="../../../assets/icon/desplegar_icon.svg" alt="Hide questions">

          </div>
          <div class="template-content" *ngIf="template === selectedTemplate">
            <app-questions-per-template
            selectedTemplate='{{template.id}}'>
            </app-questions-per-template>
          </div>
        </div>
      </div>
    </ng-container>
</div>
 <!-- This modal is showed when select create a template -->
 <app-modal *ngIf="showCreateModal" type="black">
  <div class="templates_modal_container">
    <div class="templates_modal_close_container">
      <div (click)="closesCreateModal()" class="templates_modal_close"></div>
    </div>
    <h2>Nueva plantilla:</h2>
    <form action="">
      <label for="templateName">Nombre: </label>
      <input id="templateName" type="text" [(ngModel)]="templateToCreate.name" name="name">
      <button
          type="button"
          class="ec-btn7"
          href="#"
          (click)="createTemplate()">
          Crear
      </button>
    </form>
  </div>
</app-modal>
 <!-- This modal is showed when select edit a template -->
<app-modal *ngIf="showEditModal" type="black">
  <div class="templates_modal_container">
    <div class="templates_modal_close_container">
      <div (click)="closesEditModal()" class="templates_modal_close"></div>
    </div>
    <h2>Editar plantilla:</h2>
    <form action="">
      <label for="templateName">Nombre: </label>
      <input id="templateName" type="text" [(ngModel)]="templateToUpdate.name" name="name">
      <button
          type="button"
          class="ec-btn7"
          href="#"
          (click)="updateTemplate()">
          Cambiar nombre
      </button>
      <button
          type="button"
          class="ec-btn8"
          href="#"
          (click)="openDeleteModal()">
          Eliminar
      </button>
    </form>
  </div>
</app-modal>
<!-- This modal is showed when select delete template -->
<app-modal *ngIf="showDeleteModal" type="black">
  <div class="templates_modal_container delete_template_container">
    <div class="templates_modal_close_container">
      <div (click)="closesDeleteModal()" class="templates_modal_close"></div>
    </div>
    <h2>Precaución!</h2>
    <p>Al confirmar se eliminará la plantilla:</p>
    <p class="red_text">{{templateToUpdate.name}}</p>
    <button
          type="button"
          class="ec-btn8"
          href="#"
          (click)="deleteTemplate()">
          Eliminar
      </button>
  </div>
</app-modal>
<!-- This modal is showed when select delete question -->
<app-modal *ngIf="showErrorModal" type="black">
  <div class="templates_modal_container delete_template_container">
    <div class="templates_modal_close_container">
      <div (click)="closesErrorModal()" class="templates_modal_close"></div>
    </div>
    <h2>Error!</h2>
    <div class="img_container">
      <img src="../../../assets/icon/ec-alerta.svg" alt="Error alert">
    </div>
    <p class="red_text">{{errorToShow}}</p>
  </div>
</app-modal>

