<div class="enlist-container">
    <ng-container *ngIf="loading">
        <div class="spinner-grow text-success" role="status">
            <span class="visually-hidden"></span>
        </div>
        <div class="spinner-grow text-success" role="status">
            <span class="visually-hidden"></span>
        </div>
        <div class="spinner-grow text-success" role="status">
            <span class="visually-hidden"></span>
        </div>
    </ng-container>
    <ng-container *ngIf="!loading">
        <div
            class="card"
            [ngClass]="{
                'old-enlistment-card': data.state === 'creado',
                'old-enlistment-card-incomplete': data.state === 'incompleto'
            }"
        >
            <div
                class="card-header"
                [ngClass]="{
                    'old-enlistment-card-header-complete':
                        data.state === 'creado',
                    'old-enlistment-card-header-incomplete':
                        data.state === 'incompleto'
                }"
                *ngIf="data"
            >
                Alistamiento {{ data.folio }}
            </div>
            <div class="card-body old-enlistment-card-body">
                <div class="row">
                    <div
                        [ngClass]="{
                            'col-12': data.state === 'creado',
                            'col-6': data.state === 'incompleto'
                        }"
                    >
                        <div class="col-12">
                            <div class="row pb-4">
                                <div class="header-icon">
                                    <img
                                        src="./assets/icon/ec-icon.svg"
                                        width="80"
                                    />
                                </div>
                                <div class="page-subheading ml-4">
                                    <div class="page-subheading-subtitle">
                                        En el Código Nacional de Tránsito
                                        Terrestre o Ley 769 del 6 de agosto de
                                        2002 se encuentran las disposiciones
                                        legales que rigen la actividad. Es su
                                        deber conocer, aplicar y tener siempre
                                        presente este documento.
                                    </div>
                                </div>
                            </div>
                            <div class="row-cols-1 pb-4">
                                <span class="data-label"
                                    ><strong>Fecha y Hora </strong></span
                                ><br />
                                <span class="data-text">{{ data.date }}</span>
                            </div>
                            <div class="row-cols-1 pb-4">
                                <span class="data-label"
                                    ><strong>Conductor </strong></span
                                ><br />
                                <span class="data-text">{{ data.driver }}</span>
                            </div>
                            <div class="row-cols-1 pb-4">
                                <span class="data-label"
                                    ><strong>Vehiculo </strong></span
                                ><br />
                                <span class="data-text">{{ data.unit }}</span>
                            </div>
                            <div class="row-cols-2 pb-4">
                                <span class="data-label"
                                    ><strong>Estado </strong></span
                                ><br />
                                <span
                                    class="creado"
                                    *ngIf="data.state === 'creado'"
                                >
                                    Sin Novedades
                                </span>
                                <span
                                    class="incompleto"
                                    *ngIf="data.state === 'incompleto'"
                                >
                                    Con Novedades
                                </span>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <button
                                        [ngClass]="{
                                            'ec-btn8':
                                                data.state === 'incompleto',
                                            'ec-btn7': data.state === 'creado'
                                        }"
                                        (click)="openPDF()"
                                    >
                                        Imprimir
                                    </button>
                                </div>
                                <div class="col-6">
                                    <div
                                        class="row container-checks"
                                        *ngIf="position"
                                    >
                                        <div class="">
                                            <ng-container
                                                *ngIf="data.rc === 'no'"
                                            >
                                                <img
                                                    src="./assets/icon/ec-coordinacion.svg"
                                                    alt=""
                                                    class="icon-revision"
                                                    (click)="
                                                        position.value ===
                                                        'coordinador'
                                                            ? checkEnlist()
                                                            : ''
                                                    "
                                                    [ngStyle]="{
                                                        cursor:
                                                            position.value ===
                                                            'coordinador'
                                                                ? 'pointer'
                                                                : 'no-drop'
                                                    }"
                                                />
                                            </ng-container>
                                            <ng-container
                                                *ngIf="data.rc === 'readed'"
                                            >
                                                <img
                                                    src="./assets/icon/ec-coordinacion-visto.svg"
                                                    alt=""
                                                    class="icon-revision"
                                                    (click)="
                                                        position.value ===
                                                        'coordinador'
                                                            ? checkEnlist()
                                                            : ''
                                                    "
                                                    [ngStyle]="{
                                                        cursor:
                                                            position.value ===
                                                            'coordinador'
                                                                ? 'pointer'
                                                                : 'no-drop'
                                                    }"
                                                />
                                            </ng-container>
                                            <ng-container
                                                *ngIf="data.rc === 'checked'"
                                            >
                                                <img
                                                    src="./assets/icon/ec-coordinacion-revisado.svg"
                                                    alt=""
                                                    class="icon-revision"
                                                    style="cursor: no-drop"
                                                />
                                            </ng-container>
                                        </div>
                                        <div class="">
                                            <ng-container
                                                *ngIf="data.rg === 'no'"
                                            >
                                                <img
                                                    src="./assets/icon/ec-gerencia.svg"
                                                    alt=""
                                                    class="icon-revision"
                                                    (click)="
                                                        position.value ===
                                                        'gerente'
                                                            ? checkEnlist()
                                                            : ''
                                                    "
                                                    [ngStyle]="{
                                                        cursor:
                                                            position.value ===
                                                            'gerente'
                                                                ? 'pointer'
                                                                : 'no-drop'
                                                    }"
                                                />
                                            </ng-container>
                                            <ng-container
                                                *ngIf="data.rg === 'readed'"
                                            >
                                                <img
                                                    src="./assets/icon/ec-gerencia-visto.svg"
                                                    alt=""
                                                    class="icon-revision"
                                                    (click)="
                                                        position.value ===
                                                        'gerente'
                                                            ? checkEnlist()
                                                            : ''
                                                    "
                                                    [ngStyle]="{
                                                        cursor:
                                                            position.value ===
                                                            'gerente'
                                                                ? 'pointer'
                                                                : 'no-drop'
                                                    }"
                                                />
                                            </ng-container>
                                            <ng-container
                                                *ngIf="data.rg === 'checked'"
                                            >
                                                <img
                                                    src="./assets/icon/ec-gerencia-revisado.svg"
                                                    alt=""
                                                    class="icon-revision"
                                                    style="cursor: no-drop"
                                                />
                                            </ng-container>
                                        </div>
                                        <div class="">
                                            <ng-container
                                                *ngIf="data.rm === 'no'"
                                            >
                                                <img
                                                    src="./assets/icon/ec-mantenimiento.svg"
                                                    alt=""
                                                    class="icon-revision"
                                                    (click)="
                                                        position.value ===
                                                        'mantenimiento'
                                                            ? showSingleEnlistment(
                                                                  questions
                                                              )
                                                            : ''
                                                    "
                                                    [ngStyle]="{
                                                        cursor:
                                                            position.value ===
                                                            'gerente'
                                                                ? 'pointer'
                                                                : 'no-drop'
                                                    }"
                                                />
                                            </ng-container>
                                            <ng-container
                                                *ngIf="data.rm === 'readed'"
                                            >
                                                <img
                                                    src="./assets/icon/ec-mantenimiento-visto.svg"
                                                    alt=""
                                                    class="icon-revision"
                                                    (click)="
                                                        position.value ===
                                                        'mantenimiento'
                                                            ? showSingleEnlistment(
                                                                  questions
                                                              )
                                                            : ''
                                                    "
                                                    [ngStyle]="{
                                                        cursor:
                                                            position.value ===
                                                            'mantenimiento'
                                                                ? 'pointer'
                                                                : 'no-drop'
                                                    }"
                                                />
                                            </ng-container>
                                            <ng-container
                                                *ngIf="data.rm === 'checked'"
                                            >
                                                <img
                                                    src="./assets/icon/ec-mantenimiento-revisado.svg"
                                                    alt=""
                                                    class="icon-revision"
                                                    (click)="
                                                        position.value ===
                                                        'mantenimiento'
                                                            ? showSingleEnlistment(
                                                                  questions
                                                              )
                                                            : ''
                                                    "
                                                    [ngStyle]="{
                                                        cursor:
                                                            position.value ===
                                                            'mantenimiento'
                                                                ? 'pointer'
                                                                : 'no-drop'
                                                    }"
                                                />
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="data.state === 'incompleto'">
                        <div class="col-6">
                            <div class="card enlist-card mb-4">
                                <mdb-carousel
                                    [isControls]="true"
                                    class="
                                        carousel-multi-item
                                        multi-animation
                                        novelty-carrousel
                                    "
                                    [animation]="'fade'"
                                >
                                    <ng-container
                                        *ngFor="let quest of questions"
                                    >
                                        <mdb-carousel-item
                                            class="novelty-box"
                                            *ngIf="quest.novetly"
                                        >
                                            <div class="col-12">
                                                <mdb-card
                                                    class="row novelty-row my-1"
                                                >
                                                    <ng-container *ngIf="!quest.image">
                                                      <img
                                                        src="/assets/images/image-not-found-scaled-1150x647.png"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                      />
                                                    </ng-container>
                                                    <ng-container *ngIf="quest.image">
                                                      <img
                                                        src="data:image/png;base64,{{
                                                            quest.image
                                                        }}"
                                                        class="novelty-img"
                                                        alt="Evidencia"
                                                      />
                                                    </ng-container>
                                                    <div class="novelty-info">
                                                        <strong
                                                            class="
                                                                novelty-title
                                                            "
                                                        >
                                                            {{
                                                                quest.name
                                                            }} </strong
                                                        ><br />
                                                        <span
                                                            class="novelty-desc"
                                                        >
                                                            {{
                                                                quest.description
                                                            }}.</span
                                                        >
                                                    </div>
                                                    <mdb-card-body>
                                                    </mdb-card-body>
                                                </mdb-card>
                                            </div>
                                        </mdb-carousel-item>
                                    </ng-container>
                                </mdb-carousel>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template [ngIf]="check_maintenance === true">
        <app-modal type="none">
            <app-novelty-check
                [news_maintenance]="questions"
                [enlist_id]="data.id"
                [checked]="data.rm === 'checked' ? true : false"
            ></app-novelty-check>
        </app-modal>
    </ng-template>
</div>
