import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {Question} from '@/models/questions';
import {ControlValueAccessor, FormControl} from '@angular/forms';
import {count} from 'rxjs/operators';
import internal from 'stream';

@Component({
    selector: 'app-questions-maker',
    templateUrl: './questions-maker.component.html',
    styleUrls: ['./questions-maker.component.scss']
})
export class QuestionsMakerComponent implements OnInit, ControlValueAccessor {
    @Input()
    _questions: Question[];

    @Input()
    _selected_question: Question;

    @Input()
    available_count: number;

    @Output()
    createQuestion = new EventEmitter<any>();

    @Output()
    deleteQuestion = new EventEmitter<any>();

    @Output()
    selectQuestion = new EventEmitter<any>();

    id: number;
    name = new FormControl();
    description = new FormControl();

    blocked: boolean = false;

    get questions() {
        return this._questions;
    }

    set questions(val) {
        this._questions = val;
        this.propagateChange(this._questions);
    }

    get selected_question() {
        return this._selected_question;
    }

    set selected_question(val) {
        this._selected_question = val;
        this.propagateChange(this._selected_question);
    }

    get count() {
        return this.available_count;
    }

    set count(val) {
        this.available_count = val;
        this.propagateChange(this.available_count);
    }

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit(): void {}

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}

    writeValue(obj: any): void {}

    propagateChange = (_: any) => {};

    newElement() {
        if (this.available_count > 0) {
            let newQuestion = new Question();
            this.available_count = this.available_count - 1;
            this.questions.push(newQuestion);
            this.propagateChange(this._questions);
        } else {
            this.blocked = true;
        }
    }

    postQuestion(form: any) {
        this.createQuestion.emit(form);
    }

    deleteQuest(id: number) {
        this.deleteQuestion.emit(id);
    }

    onDeleteQuestion(index: number) {
        this.available_count = this.available_count + 1;
        this.questions.splice(index, 1);
        this.propagateChange(this._questions);
    }

    onSelectQuestion(data: any) {
        this.selectQuestion.emit(data);
    }
}
