import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SwitchService } from '@services/switch.service';
import { log } from 'console';

@Component({
  selector: 'app-accordion-templates',
  templateUrl: './accordion-templates.component.html',
  styleUrls: ['./accordion-templates.component.scss']
})
export class AccordionTemplatesComponent implements OnInit {
  // saves all the templates
  templates: any[] = [];

  //used to manage accordion and send as prop to child
  selectedTemplate: any = null;

  // values to show modals to crud
  showEditModal: boolean = false;
  showCreateModal: boolean = false;
  showDeleteModal: boolean = false;

  showErrorModal: boolean = false;

  // any modal saves que question in a var to manage it
  // in this case delete uses the templateToUpdate
  templateToUpdate: any ;
  templateToCreate: any ;
  errorToShow: string;

  constructor(
    private editModalService: SwitchService,
    private deleteModalService: SwitchService,
    private createModalService: SwitchService,
    private showErrorService: SwitchService,
    private http: HttpClient
  ) {
    this.editModalService.$modal.subscribe((value: boolean) => {
      this.showEditModal = value;
    });

    this.deleteModalService.$modal2.subscribe((value: boolean) => {
      this.showDeleteModal = value;
    });

    this.createModalService.$modal3.subscribe((value: boolean) => {
      this.showCreateModal = value;
    });

    this.showErrorService.$modal4.subscribe((value: boolean) => {
      this.showErrorModal = value;
    });
  }

  // functions to opens and closes the modals

  openEditModal(template: any) {
    this.templateToUpdate = {}
    this.templateToUpdate = {...template}
    this.editModalService.$modal.emit(true);
  }

  closesEditModal() {
    this.editModalService.$modal.emit(false);
  }

  openDeleteModal() {
    this.deleteModalService.$modal2.emit(true);
    this.closesEditModal()
  }

  closesDeleteModal() {
    this.deleteModalService.$modal2.emit(false);
  }

  openCreateModal() {
    this.templateToCreate = {}
    this.createModalService.$modal3.emit(true);
  }

  closesCreateModal() {
    this.createModalService.$modal3.emit(false);
  }

  openErrorModal(error: string) {
    this.errorToShow = error
    this.showErrorService.$modal4.emit(true);
  }

  closesErrorModal() {
    this.showErrorService.$modal4.emit(false);
  }

  // CRUD functions
  // get all the user resource associated
  async getTemplates  (): Promise<any[]> {
    try {
      let response  = await this.http.get<any[]>('/api/enlistcontrol/custom_templates/get').toPromise()
      try {
        if (response[0]['error'] != null){
          this.openErrorModal(response[0]['error'])
          return []
        }
      } catch (error) {

      }
      return response
    } catch (error) {
      this.openErrorModal(`Ha ocurrido un error inesperado obteniendo las plantillas `)
    }

  }

  // make a status change,
  async changeStatus (template_id : number, name : String, state: String): Promise<void>{
    try {
      state = state === 'active'? 'inactive': 'active'
    let response = await this.http.put<any>(`/api/enlistcontrol/custom_templates/update/${template_id}`,
      {"jsonrpc": "2.0",
        "method": "call",
        "params": {"context": {},
            "name": name,
            "state" : state
        },
      "id": null}
    ).toPromise()
    if (response['result']['error'] != null){
      this.openErrorModal(response['result']['error'])
      return
    }
    this.templates = await this.getTemplates()
    } catch (error) {
      this.openErrorModal(`Ha ocurrido un error inesperado`)
    }

  }

  async updateTemplate (): Promise<void>{
    try {
      let response = await this.http.put<any>(`/api/enlistcontrol/custom_templates/update/${this.templateToUpdate.id}`,
        {"jsonrpc": "2.0",
          "method": "call",
          "params": {"context": {},
              "name": this.templateToUpdate.name,
              "state": this.templateToUpdate.state
          },
        "id": null}
      ).toPromise()
      if (response['result']['error'] != null){
        this.openErrorModal(response['result']['error'])
        this.closesEditModal()
        return
      }
      if(response['result'][0]['id'] == this.templateToUpdate.id){
        for (let i in this.templates){
          if (this.templates[i]['id'] == this.templateToUpdate.id){
            this.templates[i] = {}
            this.templates[i] = {...this.templateToUpdate}
          }
        }
      }
      this.closesEditModal()
    } catch (error) {
      this.openErrorModal(`Ha ocurrido un error inesperado`)
      this.closesEditModal()
    }

  }



  async createTemplate (): Promise<void>{
    try {
      let response = await this.http.post<any>(`/api/enlistcontrol/custom_templates/create`,
        {"jsonrpc": "2.0",
          "method": "call",
          "params": {"context": {},
              "name": this.templateToCreate.name
          },
        "id": null}
      ).toPromise()

      if (response['result']['error'] != null){
        this.openErrorModal(response['result']['error'])
        this.closesCreateModal()
        return
      }
      if (response['result'][0]['id'] != null){
        this.templates.push(response['result'][0])
        this.selectTemplate(response['result'][0])
        this.closesCreateModal()
      }
    } catch (error) {
      this.openErrorModal(`Ha ocurrido un error inesperado`)
      this.closesCreateModal()
    }


  }

  async deleteTemplate(): Promise<void>{
    try {
      const response = await this.http.delete<any>(`/api/enlistcontrol/custom_templates/delete/${this.templateToUpdate.id}`).toPromise();
      if (response[0]['error'] != null){
        this.openErrorModal(response[0]['error'])
        this.closesDeleteModal()
        return
      }
      if (response[0]['id'] == this.templateToUpdate.id ){
        this.templates = this.templates.filter(template => template.id !== this.templateToUpdate.id);
        this.closesDeleteModal();
      }

    } catch (error) {
      this.openErrorModal(`Ha ocurrido un error inesperado`)
      this.closesDeleteModal()
    }

  }

  async ngOnInit(): Promise<void> {
    this.templates = await this.getTemplates()
  }


    // to manage the accordion
  selectTemplate(template: any): void {
    if (this.selectedTemplate === template) {
      this.selectedTemplate = null;
    } else {
      this.selectedTemplate = template;
    }
  }

}
