import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ApiService} from '@services/api.service';
import {Enlistment} from '@/models/Enlistment';
import {AppService} from '@services/app.service';
import pdfMake from 'pdfmake/build/pdfmake';
import {Console} from 'console';
import {Questions, Question} from '@/models/questions';
import {motion_logo} from 'assets/images/motion_logo';
import {Position} from '@/models/Position';
import {SwitchService} from '@services/switch.service';

@Component({
    selector: 'app-single-enlistment',
    templateUrl: './single-enlistment.component.html',
    styleUrls: ['./single-enlistment.component.scss']
})
export class SingleEnlistmentComponent implements OnInit {
    @Input()
    enlistment: any = null;
    user_type: string = 'gerencia';
    loading: boolean = true;
    data: Enlistment = null;
    cards = [];
    slides: any = [[]];
    account: any;
    questions: Question[] = [];
    fullImagePath = 'assets/images/logo_motion.png';
    position: Position = {name: '', value: ''};
    check_maintenance: boolean = false;

    chunk(arr, chunkSize) {
        let R = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize) {
            R.push(arr.slice(i, i + chunkSize));
        }
        return R;
    }
    constructor(
        private apiService: ApiService,
        private appService: AppService,
        private cd: ChangeDetectorRef,
        private modalService: SwitchService
    ) {}

    ngOnInit(): void {
        this.modalService.$modal2.subscribe((value) => {
            this.check_maintenance = value;
        });
        this.slides = this.chunk(this.cards, 3);
        if (!this.enlistment) {
            console.warn('No seteaste data o algo salio mal :C');
        } else {
            this.getSingleEnlist();
        }
        this.getAccount();
    }

    getAccount() {
        this.appService.getAccountInfo().then((account) => {
            this.account = account;
            this.position = this.account.admin_field.find(
                (field: any) => field.name === 'cargo'
            );
            this.cd.detectChanges();
        });
    }

    getSingleEnlist() {
        this.apiService
            .getSingleEnlistmentInfo(this.enlistment.folio)
            .then((enlistment) => {
                this.data = enlistment.result[0];
                this.getQuestions(this.enlistment.folio);
            });
    }

    ngOnDestroy() {
        if (this.account.admin_field) {
            var data = {
                enlist_id: this.enlistment.id
            };
            this.apiService.pullRsRead(data).then(() => {
                this.getAccount();
            });
        }
    }

    checkEnlist() {
        var data = {
            enlist_id: this.enlistment.id
        };
        this.apiService.pullRsCheck(data).then(() => {
            this.getSingleEnlist();
        });
    }
    showSingleEnlistment(data) {
        this.modalService.$modal2.emit(true);
    }

    getQuestions(folio: string) {
        this.apiService.getQuestionsTemplate(folio).then((response) => {
            this.questions = response.result;
            this.loading = false;
        });
    }

    async openPDF() {
        this.loading = true;
        console.log('este es el folio');
        console.log(this.data);
        console.log(this.questions);
        var contentTable = [];
        var contentNovetlys = [];
        for (let quest of this.questions) {
            contentTable.push([
                quest.name,
                quest.novetly ? 'X' : '-',
                !quest.novetly ? 'X' : '-'
            ]);
        }

        for (let quest of this.questions) {
            if (quest.novetly) {
                contentNovetlys.push([
                    {
                        text: quest.name,
                        fontSize: 15,
                        alignment: 'center',
                        margin: [0, 5],
                        bold: true
                    },
                    {
                        image: quest.image != "" ? `data:image/jpeg;base64,${quest.image}`: await this.getBase64ImageFromURL('/assets/images/image-not-found-scaled-1150x647.png'),
                        width: 250,
                        margin: [0, 10],
                        alignment: 'center'
                    },
                    {
                        text: ` Descripcion: ${quest.description}`,
                        fontSize: 12,
                        alignment: 'center'
                    }
                ]);
            }
        }

        var docDefinition = {
            pageMargins: [40, 90, 40, 60],
            header: {
                columns: [
                    this.account && this.account.iso_img
                        ? {
                              image: `data:image/jpeg;base64,${this.account.logo}`,
                              width: 120,
                              height: 60,
                              alignment: 'left',
                              opacity: 0.9,
                              margin: [50, 10]
                          }
                        : {},
                    this.account && this.account.iso_img
                        ? {
                              image: `data:image/jpeg;base64,${this.account.iso_img}`,
                              width: 200,
                              height: 60,
                              alignment: 'right',
                              opacity: 0.9,
                              margin: [0, 10]
                          }
                        : {
                              image: motion_logo,
                              width: 200,
                              height: 60,
                              alignment: 'right',
                              opacity: 0.9,
                              margin: [0, 10]
                          }
                ],
                columnGap: 200
            },
            footer: {
                columns: [
                    this.account && this.account.signature
                        ? {
                                image: `data:image/jpeg;base64,${this.account.signature}`,
                                width: 120,
                                height: 60,
                                alignment: 'left',
                                opacity: 0.9,
                                margin: [50]
                            }
                        : {},
                    {
                        text: 'monitoringinnovation.com',
                        link: 'http://monitoringinnovation.com',
                        alignment: 'right',
                        margin: [0, 10, 10, 0]
                    }
                ]
            },
            content: [
                {
                    text: `Alistamiento: ${this.data.folio}`,
                    fontSize: 16,
                    margin: [0, 5]
                },
                {
                    text: `Fecha: ${this.data.date}`,
                    fontSize: 16,
                    margin: [0, 5]
                },
                {
                    columns: [
                        [
                            {
                                text: `Vehiculo:`,
                                fontSize: 12,
                                alignment: 'center'
                            },
                            {
                                text: `${this.data.unit}`,
                                fontSize: 14,
                                alignment: 'center',
                                bold: true
                            }
                        ],
                        [
                            {
                                text: `Conductor:`,
                                fontSize: 12,
                                alignment: 'center'
                            },
                            {
                                text: `${this.data.driver}`,
                                fontSize: 14,
                                alignment: 'center',
                                bold: true
                            }
                        ],
                        [
                            {
                                text: `Revision Gerencia:`,
                                fontSize: 12,
                                alignment: 'center'
                            },
                            {
                                text: `Sí`,
                                fontSize: 14,
                                alignment: 'center',
                                bold: true
                            }
                        ],
                        [
                            {
                                text: `Revision jefe de mantenimiento:`,
                                fontSize: 12,
                                alignment: 'center'
                            },
                            {
                                text: `Sí`,
                                fontSize: 14,
                                alignment: 'center',
                                bold: true
                            }
                        ]
                    ],
                    margin: [0, 10]
                },
                {
                    layout: 'lightHorizontalLines',
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 1,
                        widths: ['*', 100, 100],

                        body: [
                            [
                                {text: 'ITEM', bold: true},
                                {text: 'CON NOVEDAD', bold: true},
                                {text: 'SIN NOVEDAD', bold: true}
                            ],
                            ...contentTable
                        ]
                    }
                },
                {
                    text: `Novedades`,
                    fontSize: 16,
                    bold: true,
                    margin: [0, 5]
                },
                ...contentNovetlys
            ]
        };

        pdfMake.tableLayouts = {
            exampleLayout: {
                hLineWidth: function (i, node) {
                    if (i === 0 || i === node.table.body.length) {
                        return 0;
                    }
                    return i === node.table.headerRows ? 2 : 1;
                },
                vLineWidth: function (i) {
                    return 0;
                },
                hLineColor: function (i) {
                    return i === 1 ? 'black' : '#8a8a8a';
                },
                paddingLeft: function (i) {
                    return i === 0 ? 0 : 8;
                },
                paddingRight: function (i, node) {
                    return i === node.table.widths.length - 1 ? 0 : 8;
                }
            }
        };
        pdfMake.createPdf(docDefinition).open();
        this.loading = false;
    }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }
}
