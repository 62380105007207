import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-counter',
  templateUrl: './card-counter.component.html',
  styleUrls: ['./card-counter.component.scss']
})
export class CardCounterComponent implements OnInit {
  @Input() digit: number = 0;
  @Input() title: string = "--";
  @Input() subtitle: string = "--";
  constructor() { }

  ngOnInit(): void {
    console.log("inicializando componente card");
  }

}
