<div class="card mb-3 widget-content bg-night-sky">
  <div class="widget-content-wrapper text-white">
    <div class="widget-content-left">
      <div class="widget-heading">{{title}}</div>
      <div class="widget-subheading">{{subtitle}}</div>
    </div>
    <div class="widget-content-right">
      <app-animated-counter
        [digit]="digit"
        [steps]="10"
        [duration]="1000"
      ></app-animated-counter>
    </div>
  </div>
</div>
