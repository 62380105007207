import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-motion-checkbox',
    templateUrl: './motion-checkbox.component.html',
    styleUrls: ['./motion-checkbox.component.scss']
})
export class MotionCheckboxComponent implements OnInit {
    @Input()
    value: boolean;

    @Output()
    checkout = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    onCheck() {
        this.checkout.emit();
    }
}
