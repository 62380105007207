import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    TemplateRef
} from '@angular/core';
import {ECFilter, TableViewModel, Value} from '../models/table';
import {Select2OptionData} from 'ng-select2';
import {Options} from 'select2';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ExcelService} from '@services/excel.service';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
    @Input()
    minHeight = 290;

    @Input()
    pageSize = 5;

    @Input()
    hideIndex: boolean = false;

    @Input()
    actionRow: boolean = false;

    @Input()
    selectedRow: [] = [];

    @Input()
    model: TableViewModel;

    @Input()
    removeCard: boolean;

    @Output()
    rowEvent = new EventEmitter<any>();

    @Output()
    paginatorEvent = new EventEmitter<number>();

    @Output()
    pageSizeEvent = new EventEmitter<number>();

    @Output()
    getRecords = new EventEmitter<any>();

    @Output()
    searchEvent = new EventEmitter<string>();

    @Output()
    filterEvent = new EventEmitter();

    @Output()
    driverEvent = new EventEmitter();

    @Output()
    unitEvent = new EventEmitter();

    @Output()
    covidEvent = new EventEmitter<string>();

    @Output()
    revisionEvent = new EventEmitter();

    @Output()
    filterSearchEvent = new EventEmitter();

    @Output()
    filterDrivers = new EventEmitter<string>()

    @Output()
    filterUnits = new EventEmitter<string>()

    dropdownSettings = {};

    @Input()
    rowTemplate: TemplateRef<any>;

    @Input()
    actionsTemplate: TemplateRef<any>;

    index: any;

    filterKey: string;

    order: string = 'none';

    filterStatusArray: ECFilter[] = [];


    constructor(
        private modalService: NgbModal,
        private excelService: ExcelService
    ) {}

    public exampleData: Array<Select2OptionData>;
    public value: string[];

    ngOnInit(): void {

    }

    clickedRow(index: number) {
        if (this.actionRow) {
            this.rowEvent.emit(index);
        }
    }

    clickedPage(page: number) {
        this.paginatorEvent.emit(page);
        this.selectedRow = null;
    }

    selectColor(i: number) {
        var selected = this.selectedRow.find((index) => index === i);
        if (!selected && selected !== 0) {
            return '';
        } else {
            return '#ffffff';
        }
    }

    selectBackroundColor(i: number) {
        var selected = this.selectedRow.find((index) => index === i);
        if (!selected && selected !== 0) {
            return '';
        } else {
            return '#c6007e';
        }
    }

    clickedPageSize(pageSize: number) {
        this.pageSizeEvent.emit(pageSize);
        this.pageSize = pageSize;
    }

    getTotalRecords(header: any) {
      this.getRecords.emit(header);
    }

    updateFilterStatus(key: string, prevOrder: string){
      var selectedField = this.model.fields.find((field) => field.key === key);
      selectedField.filterOrder = this.getNextFilterState(selectedField.filterOrder);
      this.filterEvent.emit({key: key, order: selectedField.filterOrder});
    }

    updateFilterSearchStatus(key: string, ref: string){

      this.filterSearchEvent.emit({key: key, ref: ref});
    }

    showFilterSearch(key: string, data: Value[]){
      // TODO: mostrar filtro y capturar evento clic.
      // var selectedField = this.model.fields.find((field) => field.key === key);
      // selectedField.filterOrder = selectedField.filterOrder === 'none' ? this.getNextFilterState(selectedField.filterOrder) : 'none' ;

    }

    changeSearch(searchby: string) {
        this.searchEvent.emit(searchby);
    }

    public valueChanged(event: string) {}

    public modelChanged(event: string) {
        this.paginatorEvent.emit();
    }

    open(content) {
        this.modalService.open(content, {centered: true, size: 'xl'});
        this.paginatorEvent.emit(1);
    }

    getNextFilterState(prev: string)
    {
      let next: string;
      switch (prev) {
        case 'asc':
          next = 'desc'
          break;
        case 'desc':
          next = 'none'
          break;
        default:
          next = 'asc'
          break;
      }
      return next
    }

    onChangeDriver(key, event){
      this.driverEvent.emit({
        key: key,
        value: event.srcElement.value
      });
    }
    onChangeUnit(key, event){
      this.unitEvent.emit({
        key: key,
        value: event.srcElement.value
      });
    }
    handleFilterByDriver(event: any) {
      this.filterDrivers.emit(event.target.value)
    }
    handleFilterByUnit(event: any) {
      this.filterUnits.emit(event.target.value)
    }

    filterCovid(folio){
      this.covidEvent.emit(folio);
    }

    catchRevision(type, folio){
      this.revisionEvent.emit({
        type: type,
        folio: folio
      });
    }
}
