import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pagination } from '@components/models/Pagination';
import { Value, Field } from '@components/models/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-popup-select',
  templateUrl: './popup-select.component.html',
  styleUrls: ['./popup-select.component.scss']
})
export class PopupSelectComponent implements OnInit {

  private _value: Value[];

  currentSub : Subscription;

  get value(){
    if (!this._value){
      return [];
    }
    return this._value;
  }

  set value(val){
    this._value = val;
    this.propagateChange(this._value);
  }

  @Input()
  field: Field;

  @Output()
  onApply = new EventEmitter();

  @Input()
  showPlain = false;

  @Input()
  formGroupClass = '';


  pagination = new Pagination();

  
  constructor(private suscription: Subscription ,private modalService: NgbModal,
    private detector: ChangeDetectorRef) { }

  ngOnInit(): void {
    const timer$ = timer(1000, 1000);
    this.suscription = timer$.subscribe(() => this.detector.detectChanges());

  }

  ngOnDestroy() {
    this.suscription.unsubscribe();
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  writeValue(value: any) {
    this.value = value;
  }

  openModal(){
    console.log("Click modal");
  }
}
