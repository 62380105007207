<form class="grid-2col enlist-form" *ngIf="account" [formGroup]="form_account">
    <div class="container row rows_grid__s">
        <div class="grid inputs-container">
            <div class="input-div">
                <label for="name">
                    <h3 class="label-title">Nombre</h3>
                </label>
                <input
                    id="name"
                    type="text"
                    placeholder="Nombre"
                    formControlName="name"
                />
            </div>
            <div class="input-div">
                <label for="celular">
                    <h3 class="label-title">Celular</h3>
                </label>
                <input
                    id="celular"
                    type="tel"
                    placeholder="Número de celular"
                    maxLength="10"
                    pattern="\d{10}"
                    formControlName="phone"
                />
            </div>
        </div>
        <div class="grid inputs-container">
            <div class="input-div">
                <label for="direccion">
                    <h3 class="label-title">Dirección</h3>
                </label>
                <input
                    id="direccion"
                    type="text"
                    placeholder="Dirección"
                    formControlName="street"
                />
            </div>

            <div class="input-div">
                <label for="email">
                    <h3 class="label-title">E-mail*</h3>
                </label>
                <input
                    id="email"
                    type="email"
                    placeholder="Correo electrónico"
                    formControlName="email"
                />
            </div>
        </div>
        <div class="grid inputs-container grid__imgs">
            <div class="grid input-archivo">
                <img
                    class="img"
                    *ngIf="!account.logo"
                    src="assets/img/company-logo.png"
                />
                <img
                    class="img"
                    *ngIf="account.logo"
                    src="{{ 'data:image/jpg;base64,' + account.logo }}"
                />
                <label for="cambiar-logo">
                    <a type="button" class="btn4 s-btn"> Cambiar Logo </a>
                </label>

                <input
                    id="cambiar-logo"
                    type="file"
                    (change)="handleUploadLogo($event)"
                />
            </div>
            <div class="grid input-archivo">
                <img
                    class="img"
                    *ngIf="!account.iso_img"
                    src="assets/img/ISO-9001.png"
                />
                <img
                    class="img"
                    *ngIf="account.iso_img"
                    src="{{ 'data:image/jpg;base64,' + account.iso_img }}"
                />
                <label for="cambiar-iso">
                    <a type="button" class="btn4 s-btn"> Cambiar Iso </a>
                </label>

                <input
                    id="cambiar-iso"
                    type="file"
                    (change)="handleUploadIso($event)"
                />
            </div>
            <div class="input-div">
                <input
                    id="actualizar-info"
                    type="submit"
                    value="Actualizar"
                    class="ec-btn7"
                    (click)="updateAccount()"
                />
            </div>
        </div>
        <div class="grid input-archivo signature__grid">
            <img
                class="img img__signature"
                *ngIf="!account.signature"
                src="assets/img/signature.png"
            />
            <img
                class="img img__signature"
                *ngIf="account.signature"
                src="{{ 'data:image/jpg;base64,' + account.signature }}"
            />
            <label for="cambiar-signature">
                <a type="button" class="btn4 s-btn"> Cambiar Firma </a>
            </label>

            <input
                id="cambiar-signature"
                type="file"
                (change)="handleUploadSignature($event)"
            />
        </div>
    </div>
</form>
