import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {NotificationsService} from '@components/notifications/notifications.service';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {SwitchService} from '@services/switch.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();
    public searchForm: FormGroup;
    show_notifications: boolean = false;
    notifyFun: any;
    notifications: any;
    notifications_new: number;
    notifications_total: number;

    constructor(
        private appService: AppService,
        private switchService: SwitchService,
        private apiService: ApiService,
        private notifyService: NotificationsService
    ) {}

    ngOnInit() {
        this.searchForm = new FormGroup({
            search: new FormControl(null)
        });
        this.switchService.$notifications.subscribe((value) => {
            this.show_notifications = value;
        });
    }

    getNotification() {
        this.apiService.getNotifications().then((notify) => {
            this.notifications = notify.result;
            this.notifications_new = notify.count_new;
            this.notifications_total = notify.total_count;
        });
    }

    toggleNotifications() {
        if (this.show_notifications) {
            this.closeNotifications();
        } else {
            this.openNotifications();
        }
    }

    openNotifications() {
        this.show_notifications = true;
    }

    closeNotifications() {
        this.show_notifications = false;
    }

    logout() {
        this.appService.logout().then((res) => {
            console.log('bye');
        });
    }
}
