import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TableViewModel} from '@components/models/table';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {PaginationService} from '@components/pagination/pagination.service';
import {ExcelService} from '@services/excel.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-manage-units',
    templateUrl: './manage-units.component.html',
    styleUrls: ['./manage-units.component.scss']
})
export class ManageUnitsComponent implements OnInit {
    currentPage: number = 1;
    pageSize: number = 5;
    units: any[] = null;
    units_count: number = null;
    unitsModel: TableViewModel = {
        title: 'Vehiculos',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {title: 'Nombre', key: 'name', filter: false, data: []},
            {
                title: 'Placa',
                key: 'registration_plate',
                filter: false,
                data: []
            },
            {title: 'Modelo', key: 'year', filter: false, data: []},
            {title: 'Marca', key: 'brand', filter: false, data: []},
            {title: 'Clase', key: 'model', filter: false, data: []},
            {title: 'Tipo', key: 'type', filter: false, data: []}
        ],
        records: [],
        showFilters: true,
        enableActions: true,
        isModal: false
    };
    searchby: string = '';

    constructor(
        private apiService: ApiService,
        private appService: AppService,
        private cd: ChangeDetectorRef,
        private pS: PaginationService,
        private excelService: ExcelService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.getUnits(this.currentPage, this.searchby);
    }

    async getUnits(currentPage, searchby, update=true) {
        return this.apiService
            .getUnits(currentPage, this.pageSize, searchby)
            .then((units) => {
                if (units.count > 0 && update) {
                    this.units = units.result;
                    this.units_count = units.total_count;
                    this.unitsModel.records = this.units;
                    this.unitsModel.pagination = {
                        currentPage: this.currentPage,
                        pageSize: this.pageSize,
                        pageCount: units.count,
                        rowCount: this.units_count
                    };
                    this.cd.detectChanges();
                }
                return units.result;
            });
    }

    async generateGeneralExcel(templateSent: any) {
        const units = await this.getUnits(0, 0, false)
        const model = this.excelService.constructModel(templateSent, units)
        await this.excelService.generateExcel(model)
    }

    onRemove(index: number) {
        console.log('remove event ! ');
    }

    paginatorEvent(currentPage: number) {
        this.getUnits(currentPage, this.searchby);
    }

    pageSizeEvent(pageSize: number) {
        this.pageSize = pageSize;
        this.paginatorEvent(this.currentPage);
    }

    searchP(searchby: string) {
        this.getUnits(this.currentPage, searchby ? searchby : this.searchby);
    }

    viewEnlistments(driver: any) {
        this.router.navigate([`/enlistments/vehicle/${driver.id}`]);
    }

    viewCovid(driver: any) {
        this.router.navigate([`/covid-forms/vehicle/${driver.id}`]);
    }
}
