export class Questions {
    questions: Question[];
}
export class Question {
    id?: number;
    name: string;
    description: string;
    novetly?: boolean;
    novetly_id?: boolean;
    image?: string;
    description_mainten?: string;
    img_mainten?: string;
}
