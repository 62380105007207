import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SwitchService {
    constructor() {}

    $modal = new EventEmitter<any>();
    $modal2 = new EventEmitter<any>();
    $modal3 = new EventEmitter<any>();
    $modal4 = new EventEmitter<any>();
    $notifications = new EventEmitter<any>();
    $card_selected = new EventEmitter<any>();
}
