import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TableViewModel, Value} from '@components/models/table';
import {ApiService} from '@services/api.service';
import {AppService} from '@services/app.service';
import {PaginationService} from '@components/pagination/pagination.service';
import {ExcelService} from '@services/excel.service';
import {SwitchService} from '@services/switch.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {motion_logo} from 'assets/images/motion_logo';
import {OldCovidForm} from '@/models/OldCovid';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-manage-old-covid-forms',
    templateUrl: './manage-old-covid-forms.component.html',
    styleUrls: ['./manage-old-covid-forms.component.scss']
})
export class ManageOldCovidFormsComponent implements OnInit {
    old_currentPage: number = 1;
    old_pageSize: number = 5;
    selected_form: any;
    old_covid_forms: any[] = null;
    old_covid_forms_count: number = null;
    old_covid_formModel: TableViewModel = {
        title: 'Control Covid V.1',
        description: '',
        filter: {
            page: 1,
            pageSize: 5
        },
        fields: [
            {title: 'Folio', key: 'folio', filter: true, data: []},
            {title: 'Fecha', key: 'fecha', filter: true, data: []},
            {
                title: 'Conductor',
                key: 'conductor',
                subkey: 'name',
                subobject: true,
                filter: true,
                filterDriver: true,
                data: this.getDriverValues()
            }
        ],
        records: [],
        showFilters: true,
        enableActions: true,
        isModal: false
    };
    old_searchby: string = '';
    show_single_covid_form: boolean = false;
    loading: boolean = true;
    data: OldCovidForm = null;

    constructor(
        private apiService: ApiService,
        private appService: AppService,
        private cd: ChangeDetectorRef,
        private pS: PaginationService,
        private excelService: ExcelService,
        private modalService: SwitchService
    ) {}

    ngOnInit(): void {
        this.getOldCovidForms(this.old_currentPage, this.old_searchby);
        this.modalService.$modal.subscribe((value) => {
            this.show_single_covid_form = value;
        });
    }

    getOldCovidForms(currentPage, searchby) {
        this.apiService
            .getOldCovidFormsInfo(currentPage, this.old_pageSize, searchby)
            .then((covid_forms) => {
                if (covid_forms.count > 0) {
                    this.old_covid_forms = covid_forms.result;
                    this.old_covid_forms_count = covid_forms.total_count;
                    this.old_covid_formModel.records = this.old_covid_forms;
                    this.old_covid_formModel.pagination = {
                        currentPage: this.old_currentPage,
                        pageSize: this.old_pageSize,
                        pageCount: covid_forms.count,
                        rowCount: this.old_covid_forms_count
                    };
                    this.cd.detectChanges();
                }
            });
    }
    onRemove(index: number) {
        console.log('remove event ! ');
    }

    getDriverValues(): Value[] {
        let driverValues: Value[] = [];
        this.apiService.getAllOldDrivers().then((drivers) => {
            for (let driver of drivers) {
                let driverValue = new Value(driver.name, driver.name);
                driverValues.push(driverValue);
            }
        });
        return driverValues;
    }

    old_paginatorEvent(currentPage: number) {
        this.old_currentPage = currentPage;
        this.getOldCovidForms(currentPage, this.old_searchby);
    }

    old_pageSizeEvent(pageSize: number) {
        this.old_pageSize = pageSize;
        this.old_paginatorEvent(this.old_currentPage);
    }

    old_searchP(searchby: string) {
        this.old_searchby = searchby;
        this.getOldCovidForms(
            this.old_currentPage,
            searchby ? searchby : this.old_searchby
        );
    }

    showSingleCovidForm(data) {
        this.modalService.$modal.emit(true);
        this.selected_form = data;
    }

    updateFilter(event) {
        console.log(event);
    }
    filterDriver(event) {
        console.log(event);
    }
    filterUnit(event) {
        console.log(event);
    }

    validateRevision(event) {
        console.log(event);
    }

    openPDF(data: any): void {
        this.apiService.getOldSingleCovidInfo(data.folio).then((covid) => {
            console.log(covid);
            this.loading = true;

            var docDefinition = {
                pageMargins: [40, 90, 40, 60],
                header: {
                    columns: [
                        {
                            image: motion_logo,
                            width: 200,
                            height: 60,
                            alignment: 'right',
                            opacity: 0.8,
                            margin: [0, 10]
                        }
                    ],
                    columnGap: 200
                },
                footer: {
                    columns: [
                        {
                            text: 'monitoringinnovation.com',
                            link: 'http://monitoringinnovation.com',
                            alignment: 'right',
                            margin: [0, 10, 10, 0]
                        }
                    ]
                },
                content: [
                    {
                        text: `Formulario Covid: ${this.data.folio}`,
                        fontSize: 16,
                        margin: [0, 5]
                    },
                    {
                        text: `Fecha: ${this.data.fecha}`,
                        fontSize: 16,
                        margin: [0, 5]
                    },
                    {
                        columns: [
                            [
                                {
                                    text: `Conductor:`,
                                    fontSize: 12,
                                    alignment: 'center'
                                },
                                {
                                    text: `${this.data.conductor}`,
                                    fontSize: 14,
                                    alignment: 'center',
                                    bold: true
                                }
                            ],
                            [
                                {
                                    text: `Temperatura registrada:`,
                                    fontSize: 12,
                                    alignment: 'center'
                                },
                                {
                                    text: `${this.data.temperatura}`,
                                    fontSize: 14,
                                    alignment: 'center',
                                    bold: true
                                }
                            ]
                        ],
                        margin: [0, 10]
                    },
                    {
                        layout: 'lightHorizontalLines',
                        table: {
                            // headers are automatically repeated if the table spans over multiple pages
                            // you can declare how many rows should be treated as headers
                            headerRows: 1,
                            widths: ['*', 100, 100],

                            body: [
                                [
                                    {text: 'ITEM', bold: true},
                                    {text: 'CON NOVEDAD', bold: true},
                                    {text: 'SIN NOVEDAD', bold: true}
                                ],
                                [
                                    'Dolor de garganta',
                                    this.data.garganta ? 'X' : '-',
                                    !this.data.garganta ? 'X' : '-'
                                ],
                                [
                                    'Tos Seca',
                                    this.data.tos ? 'X' : '-',
                                    !this.data.tos ? 'X' : '-'
                                ],
                                [
                                    'Dificulta para respirar',
                                    this.data.respiracion ? 'X' : '-',
                                    !this.data.respiracion ? 'X' : '-'
                                ],
                                [
                                    'Congestion nasal',
                                    this.data.congestion ? 'X' : '-',
                                    !this.data.congestion ? 'X' : '-'
                                ],
                                [
                                    'Fatiga',
                                    this.data.fatiga ? 'X' : '-',
                                    !this.data.fatiga ? 'X' : '-'
                                ],
                                [
                                    'Escalofrios',
                                    this.data.escalofrios ? 'X' : '-',
                                    !this.data.escalofrios ? 'X' : '-'
                                ],
                                [
                                    'Dolor muscular',
                                    this.data.musculos ? 'X' : '-',
                                    !this.data.musculos ? 'X' : '-'
                                ]
                            ]
                        }
                    }
                ]
            };

            pdfMake.tableLayouts = {
                exampleLayout: {
                    hLineWidth: function (i, node) {
                        if (i === 0 || i === node.table.body.length) {
                            return 0;
                        }
                        return i === node.table.headerRows ? 2 : 1;
                    },
                    vLineWidth: function (i) {
                        return 0;
                    },
                    hLineColor: function (i) {
                        return i === 1 ? 'black' : '#8a8a8a';
                    },
                    paddingLeft: function (i) {
                        return i === 0 ? 0 : 8;
                    },
                    paddingRight: function (i, node) {
                        return i === node.table.widths.length - 1 ? 0 : 8;
                    }
                }
            };
            pdfMake.createPdf(docDefinition).open();
            this.loading = false;
        });
    }
}
