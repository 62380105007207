import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {SidebarService} from '@services/sidebar.service';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    public user;
    account: any = null;
    show_sub_history: boolean;
    show_admin: boolean;
    show_enlist: boolean;
    show_covid: boolean;
    constructor(
        public appService: AppService,
        private sidebarService: SidebarService
    ) {}

    ngOnInit() {
        this.user = this.appService.user;
        this.appService.getAccountInfo().then((account) => {
            account ? (this.account = account) : this.logout();
        });
        this.show_admin = false;
        this.show_enlist = false;
        this.show_covid = false;
        this.sidebarService.$sub_menu_history.subscribe((value) => {
            this.show_sub_history = value;
        });
    }

    toggleMenuHistory() {
        this.sidebarService.$sub_menu_history.emit(!this.show_sub_history);
    }
    toggleShowAdmin() {
        this.show_admin = !this.show_admin;
    }

    toggleShowEnlist() {
        this.show_enlist = !this.show_enlist;
    }
    toggleShowCovid() {
        this.show_covid = !this.show_covid;
    }

    logout() {
        this.appService.logout().then((res) => {
            console.log('bye');
        });
    }
}
