import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ApiService} from '@services/api.service';
import {Unit, Units} from '@/models/unit';
import {Drivers, Driver} from '@/models/driver';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ExcelService} from '@services/excel.service';
import {TableViewModel} from '@components/models/table';
import {ToastrService} from 'ngx-toastr';
import pdfMake from 'pdfmake/build/pdfmake';
import {Question} from '@/models/questions';
import {motion_logo} from 'assets/images/motion_logo';
import {AppService} from '@services/app.service';
import {Position} from '@/models/Position';
import {OldEnlistment} from '@/models/OldEnlistment';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {faLocationArrow} from '@fortawesome/free-solid-svg-icons';
import {ThrowStmt} from '@angular/compiler';
import {SwitchService} from '@services/switch.service';

@Component({
    selector: 'app-manage-report',
    templateUrl: './manage-report.component.html',
    styleUrls: ['./manage-report.component.scss']
})
export class ManageReportComponent implements OnInit {
    units: Unit[];
    unitsFiltered: Unit[];
    unitsSelected: number[] = [];
    filtersClass = ["fa-filter", "fa-sort-amount-up-alt", "fa-sort-amount-down-alt"]
    unitsFilterStatus = 0;


    drivers: Driver[];
    driversFiltered: Driver[];
    driversSelected: number[] = [];
    driversFilterStatus = 0;

    loadingUnits: boolean = true;
    loadingDrivers: boolean = true;
    loading: boolean = false;
    units_print: string;
    drivers_print: string;
    questions: Question[] = [];
    state = new FormControl('all');
    account: any;
    data: OldEnlistment = null;
    enlistments: any[] = null;
    enlistments_count: number = null;
    fullImagePath = 'assets/images/logo_motion.png';
    enlistment: any = null;
    position: Position = {name: '', value: ''};
    ok_enlistments_count: number = null;
    currentPage: number = 1;
    pageSize: number = 5;
    vehicleIds: [];
    driversIds: [];
    startDate: string;
    endDate: string;
    enlistmentModel: TableViewModel = {
        fields: [
            {title: 'Fecha', key: 'fecha', filter: false, data: []},
            {title: 'Vehiculo', key: 'conductor', filter: false, data: []},
            {title: 'Conductor', key: 'vehiculo', filter: false, data: []},
            {title: 'Estado', key: 'state', filter: false, data: []},
            {title: 'Folio', key: 'folio', filter: false, data: []},
            {
                title: 'Documentos conductor',
                key: 'documentos_conductor',
                filter: false,
                data: []
            },
            {
                title: 'Documentos vehiculo',
                key: 'documentos_vehiculo',
                filter: false,
                data: []
            },
            {title: 'Calcomania', key: 'calcomania', filter: false, data: []},
            {title: 'Pito', key: 'pito', filter: false, data: []},
            {
                title: 'Dispositivo de velocidad',
                key: 'disp_velocidad',
                filter: false,
                data: []
            },
            {
                title: 'Estado escalera puerta conductor',
                key: 'estado_esc_p_conductor',
                filter: false,
                data: []
            },
            {
                title: 'Estado escalera puerta pasajero',
                key: 'estado_esc_p_pasajero',
                filter: false,
                data: []
            },
            {
                title: 'Equipo carretera',
                key: 'equipo_carretera',
                filter: false,
                data: []
            },
            {
                title: 'Herramientas',
                key: 'herramientas',
                filter: false,
                data: []
            },
            {title: 'Linterna', key: 'linterna', filter: false, data: []},
            {title: 'Extintor', key: 'extintor', filter: false, data: []},
            {title: 'Botiquin', key: 'botiquin', filter: false, data: []},
            {title: 'Repuesto', key: 'repuesto', filter: false, data: []},
            {
                title: 'Retrovisores',
                key: 'retrovisores',
                filter: false,
                data: []
            },
            {title: 'Cinturones', key: 'cinturones', filter: false, data: []},
            {title: 'Motor', key: 'motor', filter: false, data: []},
            {title: 'Llantas', key: 'llantas', filter: false, data: []},
            {title: 'Baterias', key: 'baterias', filter: false, data: []},
            {title: 'Transmision', key: 'transmision', filter: false, data: []},
            {title: 'Tension', key: 'tension', filter: false, data: []},
            {title: 'Tapas', key: 'tapas', filter: false, data: []},
            {title: 'Niveles', key: 'niveles', filter: false, data: []},
            {title: 'Filtros', key: 'filtros', filter: false, data: []},
            {title: 'Parabrisas', key: 'parabrisas', filter: false, data: []},
            {title: 'Frenos', key: 'frenos', filter: false, data: []},
            {
                title: 'Frenos_emergencia',
                key: 'frenos_emergencia',
                filter: false,
                data: []
            },
            {title: 'Aire', key: 'aire', filter: false, data: []},
            {title: 'Luces', key: 'luces', filter: false, data: []},
            {title: 'Silleteria', key: 'silleteria', filter: false, data: []},
            {
                title: 'Silla Conductor',
                key: 'silla_conductor',
                filter: false,
                data: []
            },
            {title: 'Aseo', key: 'aseo', filter: false, data: []},
            {title: 'Celular', key: 'celular', filter: false, data: []},
            {title: 'Ruteros', key: 'ruteros', filter: false, data: []}
        ],
        records: [],
        showFilters: true,
        enableActions: false,
        isModal: false
    };
    drivers_list: string[] = [];
    units_list: string[] = [];

    report_excel = new FormGroup({
        units: new FormControl([], Validators.required),
        drivers: new FormControl([], Validators.required),
        date_init: new FormControl(null, Validators.required),
        date_end: new FormControl(null, Validators.required),
        status: new FormControl('all', Validators.required)
    });
    modal_report_generate: boolean = false;

    constructor(
        private apiService: ApiService,
        private cd: ChangeDetectorRef,
        private excelService: ExcelService,
        private toastr: ToastrService,
        private appService: AppService,
        private http: HttpClient,
        private route: ActivatedRoute,
        private modalService: SwitchService
    ) {}

    ngOnInit(): void {
        this.apiService.getAllUnits().then((units) => {
            this.units = units;
            this.unitsFiltered = [...this.units];
            this.loadingUnits = false;
        });
        this.apiService.getAllDrivers().then((drivers: any) => {
            this.drivers = drivers;
            this.driversFiltered = [...drivers];
            this.loadingDrivers = false;
        });
        this.appService.getAccountInfo().then((account) => {
            this.account = account;
        });
        this.modalService.$modal.subscribe((value) => {
            this.modal_report_generate = value;
        });
    }

    async getQuestions(folio: string) {
        await this.apiService
            .getQuestionsTemplate(folio)
            .then(async (response) => {
                this.questions = response.result;
            });
        return this.questions;
    }

    selectAllUnits() {
        this.unitsSelected = [];
        for (let unit of this.units) {
            this.unitsSelected.push(unit.id);
        }

        this.report_excel.controls['units'].setValue(this.unitsSelected);
    }

    deleteAllUnits() {
        this.unitsSelected = [];
        this.report_excel.controls['units'].setValue(this.unitsSelected);
    }

    handleSearchUnity(e: any) {
        this.unitsFiltered = this.units.filter(unit => unit.name.toLowerCase().includes(e.target.value.toLowerCase()))
    }

    handleUnitsSelectChange(e: Event) {
        const selectElement = e.target as HTMLSelectElement;
        const selectedOptions = Array.from(selectElement.selectedOptions).map(option => Number(option.getAttribute("unit-id")))

        let isInsertion = false
        for(let option of selectedOptions){
            const idx = this.unitsSelected.indexOf(option)

            if(idx === -1)
                isInsertion=true
        }
        if(isInsertion)
            this.unitsSelected = Array.from(new Set([...this.unitsSelected, ...selectedOptions]))
        else
            this.unitsSelected = this.unitsSelected.filter(unit => !selectedOptions.includes(unit))

        this.report_excel.controls['units'].setValue(this.unitsSelected)
    }

    handleFilterUnits() {
        this.unitsFilterStatus = this.unitsFilterStatus < 2 ? this.unitsFilterStatus + 1 : 0;
        this.sortUnitsByFilterStatus()
    }

    sortUnitsByFilterStatus() {
        switch(this.unitsFilterStatus){
            case 0: {
                this.unitsFiltered = [...this.units]
            } break;
            case 1: {
                this.unitsFiltered = this.unitsFiltered.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
            } break;
            case 2: {
                this.unitsFiltered = this.unitsFiltered.sort((a, b) => b.name.localeCompare(a.name, 'en', { numeric: true }))
            } break;
        }

    }

    selectAllDrivers() {
        var json_drivers = [];
        for (let driver of this.drivers) {
            json_drivers.push(driver.id);
        }
        this.driversSelected = json_drivers;
        this.report_excel.controls['drivers'].setValue(json_drivers);
    }

    deleteAllDrivers() {
        var json_drivers = [];
        this.driversSelected = [];
        this.report_excel.controls['drivers'].setValue(json_drivers);
    }

    handleSearchDriver(e: any) {
        this.driversFiltered = this.drivers.filter(driver => driver.name.toLowerCase().includes(e.target.value.toLowerCase()));
    }

    handleDriversSelectChange(e: Event) {
        const selectElement = e.target as HTMLSelectElement;
        const selectedOptions = Array.from(selectElement.selectedOptions).map(option => Number(option.getAttribute("driver-id")));


        let isInsertion = false
        for(let option of selectedOptions){
            const idx = this.driversSelected.indexOf(option)

            if(idx === -1)
                isInsertion=true
        }
        if(isInsertion) {
            this.driversSelected = Array.from(new Set([...this.driversSelected, ...selectedOptions]))
        }
        else {
            this.driversSelected = this.driversSelected.filter(driver => !selectedOptions.includes(driver))
        }

        this.report_excel.controls['drivers'].setValue(this.driversSelected)
    }

    handleFilterDrivers() {
        this.driversFilterStatus = this.driversFilterStatus < 2 ? this.driversFilterStatus + 1 : 0;
        this.sortDriversByFilterStatus()
    }

    sortDriversByFilterStatus() {

        switch(this.driversFilterStatus){
            case 0: {
                this.driversFiltered = [...this.drivers]
            } break;
            case 1: {
                this.driversFiltered = this.driversFiltered.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
            } break;
            case 2: {
                // this.unitsFiltered = this.units.sort((a, b) => a.id - b.id)
                this.driversFiltered = this.driversFiltered.sort((a, b) => b.name.localeCompare(a.name, 'en', { numeric: true }))
            } break;
        }

    }

    async getEnlistments() {
        var json_enlistments = [];
        await this.apiService
            .generateReport(
                this.report_excel.controls['units'].value,
                this.report_excel.controls['drivers'].value,
                this.report_excel.controls['status'].value,
                this.report_excel.controls['date_init'].value,
                this.report_excel.controls['date_end'].value
            )
            .then((enlistments) => {
                if (enlistments.count > 0) {
                    this.drivers_list = enlistments.drivers_list;
                    this.units_list = enlistments.units_list;
                    this.enlistments = enlistments.result;
                    json_enlistments = enlistments.result;
                }
            });
        return json_enlistments;
    }

    openPDF() {
        if (
            this.report_excel.controls['date_init'].valid &&
            this.report_excel.controls['date_end'].valid &&
            this.report_excel.controls['status'].valid
        ) {
            if (
                this.report_excel.controls['units'].valid ||
                this.report_excel.controls['drivers'].valid
            ) {
                this.loading = true;
                try {
                    this.getEnlistments().then(async (enlistments) => {
                        var json_doc_content = [];
                        if(enlistments.length === 0) {
                            this.toastr.error("No tienes enlistamientos");
                            this.loading = false;
                            throw new Error("Sin enlistamientos");
                        }
                        for await (let data of enlistments) {
                            var contentTable = [];
                            var contentNovetlys = [];
                            for await (let quest of data.questions) {
                                contentTable.push([
                                    quest.name,
                                    quest.novetly ? 'X' : '-',
                                    !quest.novetly ? 'X' : '-'
                                ]);
                                if (quest.novetly) {
                                    contentNovetlys.push([
                                        {
                                            text: quest.name,
                                            fontSize: 15,
                                            alignment: 'center',
                                            margin: [0, 5],
                                            bold: true
                                        },
                                        quest.image.length > 0
                                            ? {
                                                  image: `data:image/jpeg;base64,${quest.image}`,
                                                  width: 250,
                                                  margin: [0, 10],
                                                  alignment: 'center'
                                              }
                                            : {
                                                  text: `No contiene imagen`,
                                                  fontSize: 14,
                                                  alignment: 'center'
                                              },
                                        {
                                            text: ` Descripcion: ${quest.description}`,
                                            fontSize: 12,
                                            alignment: 'center'
                                        }
                                    ]);
                                }
                            }

                            var contentEnlistment = [
                                {
                                    text: `Alistamiento: ${data.folio}`,
                                    fontSize: 16,
                                    margin: [0, 5]
                                },
                                {
                                    text: `Fecha: ${data.date}`,
                                    fontSize: 16,
                                    margin: [0, 5]
                                },
                                {
                                    columns: [
                                        [
                                            {
                                                text: `Vehiculo:`,
                                                fontSize: 12,
                                                alignment: 'center'
                                            },
                                            {
                                                text: `${data.unit}`,
                                                fontSize: 14,
                                                alignment: 'center',
                                                bold: true
                                            }
                                        ],
                                        [
                                            {
                                                text: `Conductor:`,
                                                fontSize: 12,
                                                alignment: 'center'
                                            },
                                            {
                                                text: `${data.driver}`,
                                                fontSize: 14,
                                                alignment: 'center',
                                                bold: true
                                            }
                                        ],
                                        [
                                            {
                                                text: `Revision Gerencia:`,
                                                fontSize: 12,
                                                alignment: 'center'
                                            },
                                            {
                                                text: `sí`,
                                                fontSize: 14,
                                                alignment: 'center',
                                                bold: true
                                            }
                                        ],
                                        [
                                            {
                                                text: `Revision jefe de mantenimiento:`,
                                                fontSize: 12,
                                                alignment: 'center'
                                            },
                                            {
                                                text: `sí`,
                                                fontSize: 14,
                                                alignment: 'center',
                                                bold: true
                                            }
                                        ]
                                    ],
                                    margin: [0, 10]
                                },
                                {
                                    layout: 'lightHorizontalLines',
                                    table: {
                                        // headers are automatically repeated if the table spans over multiple pages
                                        // you can declare how many rows should be treated as headers
                                        headerRows: 1,
                                        widths: ['*', 100, 100],

                                        body: [
                                            [
                                                {
                                                    text: 'ITEM',
                                                    bold: true
                                                },
                                                {
                                                    text: 'CON NOVEDAD',
                                                    bold: true
                                                },
                                                {
                                                    text: 'SIN NOVEDAD',
                                                    bold: true
                                                }
                                            ],
                                            ...contentTable
                                        ]
                                    }
                                },
                                {
                                    text: `Novedades`,
                                    fontSize: 16,
                                    bold: true,
                                    margin: [0, 5]
                                },
                                ...contentNovetlys,
                                {
                                    text: ``,
                                    pageBreak: 'after'
                                }
                            ];

                            json_doc_content.push(...contentEnlistment);
                        }
                        var docDefinition = {
                            pageMargins: [40, 90, 40, 60],
                            header: {
                                columns: [
                                    this.account && this.account.logo
                                        ? {
                                              image: `data:image/jpeg;base64,${this.account.logo}`,
                                              width: 120,
                                              height: 60,
                                              alignment: 'left',
                                              opacity: 0.9,
                                              margin: [50, 10]
                                          }
                                        : {},
                                    this.account && this.account.iso_img
                                        ? {
                                              image: `data:image/jpeg;base64,${this.account.iso_img}`,
                                              width: 200,
                                              height: 60,
                                              alignment: 'right',
                                              opacity: 0.9,
                                              margin: [0, 10]
                                          }
                                        : {
                                              image: motion_logo,
                                              width: 200,
                                              height: 60,
                                              alignment: 'right',
                                              opacity: 0.9,
                                              margin: [0, 10]
                                          }
                                ],
                                columnGap: 200
                            },
                            footer: {
                                columns: [
                                    this.account && this.account.signature
                                        ? {
                                              image: `data:image/jpeg;base64,${this.account.signature}`,
                                              width: 120,
                                              height: 60,
                                              alignment: 'left',
                                              opacity: 0.9,
                                              margin: [50]
                                          }
                                        : {},
                                    {
                                        text: 'monitoringinnovation.com',
                                        link: 'http://monitoringinnovation.com',
                                        alignment: 'right',
                                        margin: [0, 10, 10, 0]
                                    }
                                ]
                            },
                            content: json_doc_content
                        };
                        pdfMake.tableLayouts = {
                            exampleLayout: {
                                hLineWidth: function (i, node) {
                                    if (
                                        i === 0 ||
                                        i === node.table.body.length
                                    ) {
                                        return 0;
                                    }
                                    return i === node.table.headerRows ? 2 : 1;
                                },
                                vLineWidth: function (i) {
                                    return 0;
                                },
                                hLineColor: function (i) {
                                    return i === 1 ? 'black' : '#8a8a8a';
                                },
                                paddingLeft: function (i) {
                                    return i === 0 ? 0 : 8;
                                },
                                paddingRight: function (i, node) {
                                    return i === node.table.widths.length - 1
                                        ? 0
                                        : 8;
                                }
                            }
                        };
                        pdfMake.createPdf(docDefinition).open();
                        this.loading = false;
                    });
                } catch (e) {
                    this.loading = false;
                }
            } else {
                this.toastr.error(
                    'Debe escojer almenos una unidad o condcutor'
                );
            }
        } else {
            this.toastr.error('Debe llenar las fechas para generar el informe');
        }
    }

    generateExcel() {
        if (
            this.report_excel.controls['date_init'].valid &&
            this.report_excel.controls['date_end'].valid &&
            this.report_excel.controls['status'].valid
        ) {
            if (
                this.report_excel.controls['units'].valid ||
                this.report_excel.controls['drivers'].valid
            ) {
                this.loading = true;
                try {
                    this.getEnlistments().then(async (enlistments) => {
                        var json_enlistments = [];
                        if(enlistments.length === 0) {
                            this.toastr.error("No tienes enlistamientos");
                            this.loading = false;
                            throw new Error("Sin enlistamientos");
                        }
                        for await (let enlistment of enlistments) {
                            var data = {
                                ...enlistment,
                                novetlys: enlistment.questions
                            };
                            json_enlistments.push(data);
                        }
                        this.exportExcel(json_enlistments);
                        this.loading = false;
                    });
                } catch (e) {
                    this.loading = false;
                }
            } else {
                this.toastr.error(
                    'Debe escojer almenos una unidad o condcutor'
                );
            }
        } else {
            this.toastr.error('Debe llenar las fechas para generar el informe');
        }
    }

    exportExcel(enlistments: any) {
        var data = {
            title: 'Informe de alistamientos',
            header: [
                'folio',
                'date',
                'driver',
                'unit',
                'rc',
                'rg',
                'rm',
                'covid'
            ],
            data: enlistments,
            date_init: this.report_excel.controls['date_init'].value,
            date_end: this.report_excel.controls['date_end'].value,
            drivers: JSON.stringify(this.drivers_list),
            units: JSON.stringify(this.units_list)
        };
        if (enlistments.length > 0) {
            for (let question of enlistments[0].questions) {
                data.header.push(question.name);
            }
        }
        this.excelService.printExcel(data);
    }

    openModalReportGenerate() {
        this.modalService.$modal.emit(!this.modal_report_generate);
    }
}
