import {Component, OnInit} from '@angular/core';
import {Unit, Units} from '@/models/unit';
import {Drivers} from '@/models/driver';
import {ApiService} from '@services/api.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {emit} from 'process';

@Component({
    selector: 'app-manage-notifications',
    templateUrl: './manage-notifications.component.html',
    styleUrls: ['./manage-notifications.component.scss']
})
export class ManageNotificationsComponent implements OnInit {
    units: Unit[];
    loadingUnits: boolean = true;
    notifications_enabled: boolean = false;
    email_selected: boolean = false;
    email_selected_index: number;
    selected_units: Units;
    account: any;
    loading: boolean = false;
    // variables for testing purpose
    toppings = new FormControl();
    email = new FormControl(null, Validators.required);
    form_notify = new FormGroup({
        type: new FormControl('', Validators.required),
        wialon_units: new FormControl([], Validators.required)
    });

    toppingList: string[] = [
        'Extra cheese',
        'Mushroom',
        'Onion',
        'Pepperoni',
        'Sausage',
        'Tomato'
    ];

    emails: string[] = [];
    constructor(private apiService: ApiService) {}

    ngOnInit(): void {
        this.apiService.getAllUnits().then((units) => {
            this.units = units;
            this.loadingUnits = false;
        });
        this.getInfoAccount();
        this.getNotifyConf();
    }

    getNotifyConf() {
        this.apiService.getNotificationConfigurator().then((response) => {
            this.emails = response.emails;
            this.form_notify.controls['wialon_units'].setValue(
                response.wialon_units
            );
            this.form_notify.controls['type'].setValue(response.type);
        });
    }

    getInfoAccount() {
        this.apiService.getMyAccountInfo().then((account) => {
            this.account = account;
            this.notifications_enabled = account.notification_enabled;
        });
    }

    enableConfig(event: any) {

    }

    createEmail() {
        if (this.email.valid) {
            this.emails.push(this.email.value);
            this.email.setValue(null);
        }
    }

    onSelectEmail(index: number) {
        if (this.email_selected_index === index) {
            this.email_selected = false;
            this.email_selected_index = null;
            this.email.setValue(null);
        } else {
            this.email_selected = true;
            this.email_selected_index = index;
            var email = this.emails.find(
                (email, index) => index === this.email_selected_index
            );
            this.email.setValue(email);
        }
    }

    updateEmail() {
        if (this.email.valid) {
            this.emails[this.email_selected_index] = this.email.value;
            this.email_selected = false;
            this.email_selected_index = null;
            this.email.setValue(null);
        }
    }

    onDeleteEmail(index: number) {
        this.emails.splice(index, 1);
    }

    saveConfNotify() {
        this.loading = true;
        var data = {
            emails: this.emails,
            type: this.form_notify.controls['type'].value,
            wialon_units: this.form_notify.controls['wialon_units'].value
        };
        this.apiService.postNotificationConfigurator(data).then(() => {
            this.getNotifyConf();
            this.loading = false;
        });
    }

    toggleCustomQuestion() {
        var data = {
            notification_enabled: !this.notifications_enabled
        };
        this.apiService.pullResource(data).then(() => {
            this.getInfoAccount();
        });
    }

    selectAllUnits() {
        var json_units = [];
        for (let unit of this.units) {
            json_units.push(unit.id);
        }
        this.form_notify.controls['wialon_units'].setValue(json_units);
    }

    deleteAllUnits() {
        var json_units = [];
        this.form_notify.controls['wialon_units'].setValue(json_units);
    }
}
