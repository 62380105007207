import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {switchMap} from 'rxjs/operators';
import xml2js from 'xml2js';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public enlistments: any = null;
    public enlistments_count: number = null;
    public covid_forms: any = null;
    public covid_forms_count: number = null;
    public old_enlistments: any = null;
    public old_enlistments_count: any = null;
    public single_old_enlistment: any = null;
    public single_enlistment: any = null;
    public old_covid_forms: any = null;
    public old_covid_forms_count: any = null;
    public records: any[] = null;
    public records_count: number = null;
    public myAccount: any = null;

    public notifications: any = null;
    public notifications_new: number = null;
    public notifications_total: number = null;

    title = 'read-xml-angular8';
    public xmlItems: any;

    constructor(private http: HttpClient) {}

    async getMyAccountInfo() {
        let MyAccount = await this.http
            .get<any>(`/api/enlistcontrol/myaccount`)
            .toPromise();
        if (MyAccount) {
            this.myAccount = MyAccount;
            return MyAccount;
        } else {
            return null;
        }
    }

    async postMyAccountInfo(data) {
        let body = {
            jsonrpc: '2.0',
            params: {
                email: data.email,
                commercial_company_name: data.company_name,
                phone: data.telefono,
                zip: data.postal,
                image: data.image
            }
        };
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        let MyAccount = await this.http
            .post<any>(`/api/emisim/myaccount/update`, body, {headers: headers})
            .toPromise();
        return MyAccount;
    }

    async getEnlistmentsInfo(
        page?: number,
        pageSize?: number,
        searchby?: string,
        sortby?: string,
        order?: string,
        type?: string,
        id?: string,
        vehicleIds?: [],
        driversIds?: [],
        startDate?: string,
        endDate?: string,
        status?: string,
        driverName?: string,
        vehicleName?: string
    ) {
        var url = `/api/enlistcontrol/enlistments/`;
        var params = '';
        if ((vehicleIds || driversIds) && startDate && endDate) {
            params = `vehicleIds=[${vehicleIds.toString()}]&driversIds=[${driversIds.toString()}]&status=${status.toString()}&startDate=${startDate}&endDate=${endDate}`;
        } else {
            params = params + `page=${page}&pagesize=${pageSize}`;
            if (searchby){
              params = params + `&searchby=${searchby}`;
            }
            if (sortby){
              params = params +  `&sortby=${sortby}`;
            }
            if(order){
              params = params + `&order=${order}`;
            }
            if(type){
              if(type === 'vehicle'){
                params = params +  `&vehicleId=${id}`;
              }
              if(type === 'driver'){
                params = params + `&driverId=${id}`
              }
            }
            driverName && (params += `&driverName=${driverName}`)
            vehicleName && (params += `&vehicleName=${vehicleName}`)
        }
        let enlistments = await this.http.get<any>(url + params).toPromise();
        if (enlistments.result != null) {
            this.enlistments = enlistments.result;
            this.enlistments_count = enlistments.count;
            return enlistments;
        } else {
            return null;
        }
    }

    async generateReport(
        vehicleIds?: [],
        driversIds?: [],
        status?: string,
        startDate?: string,
        endDate?: string
    ) {
        var url = `/api/enlistcontrol/generatereport/`;
        var params = '';
        if ((vehicleIds || driversIds) && startDate && endDate) {
            params = `vehicleIds=[${vehicleIds.toString()}]&driversIds=[${driversIds.toString()}]&status=${status.toString()}&startDate=${startDate}&endDate=${endDate}`;
        }
        let enlistments = await this.http.get<any>(url + params).toPromise();

        if (enlistments.result != null) {
            this.enlistments = enlistments.result;
            this.enlistments_count = enlistments.count;
            return enlistments;
        } else {
            return null;
        }
    }

    async getQuestionsTemplate(folio: string) {
        let questions = await this.http
            .get<any>(`/api/enlistcontrol/enlistquestions/template/${folio}`)
            .toPromise();
        if (questions.result != null) {
            return questions;
        } else {
            return null;
        }
    }

    async getSingleEnlistmentInfo(folio: string) {
        let enlistment = await this.http
            .get<any>(
                `/api/enlistcontrol/enlistments/page=1&pagesize=1&searchby=${folio}`
            )
            .toPromise();
        if (enlistment.result.length > 0) {
            return enlistment;
        } else {
            return null;
        }
    }

    async getOldEnlistmentsInfo(
        page: number,
        pageSize: number,
        searchby?: string,
        sortby?: string,
        order?: string,
        type?: string,
        id?: string
    ) {
        let old_enlistments = await this.http
            .get<any>(
                `/api/enlistcontrol/old_enlistments/page=${page}&pagesize=${pageSize}${
                    searchby ? `&searchby=${searchby}` : ''
                }${sortby ? `&sortby=${sortby}&order=${order}` : ''}${
                    type && type === 'vehiculo' ? `&vehicleId=${id}` : ''
                }${type && type === 'conductor' ? `&driverId=${id}` : ''}`
            )
            .toPromise();
        if (old_enlistments.result != null) {
            this.old_enlistments = old_enlistments.result;
            this.old_enlistments_count = old_enlistments.count;
            return old_enlistments;
        } else {
            return null;
        }
    }

    async getOldSingleEnlistmentInfo(folio: string) {
        let old_enlistment = await this.http
            .get<any>(
                `/api/enlistcontrol/old_enlistments/page=1&pagesize=1&searchby=${folio}`
            )
            .toPromise();
        if (old_enlistment.result.length > 0) {
            return old_enlistment;
        } else {
            return null;
        }
    }

    async getCovidFormsInfo(
        page: number,
        pageSize: number,
        searchby?: string,
        type?: string,
        id?: string
    ) {
        const url = `/api/enlistcontrol/covid_forms/page=${page}&pagesize=${pageSize}${
            searchby ? `&searchby=${searchby}` : ''
        }${type && type === 'vehicle' ? `&vehicleId=${id}` : ''}${
            type && type === 'driver' ? `&driverId=${id}` : ''
        }`;
        let covid_forms = await this.http.get<any>(url).toPromise();
        if (covid_forms.result != null) {
            this.covid_forms = covid_forms.result;
            this.covid_forms_count = covid_forms.count;
            return covid_forms;
        } else {
            return null;
        }
    }

    async getOldCovidFormsInfo(
        page: number,
        pageSize: number,
        searchby?: string
    ) {
        let old_covid_forms = await this.http
            .get<any>(
                `/api/enlistcontrol/old_covid_forms/page=${page}&pagesize=${pageSize}${
                    searchby ? `&searchby=${searchby}` : ''
                }`
            )
            .toPromise();
        if (old_covid_forms.result != null) {
            this.old_covid_forms = old_covid_forms.result;
            this.old_covid_forms_count = old_covid_forms.count;
            return old_covid_forms;
        } else {
            return null;
        }
    }

    async getOldSingleCovidFormInfo(folio: string) {
        let old_covid_form = await this.http
            .get<any>(
                `/api/enlistcontrol/old_covid_forms/page=1&pagesize=1&searchby=${folio}`
            )
            .toPromise();
        if (old_covid_form.result != null) {
            return old_covid_form;
        } else {
            return null;
        }
    }

    async getOldSingleCovidInfo(folio: string) {
        let old_covid_form = await this.http
            .get<any>(`/api/enlistcontrol/old_covid_form/folio=${folio}`)
            .toPromise();
        if (old_covid_form.result != null) {
            return old_covid_form;
        } else {
            return null;
        }
    }

    async getSingleCovidInfo(folio: string) {
        let old_covid_form = await this.http
            .get<any>(`/api/enlistcontrol/covid_form/folio=${folio}`)
            .toPromise();
        if (old_covid_form.result != null) {
            return old_covid_form;
        } else {
            return null;
        }
    }

    async getSingleCovidNovelties(folio: string) {
        let covid_novelties = await this.http
            .get<any>(`/api/enlistcontrol/covidquestions/template/${folio}`)
            .toPromise();
        if (covid_novelties.result != null) {
            return covid_novelties;
        } else {
            return null;
        }
    }

    async getAllUnits() {
        let units = await this.http
            .get<any>(`/api/enlistcontrol/units`)
            .toPromise();
        if (units.result != null) {
            return units.result;
        } else {
            return null;
        }
    }

    async getAllOldUnits() {
        let units = await this.http
            .get<any>(`/api/enlistcontrol/old_units`)
            .toPromise();
        if (units.result != null) {
            return units.result;
        } else {
            return null;
        }
    }

    async getUnits(page: number, pageSize: number, searchby?: string) {
        let units = await this.http
            .get<any>(
                `/api/enlistcontrol/units/page=${page}&pagesize=${pageSize}${
                    searchby ? `&searchby=${searchby}` : ''
                }`
            )
            .toPromise();
        if (units.result != null) {
            return units;
        } else {
            return null;
        }
    }

    async getAllDrivers() {
        let drivers = await this.http
            .get<any>(`/api/enlistcontrol/drivers`)
            .toPromise();
        if (drivers.result != null) {
            return drivers.result;
        } else {
            return null;
        }
    }

    async getAllOldDrivers() {
        let drivers = await this.http
            .get<any>(`/api/enlistcontrol/old_drivers`)
            .toPromise();
        if (drivers.result != null) {
            return drivers.result;
        } else {
            return null;
        }
    }

    async getDrivers(page: number, pageSize: number, searchby?: string) {
        let drivers = await this.http
            .get<any>(
                `/api/enlistcontrol/drivers/page=${page}&pagesize=${pageSize}${
                    searchby ? `&searchby=${searchby}` : ''
                }`
            )
            .toPromise();
        if (drivers.result != null) {
            return drivers;
        } else {
            return null;
        }
    }

    async activeCustomTemplateEnlist(active) {
        let activeTemplate = await this.http
            .get<any>(`/api/enlistcontrol/template/enlist/${active}`)
            .toPromise();
        if (activeTemplate.result != null) {
            return activeTemplate.result;
        } else {
            return null;
        }
    }

    async getEnlistQuestions() {
        let questions = await this.http
            .get<any>(`/api/enlistcontrol/enlistquestions`)
            .toPromise();
        if (questions.result != null) {
            return questions.result;
        } else {
            return null;
        }
    }

    async postEnlistQuestion(data) {
        var params = {
            name: data.name,
            description: data.description
        };
        let question = await this.http
            .post<any>(`/api/enlistcontrol/enlistquestions/new`, {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        if (question.result != null) {
            return question.result;
        } else {
            return null;
        }
    }

    async deleteEnlistQuestion(id: number) {
        let response = await this.http
            .delete<any>(`/api/enlistcontrol/enlistquestions/delete/${id}`)
            .toPromise();
        if (response.result != null) {
            return response.result;
        } else {
            return null;
        }
    }

    async updateEnlistQuestion(data: any) {
        var params = {
            id: data.id,
            name: data.name,
            description: data.description
        };
        let response = await this.http
            .put<any>(`/api/enlistcontrol/enlistquestions/update`, {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        if (response.result != null) {
            return response.result;
        } else {
            return null;
        }
    }

    async postExcelData(data) {
        var params = {
            units: data.units,
            drivers: data.drivers,
            date_init: data.date_init,
            date_end: data.date_end,
            status: data.status
        };
        let ExcelData = await this.http
            .post<any>(`/api/enlistcontrol/generate/excel`, {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        if (ExcelData.result != null) {
            return ExcelData.result;
        } else {
            return null;
        }
    }

    async getNotifications() {
        let notifications = await this.http
            .get<any>(`/api/enlistcontrol/notifications/`)
            .toPromise();
        if (notifications.result != null) {
            this.notifications = notifications.result;
            this.notifications_new = notifications.count_new;
            this.notifications_total = notifications.total_count;
            return notifications;
        } else {
            return null;
        }
    }

    async postNotifications(id: number) {
        let body = {
            jsonrpc: '2.0',
            params: {
                notification: id
            }
        };
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        });
        let ViewNotification = await this.http
            .post<any>(`/api/enlistcontrol/notification`, body, {
                headers: headers
            })
            .toPromise();
        return ViewNotification;
    }

    async getNotificationConfigurator() {
        const headers = new HttpHeaders({
            'Access-Control-Allow-Origin': '*'
        });
        let ViewNotificationConf = await this.http
            .get<any>(`/api/enlistcontrol/notifications/config`, {
                headers: headers
            })
            .toPromise();
        return ViewNotificationConf;
    }

    async postNotificationConfigurator(data) {
        let body = {
            jsonrpc: '2.0',
            params: {
                emails: data.emails,
                type: data.type,
                wialon_units: data.wialon_units
            }
        };
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        });
        let ViewNotification = await this.http
            .post<any>(`/api/enlistcontrol/notifications/config/save`, body, {
                headers: headers
            })
            .toPromise();
        return ViewNotification;
    }

    async pullResource(data: any) {
        let body = {
            jsonrpc: '2.0',
            params: {
                data: data
            }
        };
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        });
        let Resource = await this.http
            .post<any>(`/api/enlistcontrol/resource/update`, body, {
                headers: headers
            })
            .toPromise();
        return Resource;
    }

    async checkMaintenance(data) {
        var params = {
            novetlys: data.novetlys,
            enlist_id: data.enlist_id
        };
        let response = await this.http
            .post<any>(`/api/enlistcontrol/maintenance/check`, {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        if (response.result != null) {
            return response.result;
        } else {
            return null;
        }
    }

    async pullRsRead(data) {
        var params = {
            enlist_id: data.enlist_id,
            position: data.position
        };
        let response = await this.http
            .post<any>(`/api/enlistcontrol/update/rs/read`, {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        if (response.result != null) {
            return response.result;
        } else {
            return null;
        }
    }

    async pullRsCheck(data) {
        var params = {
            enlist_id: data.enlist_id
        };
        let response = await this.http
            .post<any>(`/api/enlistcontrol/update/rs/check`, {
                jsonrpc: '2.0',
                params: params
            })
            .toPromise();
        if (response.result != null) {
            return response.result;
        } else {
            return null;
        }
    }
}
